import { validateStringLang } from 'contexts/TranslationContext'

export const getDefaultBindingId = (user, project) => {
  if (user.id === 'guest') {
    return ''
  }
  if (project?.stripeId) {
    if (user?.stripeAttachedCards?.length) {
      return user.stripeAttachedCards[user.stripeAttachedCards.length - 1]?.id
    }
    return 'stripe'
  }

  if (!user.creditCards || user.creditCards.length === 0) {
    return ''
  }

  return user.creditCards[user.creditCards.length - 1]?.bindingId
}

export const compareBonusAvailableAmount = (giftCard, amount) => {
  if (!giftCard.id) {
    return {
      makePayment: true,
      paymentAmount: amount,
    }
  }
  return {
    makePayment: giftCard.availableAmount < amount,
    paymentAmount: amount - giftCard.availableAmount,
    hasGiftCard: true,
  }
}

export const checkUtm = (string) => {
  if (string.search.includes('utm')) {
    const source = string.search.split('&')[0]
    const medium = string.search.split('&')[1]
    const campaign = string.search.split('&')[2]
    const newParam = source + '&' + medium + '&' + campaign

    return newParam
  }
  return null
}

const latinLetters = {
  ա: 'a',
  բ: 'b',
  գ: 'g',
  դ: 'd',
  ե: 'e',
  զ: 'z',
  է: 'e',
  ը: 'e',
  թ: 't',
  ժ: 'zh',
  ի: 'i',
  լ: 'l',
  խ: 'kh',
  ծ: 'ts',
  կ: 'k',
  հ: 'h',
  ձ: 'dz',
  ղ: 'gh',
  ճ: 'ch',
  մ: 'm',
  յ: 'y',
  ն: 'n',
  շ: 'sh',
  ո: 'o',
  չ: 'ch',
  պ: 'p',
  ջ: 'j',
  ռ: 'r',
  ս: 's',
  վ: 'v',
  տ: 't',
  ր: 'r',
  ց: 'ts',
  ու: 'u',
  փ: 'p',
  ք: 'k',
  և: 'ev',
  եւ: 'ev',
  օ: 'o',
  ֆ: 'f',
}

export const toLatinLetters = (str) =>
  validateStringLang(str, 'hy')
    ? str
        .toLowerCase()
        .split('')
        .map((e, i) =>
          !latinLetters?.[e]
            ? e
            : i === 0
            ? latinLetters[e].toUpperCase()
            : latinLetters[e] || e
        )
        .join('')
    : str

export const generateDescription = ({
  firstName,
  lastName,
  email,
  paymentType,
  projectTitle,
}) =>
  `Dear ${toLatinLetters(firstName)} ${toLatinLetters(lastName)}, you are ${
    paymentType === 'recurring' ? 'monthly' : ''
  } donating to "${
    projectTitle || 'reArmenia'
  }" with ${email} email address. Transaction ID - `

export const createEmptyTransactionBody = ({
  project,
  user,
  hasDefaultAmount,
  defaultAmount,
  hasFirstName,
  firstName,
  hasLastName,
  lastName,
  hasEmail,
  email,
  isContribution,
}) => {
  const isGuest = user?.id === 'guest'
  return {
    bindingId: getDefaultBindingId(user || {}),
    amount: hasDefaultAmount ? defaultAmount : 0,
    reArmenia: isContribution
      ? 0
      : hasDefaultAmount
      ? (Number(defaultAmount) * 7.5) / 100
      : 0,
    email: isGuest ? (hasEmail ? email : '') : user?.email,
    firstName: isGuest ? (hasFirstName ? firstName : '') : user?.firstName,
    lastName: isGuest ? (hasLastName ? lastName : '') : user?.lastName,
    paymentType:
      project?.fundraisingType === 'oneTime' ? 'oneTime' : 'recurring',
    attachCard: true,
    attachStripeCard: false,
    subscribeNews: true,
    isAnonymous: false,
    showAttachCardCheckbox: false,
    paymentMethod: '',
  }
}

export const createTransactionBody = ({
  user,
  project,
  peerProject,
  formAmount,
  currencyRate,
  email,
  firstName,
  lastName,
  language,
  data,
  paymentType,
  appliedGiftCard,
  isBuyingGiftCard,
  isContribution,
  paymentMethod,
  transactionId,
  peerProjectId,
}) => {
  let amount = parseInt(formAmount)
  if (data.reArmenia && !isContribution) {
    amount += Number(data.reArmenia)
  }
  const body = {
    amount: parseInt(`${parseInt(amount * currencyRate)}00`),
    language,
    description: isBuyingGiftCard
      ? 'You are buying gift card'
      : generateDescription({
          firstName,
          lastName,
          email,
          paymentType,
          projectTitle: peerProject?.title_en || project?.title_en,
        }),
    redirectUrl: `${window.location.protocol}//${window.location.host}/${language}/check-transaction`,
    transactionInfo: {
      transactionId,
      subscribeForMonthly: isBuyingGiftCard
        ? false
        : paymentType === 'recurring',
      attachCard: user.id === 'guest' ? false : data.attachCard,
      paymentMethod,
    },
  }
  if (peerProjectId) {
    body.transactionInfo.peerProjectId = peerProjectId
  }
  body.clientId = email

  if (data.bindingId) {
    body.localBindingId = data.bindingId
    body.attachCard = false
  }

  const compareResult = compareBonusAvailableAmount(
    appliedGiftCard.current,
    amount
  )
  // if (compareResult.hasGiftCard) {
  //   body.amount = `${compareResult.paymentAmount}00`
  //   body.transactionInfo.giftCardAmount =
  //     appliedGiftCard.current.availableAmount
  //   body.transactionInfo.giftCardId = appliedGiftCard.current.id
  //   body.transactionInfo.giftCardCode = appliedGiftCard.current.code
  //   body.transactionInfo.giftCardTransactionType = 'spend'
  // }
  return { body, compareResult }
}

export const isTopDonor = (transaction, topDonors) => {
  if (transaction.isAnonymous) {
    return false
  }
  if (!topDonors) {
    return false
  }
  const isTop = topDonors.find(
    ({ email }) => email.toLowerCase() === transaction.email.toLowerCase()
  )
  return isTop
}

export const mapTopDonorFirstName = (transaction, topDonors) => {
  if (!isTopDonor(transaction, topDonors)) {
    return transaction
  }
  return {
    ...transaction,
    firstName: '⭐️ ' + transaction.firstName,
  }
}
