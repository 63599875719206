import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import {
  Container,
  Spinner,
  Flex,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react'
import {
  useUser,
  useProjects,
  useQueryParams,
  useUpdateProject,
  useCustomToast,
} from 'hooks'
import { MainLayout } from 'components/Layouts'
import ListView from 'components/ListView'
import Header from './Header'
import Pagination from 'components/Pagination/Index'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { applicationsListQuery } from 'elastic/queries'
import { Link } from 'pages'
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg'
import StatusSelect from './StatusSelect'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import LineClamp from 'components/LineClamp'
import useExportApplications from 'hooks/useExportApplications'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

const PAGE_SIZE = 16
const TABS = ['fundraisers', 'collaborations', 'peer-to-peer']
const projectPageURL = (isProject, id, slug) =>
  `/${isProject ? 'fundraisers' : 'collaborations'}/${slug || id}`

const editProjectPageURL = (isProject, id, fundraisingType) => {
  if (isProject) {
    if (fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      return `/edit-peer-to-peer-fundraiser/${id}/content`
    }

    return `/edit-fundraisers/${id}/application`
  }

  return `/edit-collaborations/${id}/application`
}

const ApplicationsDashboard = () => {
  const { t } = useTranslation()
  const toast = useCustomToast()
  const { user, isLoading: isUserLoading } = useUser()
  const queryParams = useQueryParams()
  const [tabIndex, setTabIndex] = useState(0)
  const searchRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('page')) || 1
  )
  const { mutate: exportApplications, isLoading: isExportLoading } =
    useExportApplications()

  const { updateProject } = useUpdateProject()
  const { projects, isLoading, setFilters, filters } = useProjects(
    applicationsListQuery({ projectType: 'fundraisers' }),
    true,
    'fundraisers'
  )
  const { navigationPush } = useNavigation()

  const [localFilters, setLocalFilters] = useState({
    category: '',
    status: '',
    search: '',
    isRecurringChecked: false,
    projectType: 'fundraisers',
  })

  const onExport = async () => {
    exportApplications(
      {},
      {
        onSuccess: (data) => {
          console.log(data)
          toast({
            title: 'Applications is being exported',
            status: 'success',
            duration: 3000,
          })
        },
      }
    )
  }

  useEffect(() => {
    if (!user.isAdmin) {
      return
    }
    const basePath = '/applications-dashboard'
    navigationPush(basePath + `?page=${currentPage}`)
    setFilters({ ...filters, from: (currentPage - 1) * PAGE_SIZE })
  }, [currentPage])

  useEffect(() => {
    setLocalFilters({ ...localFilters, projectType: TABS[tabIndex] })
  }, [tabIndex])

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!localFilters.search) {
      searchRef.current.value = ''
    }
    let updatingObject = {
      ...filters,
      ...applicationsListQuery(localFilters),
      from: (currentPage - 1) * PAGE_SIZE,
    }
    if (!_.isEqual(filters, updatingObject)) {
      setCurrentPage(1)
    }
    setFilters(updatingObject)
  }, [localFilters])

  const onStatusChange = ({ status, id }) => {
    updateProject(
      { id, status },
      {
        onSuccess: () => {
          toast({
            title: 'Application status updated successfully',
            status: 'success',
            duration: 3000,
          })
        },
        onError: () => {
          toast({
            title: 'Application status update failed',
            status: 'error',
            duration: 3000,
          })
        },
      }
    )
  }
  if (!isUserLoading && !user.isAdmin) {
    return <Redirect to={`/`} />
  }

  return (
    <MainLayout>
      <Container w="full" maxW="90%" minH="calc(100vh - 96px)">
        <Button
          mt={4}
          variant="blue"
          onClick={onExport}
          isLoading={isExportLoading}
        >
          Export Applications to Google Drive
        </Button>

        <Header
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabs={TABS}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
          searchRef={searchRef}
          isLoading={isLoading}
        />
        {isLoading ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <>
            {projects.total.value === 0 || !projects.items.length ? (
              <Flex alignItems="center" justifyContent="center" w="full">
                <Heading as="h2" fontSize="4xl" color="blue.300">
                  {t('noResults')}
                </Heading>
              </Flex>
            ) : (
              <>
                {/* eslint-disable  */}
                <ListView
                  head={[
                    <Text textAlign="left">{t('dashboard@title')}</Text>,
                    t('dashboard@application'),
                    t('dashboard@status'),
                    t('dashboard@creator'),
                    t('dashboard@name'),
                    t('dashboard@email'),
                    t('dashboard@phoneNumber'),
                    '',
                  ]}
                  body={projects.items.map((project) => [
                    <Link
                      key={project.id}
                      to={projectPageURL(
                        project.isProject,
                        project.id,
                        project.slug
                      )}
                    >
                      <LineClamp
                        Component={Text}
                        textDecor="underline"
                        textAlign="left"
                      >
                        {project.applicationTitle ||
                          project.title_en ||
                          project.title_ru ||
                          project.title_hy}
                      </LineClamp>
                    </Link>,
                    moment(project.createdAt).format('DD.MM.YYYY'),
                    <StatusSelect
                      key={project.id}
                      defaultStatus={project.status}
                      project={project}
                      onChange={onStatusChange}
                    />,
                    project.contactFullName,
                    project.ownerName,
                    project.ownerEmail,
                    project.phoneNumber,
                    <Link
                      key={project.id}
                      to={editProjectPageURL(
                        project.isProject,
                        project.id,
                        project.fundraisingType
                      )}
                    >
                      <PenIcon />
                    </Link>,
                  ])}
                  height={projects.items.length}
                  projects={projects.items}
                  onStatusChange={onStatusChange}
                />
                {/* eslint-disable  */}
                <Pagination
                  total={projects.total.value}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  size={PAGE_SIZE}
                />
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default ApplicationsDashboard
