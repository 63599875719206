/* eslint-disable */
import { Box, Heading, Flex, Image, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import Layout from './Layout'
import { getImageUrl } from './helpers'
import NoScrollbar from 'components/NoScrollbar'
import { useGetGiftCardsPageData } from 'core/Content/hooks'

export default function SelectGiftCard() {
  const { t } = useTranslation()
  const { data: giftCards } = useGetGiftCardsPageData()

  if (!giftCards) {
    return (
      <Flex
        height="full"
        w="full"
        align="center"
        justifyContent="center"
        position="absolute"
      >
        <Spinner color="blue.300" />
      </Flex>
    )
  }
  return (
    <Layout>
      <Heading mt={4} fontWeight={600} fontSize={{ base: 'xl', sm: '5xl' }}>
        {t('giftCard@title')}
      </Heading>
      <Box mt={{ base: 6, sm: 12 }}>
        {giftCards
          .filter((item) => item[1]?.length > 0)
          ?.map(([category, cards], index) => (
            <GiftCardList
              key={category}
              category={t(`giftCard@category@${category}`)}
              cards={cards}
              index={index}
            />
          ))}
      </Box>
    </Layout>
  )
}

const GiftCardList = ({ index, category, cards }) => (
  <Box my={index ? 12 : 0}>
    <Heading fontWeight={500} fontSize={{ base: 'lg', sm: '3xl' }} mb={6}>
      {category}
    </Heading>
    <NoScrollbar
      Component={Box}
      w="full"
      overflowX={{ base: 'scroll', xl: 'hidden' }}
    >
      <Flex
        alignItems="baseline"
        flexWrap={{ base: 'nowrap', xl: 'wrap' }}
        w="fit-content"
        spacing="8"
        mr={{ base: 12, xl: 0 }}
      >
        {cards?.map((imageKey, i) => (
          <GiftCard imageKey={imageKey} index={i} key={imageKey} />
        ))}
      </Flex>
    </NoScrollbar>
  </Box>
)

const GiftCard = ({ index, imageKey }) => (
  <Box
    w="250px"
    h="150px"
    mt={{ base: 0, xl: index >= 4 ? 6 : 0 }}
    ml={{ base: index ? 8 : 0, xl: index % 4 === 0 ? 0 : 8 }}
  >
    <Link to={`/order-gift-card?projectId=giftCard&giftCard=${imageKey}`}>
      <Image
        objectFit="cover"
        w="250px"
        h="150px"
        src={getImageUrl(imageKey)}
        borderRadius="12px"
      />
    </Link>
  </Box>
)
