import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useGetProject, useGetProjectSystemByProjectId } from 'hooks'
// @INFO uncomment and use once idram will be integrated successfully
import Idram from './Idram'
import MailerLite from './MailerLite'
import { useProject } from 'pages/CreateEditProject/ProjectContext'
import FBPixel from './FBPixel'

export default function Integrations() {
  const { projectId } = useParams()
  const { data: projectSystem, isLoading } =
    useGetProjectSystemByProjectId(projectId)
  const { data: project } = useGetProject({ projectId })
  const { isPeerToPeerProject } = useProject()

  if (isLoading) {
    return null
  }

  return (
    <Stack spacing={4} maxW="540px" w="100%">
      <MailerLite project={project} projectSystem={projectSystem} />
      <Idram projectSystem={projectSystem} />
      {!isPeerToPeerProject && <FBPixel project={project} />}
    </Stack>
  )
}
