import DefaultLayout from '../containers/DefaultLayout'
import { ProfileLayout } from 'components/Layouts'
import { Redirect } from './index'
import Homepage from './Homepage'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import CreateProject from './CreateProject'
import PreCreateProject from './PreCreateProject'
import Donation from './Donation'
import Project from './Project'
import Projects from './Projects'
import Initiatives from './Initiatives'
import SelectGiftCard from './SelectGiftCard'
import CollaborationRequest from './CollaborationRequest'
import ProfileCollab from './Profile/MobileProfile/MyCollaborations'
import ProfileFund from './Profile/MobileProfile/MyFundraisers'
import MySettings from './Profile/MobileProfile/Settings'
import CreateEditProject from './CreateEditProject'
import Terms from './Terms'
import ContactUs from './ContactUs'
import PaymentsPage from './PaymentPage'
import MobileDonation from './MobileDonation'
import MobileInitiative from './MobileInitiative'
import ChangePassword from './ChangePassword'
import Verify from './Verify'
import ResetPassword from './ResetPassword'
import Search from './Search'
import Privacy from './Privacy'
import ThankYouGiftCard from './ThankYouGiftCard'
import ThankYou from './ThankYou'
import ThankYouStripe from './ThankYouStripe'
import ThankYouSubscribe from './ThankYouSubscribe'
import CheckTransaction from './CheckTransaction'
import CheckBinding from './CheckBinding'
import Done from './Done'
import ThankYouSignUp from './ThankYouSignUp'
import MobileHistory from './MobileHistory'
import MobileActNow from './MobileActNow'
import Testimonials from './Testimonials'
import SuccessStory from './SuccessStory'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import UnsubscribeDonation from './UnsubscribeDonation'
import Fallback from './Fallback'
import ProjectsDashboard from './ProjectsDashboard'
import ApplicationsDashboard from './ApplicationsDashboard'
import ExpertiseDashboard from './ExpertiseDashboard'
import SetPassword from './SetPassword'
import RedeemGiftCard from './RedeemGiftCard'
import RedeemModalMobile from './RedeemModalMobile'
import ExportPage from './Export'
import DonationFallback from './DonationFallback'
import RedirectFallback from './RedirectFallback'
import DonateToRearmenia from './Donation/DonateToRearmenia'
import Experts from './Experts'
import ExpertsApplyModal from './Experts/Mobile/ExpertsApplyModal'
import ExpertsConnectModal from './Experts/Mobile/ExpertsConnectModal'
import ListSubscriptions from './Profile/Donor/ListSubscriptions.jsx'
import ConnectLoading from './ConnectLoading'
import Analytics from './Analytics'
import FormPageWithType from './ExpertForm'
import RedirectWithLang from '../pages/RedirectFallback/RedirectWithLang'
import OgImage from './OgImage'
import OgImageSync from './OgImage/OgImageSync'
import ExpertSpace from './Profile/Expert'
import ExpertNotificationModalMobile from './ExpertNotificationModalMobile'
import ExpertsDashboard from './ExpertsDashboard'
import ExpertApplicationLoading from './ExpertApplicationLoading'
import EditExpert from './EditExpert'
import TransactionDashboard from './TransactionDashboard'
import FundraiserCreator from './Profile/FundraiserCreator'
import InitiatorSpace from './Profile/Initiator'
import Dashboard from './Profile/Dashboard'
import Settings from './Profile/Settings'
import Notifications from './Profile/Notifications'
import Invest from './Profile/Invest'
import Donor from './Profile/Donor'
import Matching from './Profile/Matching'
import InKInd from './Profile/inKInd'
import CosmoAdmin from './CosmoAdmin'
import LanguageSwitcherMobile from 'components/LanguageSwitcherMobile'
import CurrencySwitcherMobile from 'components/CurrencySwitcherMobile'
import CheckStripeConnection from './CheckStripeConnection'
import CheckTransactionStripe from './CheckTransactionStripe'

const routes = [
  {
    Component: CheckTransaction,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/check-transaction',
  },
  {
    Component: CheckBinding,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/check-binding',
  },
  {
    Component: CheckTransactionStripe,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/check-transaction-stripe',
  },
  {
    Component: UnsubscribeDonation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/unsubscribe-donation/:subscriptionId',
  },
  {
    Component: ThankYouSubscribe,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/subscribe/thank-you',
  },
  {
    Component: ThankYou,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate/thank-you',
  },
  {
    Component: ThankYouStripe,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate/thank-you-stripe',
  },
  {
    Component: ThankYouGiftCard,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/gift-card/thank-you',
  },
  {
    Component: RedeemGiftCard,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/redeem-gift-card',
  },
  {
    Component: MobileHistory,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/history',
  },
  {
    Component: Search,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/search',
  },
  {
    Component: ResetPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/reset-password',
  },
  {
    Component: RedeemModalMobile,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/redeem-mobile',
  },
  {
    Component: Verify,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/verify',
  },
  {
    Component: SetPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/set-password',
  },
  {
    Component: ChangePassword,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/change-password',
  },
  {
    Component: MobileInitiative,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/act/:initiativeId',
  },
  {
    Component: MobileDonation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate/:projectId',
  },
  {
    Component: CollaborationRequest,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/collaboration-request',
  },
  {
    Component: PaymentsPage,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/payment',
  },
  {
    Component: ContactUs,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/contact-us',
  },
  {
    Component: Privacy,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/privacy-policy',
  },
  {
    Component: Terms,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/terms-and-conditions',
  },
  {
    Component: CreateEditProject,
    Layout: DefaultLayout,
    secured: false,
    exact: false,
    path: [
      '/:lang/edit-fundraisers/:projectId',
      '/:lang/edit-collaborations/:projectId',
    ],
  },
  {
    Component: CreateEditProject,
    Layout: DefaultLayout,
    secured: false,
    exact: false,
    path: [
      '/:lang/create-peer-to-peer-fundraiser/:peerProjectId',
      '/:lang/edit-peer-to-peer-fundraiser/:projectId',
    ],
  },
  {
    Component: FundraiserCreator,
    Layout: ProfileLayout,
    exact: false,
    secured: true,
    path: '/:lang/profile/fundraisers/:tab',
  },
  {
    Component: InitiatorSpace,
    Layout: ProfileLayout,
    exact: false,
    secured: true,
    path: '/:lang/profile/initiator-space/:tab',
  },
  {
    Component: ExpertSpace,
    Layout: ProfileLayout,
    exact: false,
    secured: true,
    path: '/:lang/profile/expert/:tab',
  },
  {
    Component: () => <Redirect to="/profile/expert/card" />,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/expert',
  },
  {
    Component: InKInd,
    Layout: ProfileLayout,
    exact: false,
    secured: true,
    path: '/:lang/profile/in-kind',
  },
  {
    Component: Dashboard,
    Layout: ProfileLayout,
    exact: false,
    secured: true,
    path: '/:lang/profile/dashboard',
  },
  {
    Component: Initiatives,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/collaborations',
  },
  {
    Component: Donor,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/history/:tab',
  },
  {
    Component: Settings,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/settings/:tab',
  },
  {
    Component: Invest,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/invest/:tab',
  },
  {
    Component: Matching,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/matching',
  },
  {
    Component: Notifications,
    Layout: ProfileLayout,
    exact: true,
    secured: true,
    path: '/:lang/profile/notifications',
  },
  {
    Component: ProfileCollab,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-collaborations',
  },
  {
    Component: ProfileFund,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-fundraisers',
  },
  {
    Component: MySettings,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/my-settings/:tab',
  },
  {
    Component: SelectGiftCard,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/select-gift-card',
  },
  // {
  //   Component: GiftCard,
  //   Layout: DefaultLayout,
  //   secured: false,
  //   exact: true,
  //   path: '/:lang/gift-card',
  // },
  {
    Component: Donation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/order-gift-card',
  },

  {
    Component: Done,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/done',
  },
  {
    Component: Initiatives,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/collaborations', '/:lang/initiatives', '/:lang/initiative'],
  },
  {
    Component: Projects,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/fundraisers', '/:lang/project', '/:lang/projects'],
  },
  {
    Component: Project,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/fundraisers/:projectId', '/:lang/collaborations/:projectId'],
  },
  {
    Component: Donation,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donation',
  },
  {
    Component: DonateToRearmenia,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/donate-to-rearmenia',
  },
  {
    Component: CreateProject,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/create-project',
  },
  {
    Component: PreCreateProject,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/pre-create-project',
  },
  {
    Component: Homepage,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang',
  },
  {
    Component: Login,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/sign-in',
  },
  {
    Component: Register,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/sign-up',
  },
  {
    Component: ForgotPassword,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/forgot-password',
  },
  {
    Component: ThankYouSignUp,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/signup/thank-you',
  },
  {
    Component: MobileActNow,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/act-now',
  },
  {
    Component: Blog,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/blog',
  },
  {
    Component: BlogSingle,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/blog/:slug',
  },
  {
    Component: SuccessStory,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/success-story/:slug',
  },
  {
    Component: Testimonials,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/testimonials',
  },
  {
    Component: ProjectsDashboard('fundraisers'),
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/fundraisers-dashboard',
  },
  {
    Component: ExpertsDashboard,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/experts-dashboard',
  },
  {
    Component: TransactionDashboard,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/edit-transactions/:projectId',
  },
  {
    Component: ProjectsDashboard('collaborations'),
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/collaborations-dashboard',
  },
  {
    Component: ApplicationsDashboard,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/applications-dashboard',
  },
  {
    Component: ExpertiseDashboard,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/expertise-dashboard',
  },
  {
    Component: Fallback,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: [
      '/:lang/project/:projectId',
      '/:lang/projects/:projectId',
      '/:lang/fundraiser/:projectId',
      '/:lang/initiative/:projectId',
      '/:lang/initiatives/:projectId',
      ' /:lang/fundraiser/:projectId',
      '/:lang/initiative/:projectId',
      '/:lang/collaboration/:projectId',
    ],
  },
  {
    Component: DonationFallback,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/empower-reArmenia',
  },
  {
    Component: ExportPage,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/export',
  },
  {
    Component: OgImage,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/og-image/:projectId',
  },
  {
    Component: OgImageSync,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/og-image-sync',
  },
  {
    Component: FormPageWithType('EXPERT'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/expert-form',
  },
  {
    Component: ExpertNotificationModalMobile,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/expert-notification-modal',
  },
  {
    Component: FormPageWithType('INITIATOR'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/initiator-form',
  },
  {
    Component: ConnectLoading,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/connect/:id',
  },
  {
    Component: ExpertApplicationLoading,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/expert-application/:id',
  },
  {
    Component: Analytics,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/analytics',
  },
  {
    Component: RedirectWithLang('gift-card'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/gift-card',
  },
  {
    Component: RedirectFallback('corporate'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/corporate',
  },
  {
    Component: RedirectFallback('white-label'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/paas',
  },
  {
    Component: RedirectFallback('our-team'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/our-team',
  },
  {
    Component: RedirectFallback('our-impact'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/our-impact',
  },
  {
    Component: RedirectFallback('about-us'),
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/about-us',
  },
  {
    Component: ExpertsConnectModal,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/experts/connectModal',
  },
  {
    Component: ExpertsApplyModal,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/experts/applyModal',
  },
  {
    Component: ListSubscriptions,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/list-subscriptions',
  },
  {
    Component: Experts,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: ['/:lang/experts', '/:lang/experts/:slug'],
  },
  {
    Component: CosmoAdmin,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/cosmo-admin',
  },
  {
    Component: EditExpert,
    Layout: DefaultLayout,
    secured: true,
    exact: true,
    path: '/:lang/edit-expert/:id',
  },
  {
    Component: LanguageSwitcherMobile,
    Layout: DefaultLayout,
    exact: true,
    secured: false,
    path: '/:lang/language',
  },
  {
    Component: CurrencySwitcherMobile,
    Layout: DefaultLayout,
    exact: true,
    secured: false,
    path: '/:lang/currency',
  },
  {
    Component: CheckStripeConnection,
    Layout: DefaultLayout,
    secured: false,
    exact: true,
    path: '/:lang/connect',
  },
]

export default routes
