import { Button, HStack, Img, Input, Stack, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'

export default function Variant1({
  logo,
  heading,
  isSecret,
  isLoading,
  description,
  inputPlaceholder,
  defaultValue,
  buttonText,
  onSubmit,
}) {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      value: defaultValue,
    },
  })

  return (
    <Stack
      as="form"
      p={6}
      borderRadius="16px"
      borderWidth={1}
      borderColor="gray.600"
      spacing={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      <HStack spacing={4}>
        <Img
          src={`/assets/images/${logo}`}
          alt="Logo"
          w="44px"
          objectFit="contain"
        />
        <Stack spacing={1}>
          <Text as="span" fontWeight="500">
            {t(heading)}
          </Text>
          <Text fontSize="md" color="gray.700">
            {t(description)}
          </Text>
        </Stack>
      </HStack>
      <HStack spacing={4}>
        <Input
          size="lg"
          borderWidth={1}
          borderColor="gray.600"
          borderRadius="8px"
          isInvalid={!!errors.key}
          type={isSecret ? 'password' : 'text'}
          placeholder={t(inputPlaceholder)}
          {...register('value', { required: true })}
        />
        <Button
          type="submit"
          variant="green"
          minW="150px"
          px={1}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          {t(buttonText)}
        </Button>
      </HStack>
    </Stack>
  )
}
