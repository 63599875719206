import {
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as ExpertIcon } from 'assets/icons/dashboardExpertIcon.svg'
import { ReactComponent as InitiatorIcon } from 'assets/icons/dashboardInitiatorIcon.svg'
import { ReactComponent as FundraiserIcon } from 'assets/icons/dashboardFundraiserIcon.svg'
import { ReactComponent as DonorIcon } from 'assets/icons/dashboardDonorIcon.svg'
import { ReactComponent as InvestorIcon } from 'assets/icons/dashboardInvestorIcon.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/dashboardPlay.svg'
import Space from './Space'

const RoleCard = ({
  title,
  description,
  onVideoClick,
  Icon,
  color,
  buttonText,
  to,
  video,
}) => (
  <Flex
    borderRadius="20px"
    bg="white"
    direction="column"
    w="350px"
    p="12px 16px 16px"
    mb={16}
  >
    <Flex justifyContent="space-between" alignItems="flex-end" mt="-28px">
      <Flex alignItems="flex-end">
        <Icon />
        <Heading ml={3} color={color} fontSize="4xl" fontWeight={700}>
          {title}
        </Heading>
      </Flex>
      {video && (
        <IconButton onClick={onVideoClick}>
          <PlayIcon />
        </IconButton>
      )}
    </Flex>
    <Text my={3} color="gray.800" textAlign="center" flex={1}>
      {description}
    </Text>
    <Button variant="blue" as={Link} to={to}>
      {buttonText}
    </Button>
  </Flex>
)
const Dashboard = () => {
  const { t } = useTranslation()

  const ROLES = [
    {
      title: t('profile@expert'),
      description: t('profile@dashboard@actAsExpert@description'),
      onVideoClick: () => {
        // Play Video
      },
      Icon: ExpertIcon,
      color: '#465887',
      buttonText: t('profile@dashboard@learnMore'),
      to: '/profile/expert/card',
    },
    {
      title: t('profile@dashboard@initiator'),
      description: t('profile@dashboard@initiator@description'),
      onVideoClick: () => {
        // Play Video
      },
      Icon: InitiatorIcon,
      color: '#4D9CD0',
      buttonText: t('profile@dashboard@learnMore'),
      to: '/experts',
    },
    {
      title: t('profile@dashboard@fundraiser'),
      description: t('profile@dashboard@fundraiser@description'),
      onVideoClick: () => {
        // Play Video
      },
      Icon: FundraiserIcon,
      color: '#3DA4AB',
      buttonText: t('profile@dashboard@learnMore'),
      to: '/pre-create-project',
    },
    {
      title: t('profile@dashboard@donor'),
      description: t('profile@dashboard@donor@description'),
      onVideoClick: () => {
        // Play Video
      },
      Icon: DonorIcon,
      color: '#EEBF45',
      buttonText: t('profile@dashboard@learnMore'),
      to: '/projects',
    },
    {
      title: t('profile@dashboard@investor'),
      description: t('profile@dashboard@investor@description'),
      onVideoClick: () => {
        // Play Video
      },
      Icon: InvestorIcon,
      color: '#E06969',
      buttonText: t('profile@dashboard@learnMore'),
      to: '/fundraisers?type=oneTime',
    },
  ]

  return (
    <Space title={t('profile@dashboard')}>
      <Flex direction="column" mt={10}>
        <Heading fontSize="2xl" fontWeight={500}>
          {t('profile@dashboard@actAs')}
        </Heading>
        <Center>
          <Flex gap="22px" mt={10} wrap="wrap" justifyContent="center">
            {ROLES.map((role) => (
              <RoleCard key={role.title} {...role} />
            ))}
          </Flex>
        </Center>
      </Flex>
    </Space>
  )
}

export default Dashboard
