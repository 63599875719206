import React, { useMemo } from 'react'
import { HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'
import { useNavigation } from 'pages'
import amplitude from 'amplitude-js'

const NavbarButtons = ({ project, peerProject, param, giftCardMode }) => {
  const { t } = useTranslation()
  const { navigationPush } = useNavigation()

  const projectIds = useMemo(() => {
    if (peerProject?.id) {
      return {
        projectToDonateId: peerProject.id,
        peerProjectId: project?.id,
      }
    }

    return {
      projectToDonateId: project?.id,
    }
  }, [project, peerProject])

  const donationUrl = `/donation?projectId=${projectIds.projectToDonateId}${
    projectIds.peerProjectId ? '&peerProjectId=' + projectIds.peerProjectId : ''
  }${param || ''}`

  return (
    <HStack
      zIndex="1000"
      bg="white"
      w="full"
      bottom="0"
      left="0"
      borderTop="1px solid"
      borderColor="border.100"
      justifyContent="space-between"
      py="3"
      px="3"
      position="fixed"
      spacing="3"
    >
      {project.collaborations &&
        !project.noCTA &&
        project.collaborations.length > 0 &&
        project.status === 'live' && (
          <Button
            width="full"
            variant="blue"
            onClick={() =>
              navigationPush(`/act-now?InitiativeId=${project?.id}`)
            }
          >
            {t('support')}
          </Button>
        )}
      {project.isProject && project.status === 'live' && (
        <>
          <Link
            style={{ width: '100%' }}
            to={
              giftCardMode
                ? donationUrl
                : project?.perks?.length > 0
                ? `/donate/${project?.id}${param || ''}`
                : donationUrl
            }
          >
            <Button
              w="full"
              variant="green"
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent('Donate mobile details page button click')
                console.log('sent event to amplitude')
              }}
            >
              {giftCardMode &&
              (!project?.isOneTimeDisabled ||
                project?.fundraisingType === 'oneTime')
                ? t('redeem')
                : t('project@donate')}
            </Button>
          </Link>
        </>
      )}
    </HStack>
  )
}

export default NavbarButtons
