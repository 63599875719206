import { Box, Flex, Heading, Image, Progress, Text } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useQueryParams } from 'hooks'

export default function OgImageSync() {
  const date = moment(new Date()).format('DD.MM.YYYY')
  const params = useQueryParams()

  if (
    !(
      params.has('cover') &&
      params.has('percentage') &&
      params.has('amount') &&
      params.has('goal')
    )
  ) {
    return <>Not enough params</>
  }
  return (
    <Box w="1200px" h="630px" overflow="hidden" id="mainImage" m="0">
      <Image
        w="1200px"
        h="400px"
        objectFit="cover"
        fallbackSrc="/logo.png"
        src={`${
          process.env.REACT_APP_S3_BUCKET_URL
        }/public/thumbnail/medium/${params.get('cover')}`}
      />
      <Box w="1200px" h="230px" background="white" boxSizing="border-box" p={8}>
        <Heading fontSize="40px">Support with your donation</Heading>
        <Progress
          mt={4}
          value={params.get('percentage')}
          borderRadius="100px"
          colorScheme="green"
          h="24px"
          backgroundColor="gray.500"
        />
        <Flex
          mt={6}
          w="full"
          justifyContent="space-between"
          fontSize="32px"
          fontWeight="400"
        >
          <Text>
            <Text as="span" fontWeight="bold">
              {thousandSeparator(params.get('amount'), 'AMD')} AMD{' '}
            </Text>{' '}
            <Text as="span" color="gray.700">
              raised by
            </Text>{' '}
            {date}{' '}
          </Text>
          <Text>
            <Text as="span" color="gray.700">
              Goal
            </Text>{' '}
            {thousandSeparator(params.get('goal'), 'AMD')} AMD
          </Text>
        </Flex>
      </Box>
    </Box>
  )
}
