const filterLang = (items, lang) =>
  items.filter((item) => item.metadata.tags[0].sys.id === lang)

const getImageUrl = (image) => {
  if (!image?.fields?.file?.url) {
    return ''
  }

  return `https:${image.fields.file.url}`
}

const generateContent = (items) =>
  items.map((item) => ({
    id: item.sys.id,
    fullName: item.fields.title,
    slug: item.fields.slug,
    profession: item.fields.profession,
    description: item.fields.description,
    avatar: getImageUrl(item.fields.avatar),
  }))

const testimonialUseCase = (testimonialRepo) => {
  const listTestimonials = async () => {
    const response = await testimonialRepo.listTestimonials()
    const result = {
      skip: response.skip,
      total: response.total,
      limit: response.limit,
      items_en: generateContent(filterLang(response.items, 'en')),
      items_ru: generateContent(filterLang(response.items, 'ru')),
      items_hy: generateContent(filterLang(response.items, 'hy')),
    }
    return result
  }

  return {
    listTestimonials,
  }
}

export default testimonialUseCase
