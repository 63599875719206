import { useQuery } from 'react-query'
import { feedUseCase } from 'core/factories'

const useListFeedByUserId = (input) => {
  const { error, refetch, isLoading, isRefetching, isFetching, data } =
    useQuery(['feed'], async () => {
      const response = await feedUseCase.listFeedByUserId(input)
      return response?.data?.feedByUserId
    })

  return {
    error,
    refetch,
    isRefetching,
    isFetching,
    isLoading,
    data,
  }
}

export default useListFeedByUserId
