import React, { forwardRef } from 'react'
import { Box, Flex, Avatar, Button, HStack } from '@chakra-ui/react'
import Content from 'components/Content'

const UpdateCard = forwardRef(
  (
    {
      update,
      creatorImage,
      currentUpdate,
      creatorName,
      onUpdateControl,
      language = 'en',
      isEditingVisible = false,
      showAvatar = true,
      t,
      isReport,
      ...props
    },
    ref
  ) => {
    const updateControlProps = isReport ? { report: update } : { update }
    return (
      <Box
        pt={7}
        px={6}
        w="100%"
        pb="60px"
        borderRadius="16px"
        borderWidth={1}
        ref={ref}
        borderColor={currentUpdate ? 'red' : 'border.100'}
        {...props}
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            {showAvatar && (
              <Avatar
                bg="gray.500"
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${creatorImage}`}
                size="sm"
              />
            )}
            <Box ml="3">
              <Box
                fontWeight="medium"
                color="black.300"
                fontSize="md"
                as="span"
              >
                {creatorName}
              </Box>
              <Box fontSize="sm" color="gray.700" fontWeight="medium">
                {update.formattedDate} | {update.fromNow}
              </Box>
            </Box>
          </Flex>
          {isEditingVisible && (
            <HStack spacing={4}>
              <Button
                variant="transparent"
                color="orange.500"
                onClick={() => {
                  onUpdateControl({ ...updateControlProps, action: 'edit' })
                }}
              >
                {t('edit')}
              </Button>
              <Button
                variant="transparent"
                color="red"
                onClick={() => {
                  onUpdateControl({ ...updateControlProps, action: 'delete' })
                }}
              >
                {t('remove')}
              </Button>
            </HStack>
          )}
        </Flex>
        <Box height="1px" backgroundColor="border.100" my={5} />
        <Content html={update[`data_${language}`]} />
      </Box>
    )
  }
)

export default UpdateCard
