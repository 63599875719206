/* eslint-disable */
import React from 'react'
import { Flex, Text, Box, Button, Grid, GridItem } from '@chakra-ui/react'
import { calculateProjectDurationLeft } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import ProjectCard from 'components/ProjectCard'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'
import Wrapper from 'components/Wrapper'
import amplitude from 'amplitude-js'

export const SimilarProjects = ({
  projects,
  isLoading,
  isContribution,
  isCollaboration,
}) => {
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  return (
    <Box px={{ base: 0, md: 92 }}>
      <Flex
        mb={{ base: 0, xl: '54px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text ml={4} fontSize="6xl" fontWeight={600}>
          {isContribution
            ? t('contribution@similarProjects')
            : isCollaboration
            ? t('Similar Initiatives')
            : t('Similar Projects')}
        </Text>
        <Button
          display={{ base: 'none', sm: 'block' }}
          fontSize="md"
          fontWeight={700}
          color="blue.400"
          variant="transparent"
          onClick={() => navigationPush('/fundraisers')}
        >
          {t('See all')}
        </Button>
      </Flex>
      <Grid
        templateColumns="repeat(12, 1fr)"
        w="100%"
        gap={4}
        display={{ base: 'none', xl: 'grid' }}
      >
        {[...projects, ...projects, ...projects].map((card, idx) => (
          <GridItem key={card.id + idx} colSpan={3}>
            <ProjectCard
              w="100%"
              card={card}
              goalPrice={card.activeGoal?.amount || card.goal}
              inNeedOfText={t('inNeedOf')}
              raisedMoney={thousandSeparator(
                convert({
                  amount: card.amount,
                  currencyRate: settings?.currency[currency.current],
                }),
                currency.current
              )}
              thousandSeparatorText={thousandSeparator(
                currency.current === 'AMD'
                  ? card.amount + Number(card.updateAmount)
                  : card.amount / Number(settings?.currency[currency.current]) +
                      Number(card.updateAmount),
                currency.current
              )}
              type="fundraisers"
              calculatedProjectDurationLeft={calculateProjectDurationLeft({
                t,
                campaignImplementorPeriod_en: card.campaignImplementorPeriod_en,
                fundraisingType: card.fundraisingType,
              })}
              language={language}
              currency={currency}
              settings={settings}
              changeDefaultCurrency={changeDefaultCurrency}
              navigationPush={navigationPush}
              cardStatusText={t(`status@${card.status}`)}
              cardCategoryText={t(`category@${card.category}`)}
              editText={t('edit')}
              unsubscribeText={t('unsubscribe')}
              monthlyGoalText={t('monthlyGoal')}
              raisedText={t('raised')}
              mx={4}
              collaborationProposalsText={t(
                card?.collaboratorsTotal === 1
                  ? 'collaboration proposal'
                  : 'collaboration proposals'
              )}
              collaborationProposalsTextRuEdit={t('collaboration proposals')
                .split('...')[1]
                .replace('запросов', 'запроса')}
              onClick={() => {
                amplitude
                  .getInstance()
                  .logEvent('Project box in thank you page click')
                console.log('sent event to amplitude')
              }}
            />
          </GridItem>
        ))}
      </Grid>

      <Wrapper
        display={{ base: 'block', xl: 'none' }}
        type="fundraisers"
        swiper={projects}
        isLoading={isLoading}
        isBackgroundGrey={true}
      />
      <Button
        display={{ base: 'block', sm: 'none' }}
        fontSize="md"
        fontWeight={700}
        color="blue.400"
        variant="transparent"
        m={6}
        onClick={() => navigationPush('/fundraisers')}
      >
        {t('See all')}
      </Button>
    </Box>
  )
}
