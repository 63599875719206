import { Box } from '@chakra-ui/react'
import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  useCreateGiftCard,
  useCreateTransaction,
  useCustomToast,
  usePaymentIntent,
  useQueryParams,
} from 'hooks'
import React, { useCallback, useState } from 'react'
import { TRANSACTION_STATUS, TRANSACTION_STATUS_DESCRIPTION } from './constants'
import {
  useCurrencySettings,
  convertTo,
} from 'contexts/CurrencySettingsContext'
import { useTranslation } from 'contexts/TranslationContext'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE)

function ExpressForm({
  project,
  peerProject,
  user,
  amount,
  applicationFeeAmount,
  stripeId,
  paymentMethod,
  currency,
  isContribution,
  getValues,
  watch,
  setError,
  trigger,
  clearErrors,
  settings,
}) {
  const queryParams = useQueryParams()
  const projectId = queryParams.get('projectId')
  const giftCard = queryParams.get('giftCard')
  const peerProjectId = queryParams.get('peerProjectId')

  const isBuyingGiftCard = projectId === 'giftCard'
  const { mutateAsync: createTransactionMutation } = useCreateTransaction()
  const { language, t } = useTranslation()
  const { mutate: createPaymentIntent } = usePaymentIntent()
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)
  const toast = useCustomToast()
  const { mutateAsync: createGiftCardMutation } = useCreateGiftCard()

  const createTransaction = ({
    data,
    creditCard,
    giftCardTransactionType = null,
    giftCard,
    status = TRANSACTION_STATUS.PENDING,
    statusDescription = TRANSACTION_STATUS_DESCRIPTION.PENDING,
    projectId,
  }) => {
    let currencyRate = currency === 'AMD' ? 1 : settings?.currency[currency]
    if (data.currencyRate) {
      currencyRate = data.currencyRate
    }
    let curr = currency
    if (data.currency) {
      curr = data.currency
    }
    const input = {
      userId: user.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      projectId: projectId || project?.id,
      projectTitle_en: peerProject?.title_en || project?.title_en,
      projectTitle_hy: peerProject?.title_hy || project?.title_hy,
      projectTitle_ru: peerProject?.title_ru || project?.title_ru,
      projectImage: peerProject?.cover || project?.cover,
      language,
      donationToReArmenia: Number(
        Number(Number(data.reArmenia) * currencyRate).toFixed(2)
      ),

      donationToProject: Number(Number(watch('amount')) * currencyRate).toFixed(
        curr === 'AMD' ? 0 : 2
      ),
      status,
      statusDescription,
      currency: curr,
      currencyRate,
      isManual: false,
      isAnonymous: data.isAnonymous,
      isInfoAnonymous: data.isInfoAnonymous,
      isStripe: true,
    }

    if (peerProjectId) {
      input.peerProjectId = peerProjectId
    }

    if (user.id === 'guest') {
      input.firstName = getValues('firstName')
      input.lastName = getValues('lastName')
      input.email = getValues('email')
    }
    if (creditCard) {
      input.creditCard = creditCard
    }
    input.amount = Number(
      Number(
        (Number(input.donationToProject) + Number(input.donationToReArmenia))
          .toString()
          .split('.')
          .slice(0, 2)
          .join('.')
      ).toFixed(2)
    )

    if (giftCardTransactionType) {
      input.giftCardId = giftCard.id
      input.giftCardTransactionType = giftCardTransactionType
      input.giftCardAmount = giftCard.amount
      input.amount = giftCard.amount
      if (giftCardTransactionType === 'buy') {
        input.projectTitle_en = 'Gift card'
        input.projectTitle_ru = 'Подарочная карта'
        input.projectTitle_hy = 'Նվեր քարտ'
      } else {
        input.giftCardCode = giftCard.code
      }
      input.projectImage = giftCard.image
    }
    return createTransactionMutation(input)
  }

  const createGiftCard = useCallback(
    ({
      message,
      from,
      fromEmail,
      language,
      toEmail,
      image,
      amount,
      currency,
      currencyRate,
    }) => {
      const giftCardInput = {
        userId: user.id,
        amount,
        image,
        currency,
        currencyRate,
        paymentStatus: 'pending',
        used: false,
        message,
        from,
        fromEmail,
        language,
        toEmail,
      }
      return createGiftCardMutation(giftCardInput)
    },
    [user]
  )
  const onConfirm = useCallback(
    async (event) => {
      if (!stripe) {
        return
      }
      const currencyRate = currency === 'AMD' ? 1 : settings?.currency[currency]

      const { error } = await elements.submit()
      if (error) {
        setErrorMessage(error.message)
        return
      }
      const transactionInput = {
        data: {
          amount: +amount / 100,
          reArmenia: applicationFeeAmount,
          email: user?.email,
          currencyRate,
          currency,
          firstName: user?.firstName,
          lastName: user?.lastName,
          projectId: project?.id,
          projectTitle_en: project?.title_en,
          projectTitle_hy: project?.title_hy,
          projectTitle_ru: project?.title_ru,
          projectImage: project?.cover,
          language,
          isManual: false,
          isAnonymous: false,
          isInfoAnonymous: false,
        },
        status: TRANSACTION_STATUS.PENDING,
        statusDescription: TRANSACTION_STATUS_DESCRIPTION.PENDING,
      }

      transactionInput.creditCard = {
        type: paymentMethod,
        pan: paymentMethod === 'GOOGLE_PAY' ? 'Google Pay' : 'Apple Pay',
      }

      if (isBuyingGiftCard) {
        console.log({
          amount,
          currency,
        })
        const createdGiftCard = await createGiftCard({
          message: getValues('yourMessage'),
          from: getValues('from'),
          fromEmail: user.email,
          language,
          toEmail: getValues('toEmail'),
          image: giftCard,
          currency,
          currencyRate,
          amount: Number(
            Number(transactionInput.data.amount - (applicationFeeAmount || 0)) *
              currencyRate
          ).toFixed(currency.current === 'AMD' ? 0 : 2),
        })
        transactionInput.giftCard = createdGiftCard
        transactionInput.giftCardTransactionType = 'buy'
        transactionInput.projectId = 'giftCard'
      }

      const transaction = await createTransaction(transactionInput)

      const paymentIntentInput = {
        amount: +watch('amount'),
        applicationFeeAmount,
        currency,
        connectedAccountId:
          isBuyingGiftCard || isContribution
            ? process.env.REACT_APP_STRIPE_ACCT
            : stripeId,
        metadata: {
          projectId: project?.id,
          projectTitle_en: project?.title_en,
          userId: user?.id,
          transactionId: transaction?.data?.id,
        },
      }

      if (peerProjectId) {
        paymentIntentInput.metadata.peerProjectId = peerProjectId
      }

      createPaymentIntent(paymentIntentInput, {
        onSuccess: async (data) => {
          const { client_secret: clientSecret } = data
          const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/${language}/check-transaction-stripe?transactionId=${transaction.data.createTransaction.id}&clientSecret=${clientSecret}`,
            },
          })

          if (error) {
            toast({
              title: 'Error',
              description: error?.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
            return
          }
          toast({
            title: 'Success',
            description: 'Payment successful',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        },
        onError: (err) => {
          console.log('❌', err)
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      })
    },
    [
      stripe,
      elements,
      user,
      amount,
      applicationFeeAmount,
      currency,
      paymentMethod,
    ]
  )
  const isValidAmount = (amount) => {
    return (
      convertTo({
        settings,
        amount,
        from: currency,
        to: 'USD',
      }) > 0.5
    )
  }

  const triggerCustomValidation = () => {
    if (
      [watch('firstName'), watch('lastName'), watch('email')].some(
        (e) => !e || e === 'guest'
      )
    ) {
      trigger('firstName')
      trigger('lastName')
      trigger('email')
    }
    if (!isValidAmount(watch('amount'))) {
      setError('amount')
      toast({
        title: t('toaster@atLeast50cents'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else {
      clearErrors('amount')
    }
  }
  const formIsInvalid =
    [watch('firstName'), watch('lastName'), watch('email')].some(
      (e) => !e || e === 'guest'
    ) || !isValidAmount(watch('amount'))

  return (
    <Box pos="relative">
      {formIsInvalid && (
        <Box
          w="full"
          h="full"
          pos="absolute"
          zIndex={2}
          onClick={triggerCustomValidation}
          cursor="pointer"
        />
      )}
      {paymentMethod === 'GOOGLE_PAY' && (
        <ExpressCheckoutElement
          options={{
            wallets: {
              googlePay: 'always',
              applePay: 'never',
            },
            buttonType: {
              applePay: isBuyingGiftCard ? 'buy' : 'donate',
              googlePay: isBuyingGiftCard ? 'buy' : 'donate',
            },
            paymentMethodOrder: ['googlePay', 'applePay'],
            layout: {
              maxRows: 1,
              maxColumns: 1,
              overflow: 'auto',
            },
          }}
          onConfirm={onConfirm}
        />
      )}
      {paymentMethod === 'APPLE_PAY' && (
        <ExpressCheckoutElement
          options={{
            wallets: {
              googlePay: 'never',
              applePay: 'always',
            },
            buttonType: {
              applePay: isBuyingGiftCard ? 'buy' : 'donate',
              googlePay: isBuyingGiftCard ? 'buy' : 'donate',
            },
            paymentMethodOrder: ['googlePay', 'applePay'],
            layout: {
              maxRows: 1,
              maxColumns: 1,
              overflow: 'auto',
            },
          }}
          onConfirm={onConfirm}
        />
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </Box>
  )
}

function WalletPayButton({
  project,
  peerProject,
  user,
  amount,
  watch,
  trigger,
  setError,
  clearErrors,
  applicationFeeAmount,
  currency,
  isContribution,
  paymentMethod,
  getValues,
}) {
  const { settings } = useCurrencySettings()
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'payment',
        amount: amount === 0 ? 100000 : Math.round(Number(amount) * 100), //TODO 10000 / 100 AMD still smaller than 0.5 cents
        currency: currency.toLowerCase(),
        automatic_payment_methods: { enabled: true },
      }}
    >
      <ExpressForm
        project={project}
        peerProject={peerProject}
        settings={settings}
        user={user}
        amount={amount === 0 ? 100000 : Math.round(Number(amount) * 100)}
        applicationFeeAmount={applicationFeeAmount}
        stripeId={project?.stripeId}
        paymentMethod={paymentMethod}
        currency={currency}
        isContribution={isContribution}
        getValues={getValues}
        watch={watch}
        trigger={trigger}
        setError={setError}
        clearErrors={clearErrors}
      />
    </Elements>
  )
}

export default WalletPayButton
