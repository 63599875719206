const colors = {
  placeholder: {
    100: '#6d6d6d',
  },
  blue: {
    200: '#1D6ECF',
    300: '#003D97',
    400: '#012F73',
  },
  orange: {
    100: '#FEEBCB',
    300: '#FC8600',
    400: '#ED8B00',
  },
  green: {
    200: '#3EC997',
    300: '#2EC974',
    400: '#22BE68',
    500: '#00C35D',
  },
  gray: {
    200: '#FAFAFA',
    300: '#F5F4F7',
    350: '#F3F3F3',
    400: '#EAECEF',
    500: '#DDDDDD',
    600: '#CDCDCD',
    650: '#949191',
    700: '#737373',
    800: '#272727',
    900: '#1A1A1A',
    1000: '#696A6F',
  },
  border: {
    100: '#DDDDDD',
    200: '#BCC3CC',
    300: '#B6B6B6',
    400: '#CDCDCD',
    500: '#EAECEF',
  },
  purple: {
    400: '#9F7AEA',
  },
  red: {
    400: '#DB4151',
  },
  social: {
    facebook: '#1877F2',
    twitter: '#1FA1F1',
    linkedin: '#2867B2',
    vkontakte: '#5181B8',
  },
}

export default colors
