import { useMutation } from 'react-query'
import { emailUseCase } from 'core/factories'

const useUpdatesEmail = () => {
  const { mutate, data, error, isLoading } = useMutation(
    emailUseCase.updatesEmail
  )
  return {
    mutate,
    data,
    error,
    isLoading,
  }
}

export default useUpdatesEmail
