import React from 'react'
import { Box, Flex, Spinner, VStack } from '@chakra-ui/react'
import { useGetProject, useStringManipulations } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import useGetUpdatesByProjectId from 'hooks/update/useGetProjectUpdates'
import UpdateCard from 'components/UpdateCard'
import EmptyState from 'components/EmptyState'

export default function Impact({ user }) {
  const REARMENIA_ID = '6d530a79-e071-49b6-95ea-51323f1d850c'
  const { t, language } = useTranslation()
  const { findNamingTranslation } = useStringManipulations()
  const { data: updates, isLoading } = useGetUpdatesByProjectId({
    projectId: REARMENIA_ID,
    sortDirection: 'DESC',
  })

  const { data: project, isLoading: isProjectLoading } = useGetProject({
    projectId: REARMENIA_ID,
  })
  return (
    <Flex
      justify="center"
      h="100%"
      w={{
        base: 'full',
        sm:
          !user.isReArmeniaInvestor || isLoading || isProjectLoading
            ? 'full'
            : '716px',
      }}
    >
      {!user.isReArmeniaInvestor ? (
        <EmptyState
          description={t('profile@dashboard@investor@impact@emptyDescription')}
          button={t('profile@dashboard@investor@impact@emptyButton')}
          link="/fundraisers?type=oneTime"
        />
      ) : isLoading || isProjectLoading ? (
        <Spinner />
      ) : updates && updates?.length > 0 && project ? (
        <VStack spacing={6} alignItems="flex-start">
          {updates.map((update) => (
            <UpdateCard
              key={update.id}
              t={t}
              update={update}
              creatorImage={project.creatorImage}
              creatorName={findNamingTranslation({
                parentObject: project,
                value: 'creatorName',
                language,
              })}
              language={language}
              showAvatar
              bg="white"
            />
          ))}
        </VStack>
      ) : (
        <Box
          fontWeight="800"
          my="12"
          display="block"
          fontSize="6xl"
          color="blue.300"
          as="span"
        >
          {t('project@updates')}{' '}
          <Box as="span" fontWeight="400">
            {t('project@updatesText')}
          </Box>
        </Box>
      )}
    </Flex>
  )
}
