import React from 'react'
import {
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactComponent as AngleDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as AngleTop } from 'assets/icons/angleTop.svg'

export default function DonorSettingsSelect({
  t,
  label,
  options,
  translationKey,
  selectedOptions,
  setSelectedOptions,
  isLoading,
  onMenuClose,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleMenuItemClick = (item) => {
    if (selectedOptions?.includes(item)) {
      setSelectedOptions(
        selectedOptions.filter((selectedItem) => selectedItem !== item)
      )
    } else {
      setSelectedOptions([...selectedOptions, item])
    }
  }

  const handleSelectAll = () => {
    setSelectedOptions(options)
  }

  const handleClearSelection = () => {
    setSelectedOptions([])
  }

  return (
    <Menu onClose={() => onMenuClose(onClose)} closeOnSelect={false}>
      <MenuButton
        isDisabled={isLoading}
        as={Button}
        w="242px"
        mt={{ md: 0, base: translationKey === 'region' ? 4 : 0 }}
        border="1px solid"
        borderColor="gray.500"
        _active={{
          borderColor: 'blue.400',
        }}
        _focus={{
          borderColor: 'blue.400',
        }}
        borderRadius="lg"
        bg="white"
        color="gray.800"
        fontSize="md"
        fontWeight="regular"
        textTransform="capitalize"
        onClick={onOpen}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          py={2.5}
          pl={4}
          pr={3}
        >
          {label}
          {isOpen ? (
            <AngleTop width={10} height={8} />
          ) : (
            <AngleDown width={10} height={8} />
          )}
        </Flex>
      </MenuButton>
      <MenuList mt={1} bg="white" color="gray.800">
        <MenuItem bg="white" py={2} pl={4}>
          <Text fontSize="md" color="blue.400">
            {label}
          </Text>
        </MenuItem>
        {options?.map((item) => (
          <MenuItem key={item} value={item} bg="white">
            <Checkbox
              isChecked={selectedOptions?.includes(item)}
              onChange={() => handleMenuItemClick(item)}
            >
              {t(`${translationKey}@${item}`)}
            </Checkbox>
          </MenuItem>
        ))}
        {translationKey === 'category' && (
          <MenuItem
            as={Flex}
            py={2}
            px={4}
            justifyContent="space-between"
            bg="white"
          >
            <Text onClick={handleSelectAll} fontSize="md" color="blue.400">
              {t('profile@dashboard@donor@settings@SelectAll')}
            </Text>
            <Text onClick={handleClearSelection} fontSize="md" color="gray.700">
              {t('profile@dashboard@donor@settings@Clear')}
            </Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
