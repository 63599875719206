import { useEffect } from 'react'
import {
  Button,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
  Image,
  ModalHeader,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { useGiftCard } from 'contexts/GiftCardContext'
import { useTranslation } from 'contexts/TranslationContext'
import { useListGiftCardsByCode, useQueryParams } from 'hooks'
import { useHistory } from 'react-router-dom'

const w = window.innerWidth

export default function RedeemGiftCardModal({ closeModal }) {
  const { t } = useTranslation()
  const params = useQueryParams()
  const giftCardCode = params.get('giftCardCode')
  const { mutate: getGiftCard, data, isLoading } = useListGiftCardsByCode()
  const { setGiftCardMode, setGiftCard, giftCard } = useGiftCard()
  const history = useHistory()
  useEffect(() => {
    if (w < 485) {
      closeModal()

      // TODO: uncomment and remove history push
      // navigationPush(`/redeem-mobile?giftCardCode=${giftCardCode}`)

      history.push(`/hy/redeem-mobile?giftCardCode=${giftCardCode}`)
    }
    if (giftCardCode || giftCard) {
      getGiftCard(
        { code: giftCardCode || giftCard.code },
        {
          onSuccess: (giftCard) => {
            giftCard.amountWithCurrency = Math.floor(
              Number(giftCard.amount) / Number(giftCard.currencyRate)
            )
            setGiftCard(giftCard)
          },
        }
      )
    }
  }, [])

  const onGiftCardUse = () => {
    if (data && !data?.used) {
      setGiftCardMode(true)
      closeModal()
    }
    closeModal()
  }

  return (
    <>
      <ModalCloseButton
        color="gray"
        _active={{ background: 'transparent' }}
        _hover={{ background: 'transparent' }}
        position="absolute"
      />
      <ModalHeader padding="0" w="full">
        <Image
          src={
            isLoading || (data && !data?.used)
              ? '/assets/images/dialog.jpg'
              : '/assets/images/alreadyRedeemed.jpg'
          }
          borderTopRadius="12px"
          width="100%"
          height="190px"
          objectFit="cover"
          w="full"
        />
      </ModalHeader>
      <ModalBody
        my={12}
        px="8"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        {isLoading ? (
          <Flex>
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <>
            <Heading fontSize="2xl" textAlign="center">
              {t(`giftCard@modal@title${data && !data?.used ? '' : '@done'}`)}
            </Heading>
            <Text fontSize="lg" textAlign="center" mt={2}>
              {t(
                `giftCard@modal@description${
                  data && !data?.used ? '' : '@done'
                }`
              )}
            </Text>
            <Button mt="12" variant="orange" onClick={onGiftCardUse}>
              {t('giftCard@modal@button')}
            </Button>
          </>
        )}
      </ModalBody>
    </>
  )
}
