/* eslint-disable */
import { useEffect, useState } from 'react'
import { post } from 'aws-amplify/api'
import { Link as ReactLink } from 'react-router-dom'
import {
  Stack,
  HStack,
  Image,
  Button,
  Flex,
  Text,
  IconButton,
  Checkbox,
  Link,
  Radio,
  Center,
  Heading,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser, useQueryParams } from 'hooks'
import { BANK_CARD_TYPES } from '_constants'
import CreditCardItem from './CreditCardItem'
import StripeCard from './StripeCard'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as GiftCard } from 'assets/icons/giftCardIcon.svg'
import { ReactComponent as CreditCard } from 'assets/icons/credit-card.svg'
import { PAYMENT_METHODS_DATA } from 'pages/Donation/constants'
import ApplyGiftCard from './ApplyGiftCard'
import PaymentGuestUserFormDonationPage from 'components/PaymentGuestUserFormDonationPage'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

export default function CreditCards({
  page,
  fullRedirectUrl,
  setPaymentMethod,
  getValues,
  removable,
  makeDefault,
  privacyPolicy,
  showIcons,
  onClick,
  isGiftCardDisabled,
  showGiftCard,
  setValue,
  paymentMethod,
  isRecurring,
  isContribution,
  donationPage,
  showStripe,
  clearErrors,
  trigger,
  register,
  errors = {},
  amount,
  scheme = 'white',
  setTokenId,
  setNewStripeCard,
}) {
  const queryParams = useQueryParams()
  const { t, language } = useTranslation()
  const { user } = useUser()
  const [read, toggleRead] = useState(true)
  const [error, setError] = useState(false)
  const [loading, toggleLoading] = useState(false)

  const onCardAdd = async () => {
    if (loading) {
      return
    }
    if (!read) {
      return setError(true)
    }
    toggleLoading(true)

    let redirectUrl = `${window.location.protocol}//${window.location.host}/${language}/check-binding`

    if (page === 'donation') {
      redirectUrl += `?donationInfo=${queryParams.get(
        'projectId'
      )},${queryParams.get('amount')}`
    }
    if (fullRedirectUrl) {
      redirectUrl += `?redirectUrl=${fullRedirectUrl}`
    }

    const data = {
      body: {
        userEmail: user.email,
        description: `Dear ${user.firstName} ${user.lastName}, fill in your card details so we can attach a card to your profile. Transaction ID - `,
        redirectUrl,
        language,
      },
    }

    try {
      const res = post({
        apiName: 'paymentAPI',
        path: '/binding',
        options: data,
      })

      const { formUrl } = await getResponseToJSON(res)
      window.location = formUrl.replace('_binding', '')
    } catch (err) {
      console.log('❌', err)
    } finally {
      toggleLoading(false)
    }
  }

  const onCardClick = (creditCard) => {
    if (setValue) {
      setValue('paymentMethod', creditCard.bindingId)
      return onClick(creditCard.bindingId)
    }
  }

  useEffect(() => {
    if (donationPage) {
      clearErrors(['firstName', 'lastName', 'email'])
    }
  }, [paymentMethod])
  return (
    <Wrapper
      showIcons={showIcons}
      error={error}
      read={read}
      privacyPolicy={
        privacyPolicy && (!user.creditCards || user.creditCards.length === 0)
      }
      toggleRead={toggleRead}
      setError={setError}
    >
      <Stack spacing={3}>
        {!donationPage && (
          <>
            {user.creditCards?.map((creditCard, index) => (
              <CreditCardItem
                key={`credit-card-${creditCard.pan}-${index}`}
                removable={removable}
                index={index}
                isSelected={creditCard.bindingId === paymentMethod}
                makeDefault={makeDefault}
                creditCard={creditCard}
                onClick={onClick}
                onCardClick={onCardClick}
                scheme={scheme}
              />
            ))}
            {user.stripeAttachedCards?.map(({ id, type, last4 }, index) => (
              <CreditCardItem
                key={`credit-card-${id}-${index}`}
                removable={removable}
                index={index}
                isSelected={id === paymentMethod}
                makeDefault={makeDefault}
                creditCard={{ bindingId: id, type, pan: `**** ${last4}` }}
                onClick={onClick}
                onCardClick={onCardClick}
                scheme={scheme}
              />
            ))}
          </>
        )}
        {donationPage && showStripe && (
          <>
            {user.stripeAttachedCards?.map(({ id, last4, type }) => (
              <CardSavingSelection
                key={id}
                Icon={
                  isSupportedType(type) ? (
                    <Image src={`/assets/images/${type}.png`} h="20px" />
                  ) : (
                    <CreditCard style={{ height: '28px' }} />
                  )
                }
                text={`****  ${last4} ${t('savedCard')} `}
                type="attach"
                isSelected={paymentMethod === id}
                onClick={() => onCardClick({ bindingId: id })}
              />
            ))}
            <StripeCard
              setPaymentMethod={setPaymentMethod}
              getValues={getValues}
              register={register}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              user={user}
              amount={amount}
              isRecurring={isRecurring}
              paymentMethod={paymentMethod}
              setTokenId={setTokenId}
              setNewStripeCard={setNewStripeCard}
              isInvalid={errors.firstName || errors.lastName || errors.email}
            />
            {/* Google pay */}
            {/* Apple pay */}
            {!isRecurring && donationPage && showStripe && !isContribution && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA.GOOGLE_PAY.image}
                text={PAYMENT_METHODS_DATA.GOOGLE_PAY.title}
                type="GOOGLE_PAY"
                isSelected={paymentMethod === 'GOOGLE_PAY'}
                onClick={() => {
                  setValue('paymentMethod', 'GOOGLE_PAY')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' && (
                  <PaymentGuestUserFormDonationPage
                    register={register}
                    errors={errors}
                    isRecurring={isRecurring}
                    t={t}
                    firstName={t('donation@firstName')}
                    lastName={t('donation@lastName')}
                    email={t('donation@email')}
                    emailErrorMessage={t(errors?.email?.message)}
                  />
                )}
              </CardSavingSelectionWithChildren>
            )}
            {!isRecurring &&
              donationPage &&
              showStripe &&
              !isContribution &&
              window.ApplePaySession && (
                <CardSavingSelectionWithChildren
                  src={PAYMENT_METHODS_DATA.APPLE_PAY.image}
                  text={PAYMENT_METHODS_DATA.APPLE_PAY.title}
                  type="APPLE_PAY"
                  isSelected={paymentMethod === 'APPLE_PAY'}
                  onClick={() => {
                    setValue('paymentMethod', 'APPLE_PAY')
                  }}
                  isInvalid={
                    errors.firstName || errors.lastName || errors.email
                  }
                >
                  {user.id === 'guest' && (
                    <PaymentGuestUserFormDonationPage
                      register={register}
                      errors={errors}
                      isRecurring={isRecurring}
                      t={t}
                      firstName={t('donation@firstName')}
                      lastName={t('donation@lastName')}
                      email={t('donation@email')}
                      emailErrorMessage={t(errors?.email?.message)}
                    />
                  )}
                </CardSavingSelectionWithChildren>
              )}
          </>
        )}
        {/* {!removable && showStripe && (
          <CardSavingSelectionWithChildren
            src={PAYMENT_METHODS_DATA['MIR'].image}
            text={PAYMENT_METHODS_DATA['MIR'].title}
            type="attach"
            isSelected={paymentMethod === 'MIR'}
            onClick={() => {
              setValue('paymentMethod', 'MIR')
            }}
            isInvalid={errors.firstName || errors.lastName || errors.email}
          >
            {user.id === 'guest' ? (
              <PaymentGuestUserFormDonationPage
                register={register}
                errors={errors}
                isRecurring={isRecurring}
                t={t}
                firstName={t('donation@firstName')}
                lastName={t('donation@lastName')}
                email={t('donation@email')}
                emailErrorMessage={t(errors?.email?.message)}
              />
            ) : isRecurring ? (
              <Text fontSize="md" mt={3}>
                <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
                {t('donation@cardWillBeSaved').split('(bold)')[1]}
              </Text>
            ) : (
              <Checkbox mt={3} {...register('attachCard')}>
                <Text fontSize="sm" fontWeight={400}>
                  {t('donation@saveCard').split('(bold)')[0]}
                  <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                  {t('donation@saveCard').split('(bold)')[2]}
                </Text>
              </Checkbox>
            )}
          </CardSavingSelectionWithChildren>
        )} */}
        {removable ? (
          <>
            {user?.creditCards?.length ? (
              <Center
                py={3}
                px={6}
                borderRadius={8}
                borderWidth="1px"
                borderColor="border.500"
                cursor="pointer"
                bg="border.500"
                onClick={onCardAdd}
              >
                <IconButton
                  w="24px"
                  h="24px"
                  minW="24px"
                  minH="24px"
                  borderRadius="100px"
                  icon={<Plus />}
                  isLoading={loading}
                  variant="blue"
                  mr={3}
                />
                <Text color="blue.400" fontSize="md" fontWeight="700">
                  {t('profile@settings-payments-attach-new-card')}
                </Text>
              </Center>
            ) : (
              <Button
                isLoading={loading}
                onClick={onCardAdd}
                variant="blue"
                borderRadius={{ base: '12px', sm: '8px' }}
                fontSize="md"
                fontWeight="700"
              >
                {t('profile@settings-payments-attach-card')}
              </Button>
            )}
          </>
        ) : (
          <>
            {/* @FIXME:
            - below it is a quick solution to disable ARCA for a specific project
            - we need a long term and automated solution so admin can decide whether project can use ARCA or not
          */}
            {/* {queryParams.get('projectId') !==
              'fdc74c71-2f70-4722-a721-df496b08467c' && (
              <>
                <Flex direction="column" mt={3} mb={1}>
                  <Heading
                    as="h2"
                    fontSize={{ base: 'lg', sm: '2xl' }}
                    mb={1.5}
                    fontWeight={500}
                  >
                    {t('donation@cardsIssuedInArmenia')}
                  </Heading>
                  <Text fontSize={{ base: 'md', sm: 'lg' }}>
                    {t('donation@cardsInArmeniaTax')}
                  </Text>
                </Flex>
                {user?.creditCards?.map((creditCard, index) => (
                  <CreditCardItem
                    key={`credit-card-${creditCard.pan}-${index}`}
                    removable={removable}
                    index={index}
                    isSelected={creditCard.bindingId === paymentMethod}
                    makeDefault={makeDefault}
                    creditCard={creditCard}
                    onClick={onClick}
                    onCardClick={onCardClick}
                    scheme={scheme}
                  />
                ))}

                <CardSavingSelectionWithChildren
                  src={PAYMENT_METHODS_DATA['ARCA_VISA_MASTER'].image}
                  text={PAYMENT_METHODS_DATA['ARCA_VISA_MASTER'].title}
                  type="attach"
                  isSelected={paymentMethod === 'ARCA_VISA_MASTER'}
                  onClick={() => {
                    setValue('paymentMethod', 'ARCA_VISA_MASTER')
                  }}
                  isInvalid={
                    errors.firstName || errors.lastName || errors.email
                  }
                >
                  {user.id === 'guest' ? (
                    <>
                      {donationPage && (
                        <PaymentGuestUserFormDonationPage
                          register={register}
                          errors={errors}
                          isRecurring={isRecurring}
                          t={t}
                          firstName={t('donation@firstName')}
                          lastName={t('donation@lastName')}
                          email={t('donation@email')}
                          emailErrorMessage={t(errors?.email?.message)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {isRecurring ? (
                        <Text fontSize="md" mt={3}>
                          <b>
                            {t('donation@cardWillBeSaved').split('(bold)')[0]}
                          </b>
                          {t('donation@cardWillBeSaved').split('(bold)')[1]}
                        </Text>
                      ) : (
                        <Checkbox mt={3} {...register('attachCard')}>
                          <Text fontSize="sm" fontWeight={400}>
                            {t('donation@saveCard').split('(bold)')[0]}
                            <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                            {t('donation@saveCard').split('(bold)')[2]}
                          </Text>
                        </Checkbox>
                      )}
                    </>
                  )}
                </CardSavingSelectionWithChildren>
              </>
            )} */}
            {/* {!removable && !showStripe && (
              <CardSavingSelectionWithChildren
                src={PAYMENT_METHODS_DATA['MIR'].image}
                text={PAYMENT_METHODS_DATA['MIR'].title}
                type="attach"
                isSelected={paymentMethod === 'MIR'}
                onClick={() => {
                  setValue('paymentMethod', 'MIR')
                }}
                isInvalid={errors.firstName || errors.lastName || errors.email}
              >
                {user.id === 'guest' ? (
                  <PaymentGuestUserFormDonationPage
                    register={register}
                    errors={errors}
                    isRecurring={isRecurring}
                    t={t}
                    firstName={t('donation@firstName')}
                    lastName={t('donation@lastName')}
                    email={t('donation@email')}
                    emailErrorMessage={t(errors?.email?.message)}
                  />
                ) : isRecurring ? (
                  <Text fontSize="md" mt={3}>
                    <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
                    {t('donation@cardWillBeSaved').split('(bold)')[1]}
                  </Text>
                ) : (
                  <Checkbox mt={3} {...register('attachCard')}>
                    <Text fontSize="sm" fontWeight={400}>
                      {t('donation@saveCard').split('(bold)')[0]}
                      <b>{t('donation@saveCard').split('(bold)')[1]}</b>
                      {t('donation@saveCard').split('(bold)')[2]}
                    </Text>
                  </Checkbox>
                )}
              </CardSavingSelectionWithChildren>
            )} */}
            {showGiftCard && (
              <>
                <Flex direction="column" mt={3} mb={1}>
                  <Heading
                    as="h2"
                    fontSize={{ base: 'lg', sm: '2xl' }}
                    mb={1.5}
                    fontWeight={500}
                  >
                    {t('donation@giftCardRedeem')}
                  </Heading>
                  <Text fontSize={{ base: 'md', sm: 'lg' }}>
                    {t('donation@giftCardCode')}
                  </Text>
                </Flex>

                <CardSavingSelectionWithChildren
                  Icon={GiftCard}
                  text={t('Gift card')}
                  type="attach"
                  isSelected={paymentMethod === 'giftCard'}
                  onClick={() => {
                    onCardClick({ bindingId: 'giftCard' })
                  }}
                  isInvalid={errors.giftCardCode}
                >
                  <ApplyGiftCard
                    isGiftCardDisabled={isGiftCardDisabled}
                    register={register}
                    getValues={getValues}
                    trigger={trigger}
                  />
                </CardSavingSelectionWithChildren>
              </>
            )}
          </>
        )}
      </Stack>
    </Wrapper>
  )
}
const CardSavingSelection = ({ text, isSelected, Icon, ...props }) => (
  <Flex
    h="64px"
    p="22px"
    align="center"
    borderRadius={{ base: '12px', sm: '8px' }}
    borderWidth="1px"
    borderColor={isSelected ? 'blue.300' : 'border.500'}
    position="relative"
    alignItems="center"
    justifyContent="space-between"
    cursor={isSelected ? 'default' : 'pointer'}
    _hover={{
      'button[datatype="remove"]': {
        opacity: 1,
      },
    }}
    {...props}
  >
    <Flex alignItems="center">
      {Icon}
      <Text ml={4} color="black.100" fontSize="md">
        {text}
      </Text>
    </Flex>

    <Flex alignItems="center">
      <Radio value="1" isChecked={isSelected}></Radio>
    </Flex>
  </Flex>
)

const CardSavingSelectionWithChildren = ({
  text,
  isSelected,
  Icon,
  src,
  children,
  isInvalid,
  ...props
}) => (
  <Flex
    p="22px"
    justify="center"
    direction="column"
    borderRadius={8}
    borderWidth="1px"
    borderColor={
      isInvalid && isSelected
        ? 'red.400'
        : isSelected
        ? 'blue.300'
        : 'border.500'
    }
    h={isSelected ? 'auto' : '64px'}
    {...props}
  >
    <Flex justify="space-between">
      <Flex alignItems="center">
        {src && <Image src={src} h="20px" />}
        {Icon && <Icon />}

        <Text ml={4} color="black.100" fontSize="md">
          {text}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Radio value="1" isChecked={isSelected}></Radio>
      </Flex>
    </Flex>
    {isSelected && children}
  </Flex>
)

const Wrapper = ({
  privacyPolicy,
  read,
  toggleRead,
  error,
  setError,
  showIcons,
  children,
}) => {
  const { t } = useTranslation()

  const onChange = (e) => {
    toggleRead(e.target.value)
    if (e.target.value) {
      setError(false)
    }
  }

  return (
    <>
      {showIcons && (
        <HStack mb={4} spacing={1} align="center" height="20px">
          {BANK_CARD_TYPES.map((bankCardType) => (
            <Image
              key={`bank-card-type-${bankCardType}`}
              src={`/assets/images/${bankCardType}.png`}
              alt={bankCardType}
              width="20px"
              height="14px"
            />
          ))}
        </HStack>
      )}
      {children}
      {privacyPolicy && (
        <Checkbox
          mt={8}
          mb={1}
          value={read}
          borderColor={error ? 'red.400' : 'border.200'}
          onChange={onChange}
        >
          {t('profile@settings-payments-have-read')}&nbsp;
          <Link as={ReactLink} target="_blank" to="/privacy-policy">
            {t('profile@settings-payments-privacy-policy')}
          </Link>
        </Checkbox>
      )}
    </>
  )
}

const isSupportedType = (type) =>
  ['master', 'mastercard', 'visa'].includes(type.toLowerCase())
