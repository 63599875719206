import moment from 'moment'
import {
  calcDuplicateTransaction,
  fetchItemsWhileEmpty,
  investmentParams,
  normalizeInvestmentResponse,
} from './helpers'

const transactionUseCase = (transactionRepo) => {
  const getTransaction = async (id) => {
    if (!id) {
      throw Error('ID is required')
    }
    const transactionResponse = await transactionRepo.getTransaction(id)
    return transactionResponse?.data?.getTransaction
  }

  const listTransactions = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByGiftCardCode(
        variables
      )

      let total = 0
      transactions.data.transactionsByDate.items =
        transactions.data.transactionsByDate.items.map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            transaction.double = false
            if (total < 20_000_000) {
              transaction.double = true
              const amount = calcDuplicateTransaction(
                total,
                20_000_000,
                transaction.donationToProject
              )
              // transaction.donationToProject = transaction.donationToProject * 2
              // total += transaction.donationToProject
              transaction.donationToProject = amount
              total += amount
            }
          }
          if (
            transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
          ) {
            transaction.double = false
            if (total < 14_000_000) {
              transaction.double = true
              const amount = calcDuplicateTransaction(
                total,
                14_000_000,
                transaction.donationToProject
              )
              // transaction.donationToProject = transaction.donationToProject * 2
              // total += transaction.donationToProject
              transaction.donationToProject = amount
              total += amount
            }
          }
          return transaction
        })
      transactions = transactions.sort((a, b) => a.createdAt - b.createdAt)
      return transactions
    } catch (e) {
      console.log('#ed list transactions error', e)
    }

    return []
  }

  const listTransactionsByGiftCardCode = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactions(variables)
      let total = 0
      transactions = transactions
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            if (total < 20000000) {
              transaction.amount = transaction.amount * 2
              total += transaction.amount
            }
          }

          return transaction
        })
      return transactions
    } catch (e) {
      console.log('#ed list transactions error', e)
    }

    return []
  }

  const listTransactionsByDate = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByDate(variables)
      let total = 0

      if (variables.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1') {
        const duplicateCursorIndex =
          transactions.data.transactionsByDate.items.findIndex(
            (item) => item.id === '51ac221f-384f-449e-a128-8b1b4a14e412'
          )
        if (duplicateCursorIndex !== -1) {
          for (let item of transactions.data.transactionsByDate.items.slice(
            duplicateCursorIndex,
            transactions.data.transactionsByDate.items.length
          )) {
            item.double = true
            item.donationToProject = item.donationToProject * 2
          }
        }
      }
      transactions.data.transactionsByDate.items =
        transactions.data.transactionsByDate.items.map((transaction) => {
          if (
            transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
          ) {
            transaction.double = false
            if (total < 20000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          if (
            transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
          ) {
            transaction.double = false
            if (total < 14000000) {
              transaction.double = true
              transaction.donationToProject = transaction.donationToProject * 2
              total += transaction.donationToProject
            }
          }
          return transaction
        })

      return transactions.data.transactionsByDate
    } catch (e) {
      console.log(e)
    }
  }

  const listTransactionsByPeerProjectId = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByPeerProjectId(
        variables
      )
      let total = 0

      if (variables.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1') {
        const duplicateCursorIndex =
          transactions.data.transactionsByPeerProjectId.items.findIndex(
            (item) => item.id === '51ac221f-384f-449e-a128-8b1b4a14e412'
          )
        if (duplicateCursorIndex !== -1) {
          for (let item of transactions.data.transactionsByPeerProjectId.items.slice(
            duplicateCursorIndex,
            transactions.data.transactionsByPeerProjectId.items.length
          )) {
            item.double = true
            item.donationToProject = item.donationToProject * 2
          }
        }
      }
      transactions.data.transactionsByPeerProjectId.items =
        transactions.data.transactionsByPeerProjectId.items.map(
          (transaction) => {
            if (
              transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
            ) {
              transaction.double = false
              if (total < 20000000) {
                transaction.double = true
                transaction.donationToProject =
                  transaction.donationToProject * 2
                total += transaction.donationToProject
              }
            }
            if (
              transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
            ) {
              transaction.double = false
              if (total < 14000000) {
                transaction.double = true
                transaction.donationToProject =
                  transaction.donationToProject * 2
                total += transaction.donationToProject
              }
            }
            return transaction
          }
        )

      return transactions.data.transactionsByPeerProjectId
    } catch (e) {
      console.log(e)
    }
  }

  const listTransactionsByTopDonation = async (variables) => {
    try {
      let transactions = await transactionRepo.listTransactionsByTopDonation(
        variables
      )
      let total = 0
      transactions.data.transactionsByTopProjectDonation.items =
        transactions.data.transactionsByTopProjectDonation.items.map(
          (transaction) => {
            if (
              transaction.projectId === '3699d58d-d9d0-45e0-aba8-f36e41401211'
            ) {
              transaction.double = false
              if (total < 20000000) {
                transaction.double = true
                transaction.donationToProject =
                  transaction.donationToProject * 2
                total += transaction.donationToProject
              }
            }
            if (
              transaction.projectId === '940391dc-f9e1-446c-a2b1-d023f7e9ddf1'
            ) {
              transaction.double = false
              if (total < 14000000) {
                transaction.double = true
                transaction.donationToProject =
                  transaction.donationToProject * 2
                total += transaction.donationToProject
              }
            }
            return transaction
          }
        )

      return transactions.data.transactionsByTopProjectDonation
    } catch (e) {
      console.log(e)
    }
  }

  const createTransaction = async (input) =>
    transactionRepo.createTransaction(input)
  const updateTransaction = async (input) =>
    transactionRepo.updateTransaction(input)

  const getTransactionsFromElastic = (input) =>
    transactionRepo.getTransactionsFromElastic(input)

  const getTransactionsTotal = async (condition) => {
    const response = await transactionRepo.getTransactionsFromElastic(condition)
    if (!response) {
      return []
    }
    const total = response.aggregations.totalAmount.value
    return total
  }
  const getTransactionsByEmailDate = async (variables) => {
    const response = await transactionRepo.getTransactionsByEmailDate(variables)
    if (!response) {
      return []
    }
    const transactions = response.data?.transactionsByEmailDate
    let items = transactions.items.map((item) => ({
      ...item,
      formattedDate: moment(item.createdAt).format('MMMM DD, YYYY'),
    }))
    transactions.items = items

    return transactions
  }

  const getReArmeniaOneTimeTransactionsByEmailDate = async (variables) => {
    let items = [],
      transactions = {}

    variables = {
      ...{
        limit: 10,
        sortDirection: 'DESC',
        filter: {
          status: { eq: 'DONE' },
          and: {
            projectId: { eq: '6d530a79-e071-49b6-95ea-51323f1d850c' },
            subscriptionId: { attributeExists: false },
          },
        },
      },
      ...variables,
    }

    const fetch = async (variables) => {
      const response = await transactionRepo.getTransactionsByEmailDate(
        variables
      )
      if (!response) {
        return items
      }
      transactions = response.data?.transactionsByEmailDate
      items = [
        ...items,
        ...transactions.items.map((item) => ({
          ...item,
          formattedDate: moment(item.createdAt).format('MMMM DD, YYYY'),
        })),
      ]
      if (items?.length < variables.limit && transactions.nextToken) {
        await fetch({ ...variables, nextToken: transactions.nextToken })
      }
      transactions.items = items
    }
    await fetch(variables)
    return transactions
  }

  const getUserInvestments = async ({
    nextToken,
    email,
    isRecurring,
    limit,
    sortDirection = 'DESC',
    projectIdList,
  }) => {
    const params = investmentParams({
      limit,
      sortDirection,
      email,
      isRecurring,
      projectIdList,
      nextToken,
    })

    const defaultProjectInvestments = await fetchItemsWhileEmpty({
      params,
      request: transactionRepo.getTransactionsByEmailDate,
    })
    console.log({ defaultProjectInvestments })

    if (
      defaultProjectInvestments.data.transactionsByEmailDate.items.length > 0
    ) {
      return normalizeInvestmentResponse(
        defaultProjectInvestments.data.transactionsByEmailDate
      )
    }

    return {
      items: [],
      nextToken: null,
    }
  }

  return {
    getTransactionsFromElastic,
    createTransaction,
    getTransaction,
    listTransactions,
    listTransactionsByGiftCardCode,
    listTransactionsByDate,
    listTransactionsByTopDonation,
    getTransactionsTotal,
    getTransactionsByEmailDate,
    getReArmeniaOneTimeTransactionsByEmailDate,
    getUserInvestments,
    updateTransaction,
    listTransactionsByPeerProjectId,
  }
}

export default transactionUseCase
