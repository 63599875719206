import React from 'react'
import { Box, Heading, Text, Flex, Button } from '@chakra-ui/react'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'

const About = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Flex
        px="4"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box maxW="832px">
          <Heading mb="8" fontSize={{ base: '3xl', sm: '2.5rem' }} as="h2">
            {t('homepage@aboutHeading')}
          </Heading>
          <Text
            mb="6"
            color="gray.700"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
          >
            {t('homepage@aboutDescription1')}
          </Text>
          <Text
            mb="6"
            color="gray.700"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
            dangerouslySetInnerHTML={{
              __html: t('homepage@aboutDescription2'),
            }}
          />
          <Text
            mb="5rem"
            color="gray.700"
            fontSize={{ base: 'xl', sm: '1.25rem' }}
          >
            {t('homepage@aboutDescription3')}
          </Text>
        </Box>
      </Flex>
      <Flex
        py="7rem"
        bg="#fafafa"
        direction="column"
        alignItems="center"
        justifyContent="center"
        margin="auto"
      >
        <Heading as="h2" fontSize={{ base: '3xl', sm: '2.5rem' }} mb="5">
          {t('homepage@contributionHeading')}
        </Heading>
        <Button
          as={Link}
          to="/fundraisers?type=oneTime"
          display={{ base: 'none', sm: 'inline-flex' }}
          w="250px"
          variant="green"
        >
          {t('homepage@contributionButtonText')}
        </Button>
      </Flex>
    </Box>
  )
}

export default About
