import { useState } from 'react'
import {
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useListGiftCardsByCode, useQueryParams } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { useGiftCard } from 'contexts/GiftCardContext'
import { useModal } from 'providers/ModalProvider'
import { useNavigation } from 'pages'
import { REDEEM_GIFT_CARD_MODAL } from 'constants/modals'
const w = window.innerWidth

export default function ApplyGiftCard({
  isGiftCardDisabled,
  getValues,
  register,
  trigger,
}) {
  const { mutate } = useListGiftCardsByCode()
  const { t, language } = useTranslation()
  const { setGiftCard, giftCardMode } = useGiftCard()
  const { openModal } = useModal()
  const { navigationPush } = useNavigation()
  const [error, setError] = useState(null)
  const params = useQueryParams()

  const onApply = () => {
    if (!getValues('giftCardCode')) {
      trigger('giftCardCode')
      return
    }
    mutate(
      { code: getValues('giftCardCode') },
      {
        onSuccess: (response) => {
          if (!response) {
            setError('orderGiftCard@notRecognized')
          }
          if (response && response.used) {
            setError('orderGiftCard@redeemed')
          }
          if (!response?.used && response?.amount) {
            const peerProjectId = params.get('peerProjectId')
            response.amountWithCurrency = Math.floor(
              Number(response.amount) / Number(response.currencyRate)
            )
            setGiftCard(response)
            if (w < 485) {
              let newUrl =
                window.location.pathname.split(language)[1] +
                `?projectId=${params.get('projectId')}`
              if (peerProjectId) {
                newUrl += `&peerProjectId=${peerProjectId}`
              }
              localStorage.setItem('fromUrl', newUrl)
              navigationPush(
                `/redeem-mobile?giftCardCode=${getValues('giftCardCode')}`
              )
            } else {
              openModal(REDEEM_GIFT_CARD_MODAL)
            }
          }
        },
      }
    )
  }

  return (
    <Flex direction="column" mt={3}>
      <Text fontSize="md" color="gray.700" mb={2}>
        {t('orderGiftCard@enterGiftCardCode')}
      </Text>
      <InputGroup h="52px" alignItems="center">
        <Input
          size="md"
          pl={3}
          py={3.5}
          pr="100px"
          fontWeight="500"
          isDisabled={isGiftCardDisabled}
          background="white"
          border="1px solid"
          borderColor="border.400"
          borderRadius={8}
          fontSize="md"
          {...register('giftCardCode', {
            required: true,
          })}
        />
        {!isGiftCardDisabled && (
          <InputRightElement
            width="auto"
            top="unset"
            mr="4"
            children={
              <Button
                variant="transparent"
                fontWeight={500}
                fontSize="lg"
                color={giftCardMode ? 'green.500' : 'gray.800'}
                onClick={onApply}
              >
                {giftCardMode ? '' : '+ '}
                {t(giftCardMode ? 'applied' : 'apply')}
              </Button>
            }
          />
        )}
      </InputGroup>
      {error && (
        <Text
          color="red.400"
          mt={5}
          fontSize="sm"
          fontWeight="semibold"
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: t(error),
          }}
        />
      )}
    </Flex>
  )
}
