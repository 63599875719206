import React from 'react'
import { UserProvider } from './contexts'
import { TranslationProvider } from './contexts/TranslationContext'
import { SettingsProvider } from './contexts/CurrencySettingsContext'
import Routes, { RouterProvider } from './pages'
import {
  QueryProvider,
  ChakraProvider,
  ModalProvider,
  // PopUpProvider,
} from './providers'
import GoogleAnalyticsWrapper from './components/GoogleAnalyticsWrapper'
import { GiftCardContextProvider } from 'contexts/GiftCardContext'
import AmplitudeProvider from 'providers/AmplitudeProvider'
import MessengerWrapper from 'components/MessengerWrapper'
import { HelmetProvider } from 'react-helmet-async'

function App() {
  return (
    <AmplitudeProvider>
      <ChakraProvider>
        <QueryProvider>
          <RouterProvider>
            <HelmetProvider>
              <GoogleAnalyticsWrapper>
                <TranslationProvider>
                  <UserProvider>
                    <SettingsProvider>
                      <GiftCardContextProvider>
                        <ModalProvider>
                          {/* <PopUpProvider> */}
                          <MessengerWrapper>
                            <Routes />
                          </MessengerWrapper>
                          {/* </PopUpProvider> */}
                        </ModalProvider>
                      </GiftCardContextProvider>
                    </SettingsProvider>
                  </UserProvider>
                </TranslationProvider>
              </GoogleAnalyticsWrapper>
            </HelmetProvider>
          </RouterProvider>
        </QueryProvider>
      </ChakraProvider>
    </AmplitudeProvider>
  )
}

export default App
