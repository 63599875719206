import * as Sentry from '@sentry/react'
import { useState, useCallback, useEffect, useMemo } from 'react'
import {
  Box,
  Checkbox,
  Text,
  Button,
  Spinner,
  VStack,
  HStack,
} from '@chakra-ui/react'
import BackButton from 'components/BackButton'
import { GridItem } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { Link, useNavigation } from 'pages'
import { motion } from 'framer-motion'
import {
  useUser,
  useQueryParams,
  useGetProject,
  usePayment,
  useCreateGiftCard,
  useCreateTransaction,
  useGetGiftCard,
  useCustomToast,
  useGetUserGeo,
  useCreateCustomer,
  useUpdateUser,
  useCreateSource,
  useObjectManipulations,
  useLocalStorage,
  useGetUserByEmailAsync,
  useAttachPmToCustomer,
  useSubscribeToProjectNewsletter,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'
import CartAmountForm from 'components/CartAmountForm'
import Cart from 'components/Cart'
import AttachedCards from './AttachedCards'
import { createTransactionBody } from './donationHelpers'
import Layout from './Layout'
import Header from './MobileDonation/Header'
import useDonationForm from './useDonationForm'
import GiftCardForm from './GiftCardForm'
import GiftCardBanner from 'components/GiftCardBanner'
import { useGiftCard } from 'contexts/GiftCardContext'
import ContributeBanner from './ContributeBanner'
import PoweredBy from './PoweredBy'
import ToRearmeniaTotal from './ToRearmeniaTotal'
import DonorList from './DonorList'
import { useRef } from 'react'
import amplitude from 'amplitude-js'
import { TRANSACTION_STATUS, TRANSACTION_STATUS_DESCRIPTION } from './constants'
import useUpdateGiftCard from 'hooks/giftCard/useUpdateGiftCard'
import ReArmeniaTip from './ReArmeniaTip'
import FbPixel from 'components/FbPixel'
import fbPixel from 'fbPixel'
import usePaymentIntent from 'hooks/stripe/usePaymentIntent'
import useConfirmPayment from 'hooks/stripe/useConfirmPayment'
import PaymentGuestUserForm from 'components/PaymentGuestUserForm'
import moment from 'moment'
import WalletPayButton from './WalletPayButton'
import ProjectHelperInfo from 'components/ProjectHelperInfo'

const w = window.innerWidth
const TIP_PERCENT_OPTIONS = ['0', '5', '7.5', '12.5']

const Donation = ({ rearmeniaProjectId }) => {
  const history = useHistory()
  const { remove, put } = useLocalStorage()
  const queryParams = useQueryParams()
  const { navigationPush } = useNavigation()
  const { user } = useUser()
  const { updateUser } = useUpdateUser()
  const { currency, settings } = useCurrencySettings()
  const { data: userGeo } = useGetUserGeo()
  const { t, language } = useTranslation()
  const projectId = queryParams.get('projectId') || rearmeniaProjectId
  const peerProjectId = queryParams.get('peerProjectId')
  const isBuyingGiftCard = projectId === 'giftCard'
  const giftCard = queryParams.get('giftCard')
  const hasPerkIndex = queryParams.has('perkIndex')
  const isContribution = projectId === 'rearmenia'
  const { isLoading: isLoadingProject, data: project } = useGetProject({
    projectId: projectId ?? '',
  })
  const { data: peerProject, isLoading: isLoadingPeerProject } = useGetProject({
    projectId: peerProjectId ?? '',
  })

  const { deleteKey } = useObjectManipulations()
  const { mutate: subscribeToProjectNewsLetter } =
    useSubscribeToProjectNewsletter()
  const { mutate: payment } = usePayment()
  const { mutateAsync: createGiftCardMutation } = useCreateGiftCard()
  const { mutateAsync: updateGiftCardMutation } = useUpdateGiftCard()
  const { mutateAsync: createTransactionMutation } = useCreateTransaction()
  const { mutateAsync: getGiftCardAsync } = useGetGiftCard()
  const { mutateAsync: getUserByEmailAsync } = useGetUserByEmailAsync()
  const { mutate: paymentIntent } = usePaymentIntent()
  const { mutate: confirmPayment } = useConfirmPayment()
  const { mutate: createCustomer, mutateAsync: createCustomerAsync } =
    useCreateCustomer()
  const { mutateAsync: attachPmToCustomer } = useAttachPmToCustomer()
  const { mutate: createSource } = useCreateSource()
  const [isLoading, toggleLoading] = useState(false)
  const [clickedOnce, setClickedOnce] = useState(false)
  const [percent, setPercent] = useState(7.5)
  const [clientSecret, setClientSecret] = useState(null)
  const [paymentMethodData, setPaymentMethod] = useState()
  const [tokenId, setTokenId] = useState(null)
  const [newStripeCard, setNewStripeCard] = useState()
  const buttonRef = useRef(null)
  const [nav, setNav] = useState('none')
  const toast = useCustomToast()
  const {
    giftCardMode: isGiftCardMode,
    giftCard: giftCardInfo,
    setGiftCardMode,
    setGiftCard,
  } = useGiftCard()

  const giftCardMode = useMemo(
    () =>
      !isContribution &&
      projectId !== 'giftCard' &&
      (!project?.isOneTimeDisabled || project?.fundraisingType === 'oneTime') &&
      isGiftCardMode,
    [isContribution, projectId, project, isGiftCardMode]
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    appliedGiftCard,
  } = useDonationForm({
    giftCard,
    giftCardMode,
    user,
    project,
    hasDefaultAmount: queryParams.has('amount'),
    defaultAmount:
      queryParams.get('amount') === 'undefined'
        ? 0
        : Number(queryParams.get('amount')),
    hasFirstName: queryParams.has('firstName'),
    firstName: queryParams.get('firstName'),
    hasLastName: queryParams.has('lastName'),
    lastName: queryParams.get('lastName'),
    hasEmail: queryParams.has('email'),
    email: queryParams.get('email'),
    projectId,
    isContribution,
    peerProjectId,
  })

  const isGiftCardDisabled =
    queryParams.has('giftCard') || watch('paymentType') !== 'oneTime'

  const redeemGiftCard = useCallback(
    async (data) => {
      toggleLoading(true)
      const dbGiftCard = await getGiftCardAsync(giftCardInfo.id)
      if (dbGiftCard.used) {
        toast({
          title: 'Sorry, gift card already used',
          status: 'warning',
        })
        setGiftCardMode(false)
        setGiftCard({})
        remove('giftCard')
        remove('giftCardMode')
        toggleLoading(false)
        return
      }
      const isStripe =
        getValues('paymentMethod') === 'stripe' ||
        getValues('paymentMethod').includes('card_')
      const transactionInput = {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          isAnonymous: data.isAnonymous,
          isInfoAnonymous: data.isInfoAnonymous,
          amount:
            (giftCardInfo.amount - watch('reArmenia')) /
            giftCardInfo.currencyRate,
          reArmenia: watch('reArmenia') / giftCardInfo.currencyRate,
          currency: giftCardInfo.currency,
          currencyRate: giftCardInfo.currencyRate,
        },
        giftCard: {
          id: giftCardInfo.id,
          amount: giftCardInfo.amount,
          image: giftCardInfo.image,
          code: giftCardInfo.code,
        },
        giftCardTransactionType: 'spend',
        status: TRANSACTION_STATUS.DONE,
        statusDescription: TRANSACTION_STATUS_DESCRIPTION.DONE,
        isStripe,
      }
      const giftCardInput = {
        id: giftCardInfo.id,
        used: true,
      }
      const title =
        peerProject?.title_en ||
        project.projectTitle_en ||
        project.applicationTitle
      try {
        await createTransaction(transactionInput)
        await updateGiftCardMutation(giftCardInput)
        setGiftCardMode(false)
        setGiftCard({})
        remove('giftCard')
        remove('giftCardMode')
        navigationPush(`/donate/thank-you?name=${
          data.firstName
        }&title=${encodeURI(title)}&id=${peerProjectId || projectId}
      `)
      } catch (error) {
        toast({ title: 'Something went wrong', status: 'warning' })
        console.log('ERRR', error)
      }
    },
    [project, giftCard, giftCardInfo]
  )

  const createTransaction = ({
    data,
    isStripe,
    creditCard,
    giftCardTransactionType = null,
    giftCard,
    status = TRANSACTION_STATUS.PENDING,
    statusDescription = TRANSACTION_STATUS_DESCRIPTION.PENDING,
  }) => {
    let currencyRate =
      currency.current === 'AMD' ? 1 : settings?.currency[currency.current]
    if (data.currencyRate) {
      currencyRate = data.currencyRate
    }
    let curr = currency.current
    if (data.currency) {
      curr = data.currency
    }
    const input = {
      userId: user.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      projectId,
      projectTitle_en: peerProject?.title_en || project?.title_en,
      projectTitle_hy: peerProject?.title_hy || project?.title_hy,
      projectTitle_ru: peerProject?.title_ru || project?.title_ru,
      projectImage: peerProject?.cover || project?.cover,
      language,
      isStripe,
      donationToReArmenia: isContribution
        ? 0
        : Number(Number(data.reArmenia * currencyRate).toFixed(2)),
      donationToProject: Number(Number(data.amount) * currencyRate).toFixed(
        curr === 'AMD' ? 0 : 2
      ),
      status,
      statusDescription,
      currency: curr,
      currencyRate,
      isManual: false,
      isAnonymous: data.isAnonymous,
      isInfoAnonymous: data.isInfoAnonymous,
    }
    if (peerProjectId) {
      input.peerProjectId = peerProjectId
    }
    if (creditCard) {
      input.creditCard = creditCard
    }
    input.amount = Number(
      Number(
        (Number(input.donationToProject) + Number(input.donationToReArmenia))
          .toString()
          .split('.')
          .slice(0, 2)
          .join('.')
      ).toFixed(2)
    )

    if (giftCardTransactionType) {
      input.giftCardId = giftCard.id
      input.giftCardTransactionType = giftCardTransactionType
      input.giftCardAmount = giftCard.amount
      input.amount = giftCard.amount
      if (giftCardTransactionType === 'buy') {
        input.projectTitle_en = 'Gift card'
        input.projectTitle_ru = 'Подарочная карта'
        input.projectTitle_hy = 'Նվեր քարտ'
        input.projectImage = giftCard.image
      } else {
        input.giftCardCode = giftCard.code
      }
    }

    return createTransactionMutation(input)
  }

  const createStripeAttachCard = useCallback(
    ({ customerId, tokenId }) => {
      return new Promise((resolve, reject) => {
        createSource(
          {
            customerId,
            tokenId,
          },
          {
            onSuccess: async (sourceResponse) => {
              if (!sourceResponse.source) {
                // TODO in all cases runs with 623 line's toast
                // toast({
                //   title: t('payment@orderStatus-general'),
                //   status: 'error',
                // })
                resolve()
              } else if (
                user.stripeAttachedCards?.find(
                  ({ last4 }) => last4 === sourceResponse.source.last4
                )
              ) {
                resolve()
              } else {
                const stripeAttachCardInput = {
                  id: sourceResponse.source.id,
                  type: sourceResponse.source.brand.toLowerCase(),
                  last4: sourceResponse.source.last4,
                }
                const updatedAttachedCards = [
                  ...(user.stripeAttachedCards || []),
                  stripeAttachCardInput,
                ]
                deleteKey(updatedAttachedCards, '__typename')
                const updateUserRes = await updateUser({
                  id: user.id,
                  stripeAttachedCards: updatedAttachedCards,
                })

                put('rearmenia_user', JSON.stringify(updateUserRes))
                resolve(sourceResponse)
              }
            },
            onError: (err) => {
              console.log(err)
              toast({ title: 'Problem with Stripe', status: 'warning' })
              toggleLoading(false)
              reject(err)
            },
          }
        )
      })
    },
    [user]
  )

  useEffect(() => {
    if (user.id === 'guest') {
      setValue('bindingId', null)
    }
  }, [user])

  const onDonate = useCallback(
    async (data) => {
      if (!giftCardMode && data.bindingId === 'giftCard') {
        delete data.bindingId
      }
      if (data.subscribeNews) {
        subscribeToProjectNewsLetter({ projectId, email: data.email })
      }

      const getFieldValue = (field) => {
        if (user.id === 'guest') {
          return data[field]
        }
        return user[field]
      }
      if (getValues('paymentMethod') === 'stripe' && !giftCardMode) {
        if (!tokenId) {
          setError('stripe')
          return
        }
      }
      const email = getFieldValue('email').trim()
      const isStripePayment =
        ['stripe', 'GOOGLE_PAY', 'APPLE_PAY'].includes(
          getValues('paymentMethod')
        ) || getValues('paymentMethod').includes('card_')
      const isAttachingStripeCard =
        getValues('paymentMethod') === 'stripe' &&
        getValues('attachCard') &&
        user.id !== 'guest'
      // save in local storage for later use if donation fails
      const cacheDonationFormData = { ...data, projectId }
      if (peerProjectId) {
        cacheDonationFormData.peerProjectId = peerProjectId
      }
      if (peerProject?.id) {
        cacheDonationFormData.peerProject = peerProject
      }
      put('donate-form', JSON.stringify(cacheDonationFormData))

      if (giftCardMode) {
        await redeemGiftCard(data)
        return
      }
      toggleLoading(true)
      let userByEmail
      if (user.id === 'guest' && getFieldValue('email').trim()) {
        userByEmail = await getUserByEmailAsync(getFieldValue('email').trim())
      }
      const currencyRate =
        currency.current === 'AMD' ? 1 : settings?.currency[currency.current]

      const transactionInput = {
        data,
        isStripe: !!isStripePayment,
      }
      if (isStripePayment) {
        if (getValues('paymentMethod') === 'stripe' && newStripeCard) {
          transactionInput.creditCard = {
            pan: `**** ${newStripeCard.last4}`,
            type: newStripeCard.brand
              .toLowerCase()
              .replace('mastercard', 'master'),
          }
        }
        if (
          getValues('paymentMethod').includes('card_') &&
          user.stripeAttachedCards
        ) {
          const card = user.stripeAttachedCards.find(
            ({ id }) => id === getValues('paymentMethod')
          )
          if (card) {
            transactionInput.creditCard = {
              pan: `**** ${card.last4}`,
              type: card.type,
            }
          }
        }
        if (paymentMethodData?.id && transactionInput?.creditCard) {
          transactionInput.creditCard.stripePaymentMethod = paymentMethodData.id
        }
      }
      if (isBuyingGiftCard) {
        const createdGiftCard = await createGiftCard({
          message: data.yourMessage,
          from: data.from,
          fromEmail: user.email,
          language,
          toEmail: data.toEmail,
          image: giftCard,
          currency: currency.current,
          currencyRate,
          amount: Number(Number(data.amount) * currencyRate).toFixed(
            currency.current === 'AMD' ? 0 : 2
          ),
        })
        transactionInput.giftCard = createdGiftCard
        transactionInput.giftCardTransactionType = 'buy'
        transactionInput.projectId = 'giftCard'
      }
      const transaction = await createTransaction(transactionInput)
      const { body, compareResult } = createTransactionBody({
        isBuyingGiftCard: giftCard,
        project,
        peerProject,
        user,
        formAmount: getValues('amount'),
        currency,
        currencyRate,
        email,
        firstName: getFieldValue('firstName'),
        lastName: getFieldValue('lastName'),
        language,
        data,
        paymentType: getValues('paymentType'),
        paymentMethod: getValues('paymentMethod'),
        appliedGiftCard,
        isContribution,
        transactionId: transaction.data.createTransaction.id,
        peerProjectId,
      })

      //? for tracking donate button analytics from here
      if (clickedOnce) {
        const infoForSentry = {
          userAgent: window.navigator.userAgent,
          browser: getBrowserType(),
          email,
          giftCardMode,
          amount: getValues('amount'),
          isBuyingGiftCard: giftCard,
          project_id: project?.id,
          user_id: user?.id,
          currency: JSON.stringify(currency),
          currencyRate,
          firstName: getFieldValue('firstName'),
          lastName: getFieldValue('lastName'),
          language,
          data: JSON.stringify(data),
          paymentType: getValues('paymentType'),
          paymentMethod: getValues('paymentMethod'),
          appliedGiftCard: JSON.stringify(appliedGiftCard),
          isContribution,
          transactionId: transaction.data.createTransaction.id,
          isMobile: w < 481,
          body: JSON.stringify(body),
          userGeo: JSON.stringify(userGeo),
          userInfo: JSON.stringify(getUserInfo()),
        }

        Sentry.withScope((scope) => {
          scope.setExtra('Extra Info', infoForSentry)
          Sentry.captureMessage('Second click on donate button', 'error')
        })
      } else {
        setClickedOnce(true)
      }
      //? for tracking donate button analytics until here

      if (isStripePayment) {
        let stripeId = project?.stripeId
        if (isBuyingGiftCard || isContribution) {
          stripeId = process.env.REACT_APP_STRIPE_ACCT
        }

        const paymentIntentInput = {
          amount: Number(data.amount),
          applicationFeeAmount: Number(data.reArmenia),
          currency: currency.current,
          connectedAccountId: stripeId,
          metadata: {
            projectId: project?.id,
            peerProjectId: peerProjectId,
            projectTitle_en: project?.title_en,
            transactionId: transaction.data.createTransaction.id,
            userId: user.id,
          },
        }

        if (user.customerId || userByEmail?.customerId) {
          paymentIntentInput.customerId =
            user.customerId || userByEmail?.customerId
        }
        if (user.id === 'guest' && paymentMethodData?.id) {
          //? Case #1 No user at all (create a new customer with payment_method)
          //? Case #2 User with an existing customerId (attach payment_method to that customer)
          //? Case #3 User without a customerId (create a new customer with payment_method + update user with that customerId)
          if (userByEmail?.customerId) {
            await attachPmToCustomer({
              customerId: userByEmail?.customerId,
              paymentMethod: paymentMethodData.id,
            })
          } else {
            let customerRes
            try {
              customerRes = await createCustomerAsync({
                email: getFieldValue('email'),
                name: `${getFieldValue('firstName')} ${getFieldValue(
                  'lastName'
                )}`,
                payment_method: paymentMethodData.id,
              })

              if (userByEmail) {
                const input = {
                  id: userByEmail.id,
                  customerId: customerRes.customer.id,
                }
                updateUser(input, {
                  onSuccess: (newUser) => {
                    put(
                      'rearmenia_user',
                      JSON.stringify(newUser.data.updateUser)
                    )
                  },
                  onError: (error) => {
                    console.log('UPDATE USER ERROR', error)
                  },
                })
              }
              paymentIntentInput.customerId = customerRes.customer.id
            } catch (err) {
              console.log('CREATE CUSTOMER ERROR', err)
            }
          }
        }

        let cardId
        if (isAttachingStripeCard) {
          const attachCardResponse = await createStripeAttachCard({
            customerId: user.customerId,
            tokenId,
          })
          cardId = attachCardResponse?.source?.id
        }
        if (getValues('paymentMethod').includes('card_')) {
          cardId = getValues('paymentMethod')
        }
        const foundCard = user.stripeAttachedCards?.find(
          (card) => card.id === cardId
        )
        paymentIntentInput.cardId = cardId
        paymentIntentInput.metadata.cardId = cardId
        paymentIntentInput.metadata.attachCard = isAttachingStripeCard
        paymentIntentInput.metadata.paymentType = paymentMethodData
          ? paymentMethodData?.card?.brand
          : foundCard?.type
        paymentIntentInput.metadata.last4 = paymentMethodData
          ? paymentMethodData?.card?.last4
          : foundCard?.last4
        paymentIntentInput.metadata.donationDate = moment(
          transaction.createdAt
        ).format('MMM D, YYYY')
        paymentIntentInput.metadata.donationDate_en = moment(
          transaction.createdAt
        )
          .locale('en')
          .format('MMM D, YYYY')
        body.transaction = transaction.data?.createTransaction
        if (paymentMethodData?.id) {
          paymentIntentInput.payment_method = paymentMethodData.id
        }
        body.stripeData = paymentIntentInput

        paymentIntent(paymentIntentInput, {
          onSuccess: async (data) => {
            if (data?.client_secret) {
              setClientSecret(data.client_secret)
              if (data.id) {
                const projectTitle_en =
                  peerProject?.title_en || project?.title_en
                confirmPayment(
                  {
                    paymentIntentId: data.id,
                    payment_method: paymentMethodData?.id || cardId,
                    giftCardId:
                      isBuyingGiftCard &&
                      transaction.data.createTransaction?.giftCardId,
                    transactionId: transaction.data.createTransaction.id,
                    email: transaction.data.createTransaction.email,
                    name: transaction.data.createTransaction.firstName,
                    donationAmount: transaction.data.createTransaction.amount,
                    donationToProject:
                      transaction.data.createTransaction.donationToProject,
                    donationToReArmenia:
                      transaction.data.createTransaction.donationToReArmenia,
                    currencyRate:
                      transaction.data.createTransaction.currencyRate,
                    currency: transaction.data.createTransaction.currency,
                    projectName: projectTitle_en,
                    projectName_en: projectTitle_en,
                    projectId: peerProjectId || projectId,
                    receiptId: transaction.data.createTransaction.id,
                    donationDate: moment(transaction.createdAt).format(
                      'MMM D, YYYY'
                    ),
                    donationDate_en: moment(transaction.createdAt)
                      .locale('en')
                      .format('MMM D, YYYY'),
                    transactionLanguage:
                      transaction.data.createTransaction.language,
                    paymentType: paymentMethodData
                      ? paymentMethodData.card.brand
                      : foundCard.type,
                    last4: paymentMethodData
                      ? paymentMethodData.card.last4
                      : foundCard.last4,
                    isStripe: isStripePayment,
                    isOneTime: getValues('paymentType') === 'oneTime',
                  },
                  {
                    onSuccess: async ({ status, message, success }) => {
                      if (success) {
                        if (getValues('paymentType') === 'recurring') {
                          await payment(
                            { path: '/createDonationSubscription', body },
                            {
                              onSuccess: async ({ id }) => {
                                toggleLoading(false)
                                navigationPush(
                                  `/check-transaction-stripe?transactionId=${transaction.data.createTransaction.id}&clientSecret=${data.client_secret}&subscriptionId=${id}`
                                )
                              },
                              onError: (err) => {
                                toggleLoading(false)
                                console.log(err)
                                toast({
                                  title: 'Problem with bank',
                                  status: 'warning',
                                })
                                toggleLoading(false)
                              },
                            }
                          )
                        } else {
                          navigationPush(
                            `/check-transaction-stripe?transactionId=${transaction.data.createTransaction.id}&clientSecret=${data.client_secret}`
                          )
                        }
                      } else {
                        toggleLoading(false)
                        toast({
                          title: message || t('payment@orderStatus-general'),
                          status: 'error',
                        })
                      }
                    },
                    onError: (err) => {
                      console.log('❌', err)
                    },
                  }
                )
              }
            } else {
              if (data?.errorMessage) {
                console.log('❌', data)
                if (data.code === 'amount_too_small') {
                  toast({
                    title: t('toaster@atLeast50cents'),
                    status: 'error',
                  })
                } else {
                  toast({
                    title: data?.errorMessage,
                    status: 'error',
                  })
                }
                toggleLoading(false)
              }
            }
          },
          onError: (err) => {
            console.log('❌', err)
            toggleLoading(false)
          },
        })
        return
      }

      if (compareResult.makePayment) {
        const path = body.localBindingId ? '/bind' : '/payment'
        await payment(
          { path, body },
          {
            onSuccess: async (result) => {
              setTimeout(() => {
                //? @INFO setTimeout is for loading spinner not to blink before redirecting
                toggleLoading(false)
              }, 800)
              if (result.formUrl) {
                if (path === '/payment') {
                  if (result.formUrl) {
                    put('paymentMethod', getValues('paymentMethod'))
                    window.location = result.formUrl.replace('_binding', '')
                    return
                  }
                }
              }
              navigationPush(
                `/check-transaction?orderId=${
                  result.orderId
                }&method=${getValues('paymentMethod')}`
              )
            },
            onError: (e) => {
              toggleLoading(false)
              toast({
                title: e?.response?.data?.message || 'Problem with bank',
                status: 'warning',
              })
            },
          }
        )
      }
    },
    [
      project,
      peerProject,
      user,
      currency.current,
      giftCardMode,
      clickedOnce,
      tokenId,
      paymentMethodData,
    ]
  )

  const createGiftCard = useCallback(
    ({
      message,
      from,
      fromEmail,
      language,
      toEmail,
      image,
      amount,
      currency,
      currencyRate,
    }) => {
      const giftCardInput = {
        userId: user.id,
        amount,
        image,
        currency,
        currencyRate,
        paymentStatus: 'pending',
        used: false,
        message,
        from,
        fromEmail,
        language,
        toEmail,
      }
      return createGiftCardMutation(giftCardInput)
    },
    [user]
  )

  const onScroll = () => {
    // working for donate to rearmenia page (contribution)
    if (!buttonRef.current) {
      return
    }
    const current = window.scrollY
    const { y } = buttonRef.current
    if (current > y + 20) {
      setNav('block')
    }
    if (current < y) {
      setNav('none')
    }
  }

  const handleGiftCardAmount = (e) => {
    setPercent(Number(e))
    setValue('reArmenia', (Number(giftCardInfo.amount) * Number(e)) / 100)
  }
  useEffect(() => {
    if (!user || !project || user.customerId || user.id === 'guest') {
      return
    }
    if (project?.stripeId && project?.isStripeOnBoardingDone) {
      createCustomer(
        {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        },
        {
          onSuccess: (res) => {
            const input = {
              id: user.id,
              customerId: res.customer.id,
            }
            updateUser(input, {
              onSuccess: (newUser) => {
                put('rearmenia_user', JSON.stringify(newUser.data.updateUser))
              },
              onError: (error) => {
                console.log('UPDATE USER ERROR', error)
              },
            })
          },
          onError: (error) => {
            console.log('CREATE CUSTOMER ERROR', error)
          },
        }
      )
    }
  }, [user, project])

  useEffect(() => {
    if (giftCardInfo && giftCardInfo.amount && giftCardMode) {
      setValue(
        'reArmenia',
        (Number(giftCardInfo.amount) * Number(percent)) / 100
      )
    }
  }, [giftCardInfo, giftCardMode])

  useMemo(() => {
    if (percent && !isContribution) {
      setValue('reArmenia', (watch('amount') * percent) / 100)
    }
  }, [watch('amount')])

  useEffect(() => {
    if (project?.fbPixel) {
      fbPixel.pageView()
      fbPixel.track('Donation')
    }
  }, [project?.fbPixel])

  useEffect(() => {
    // add scroll event for donate to rearmenia page (contribution)
    if (!isContribution) {
      return
    }
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [onScroll])

  useEffect(() => {
    amplitude.getInstance().logEvent('Web Donation page view')
    if (isBuyingGiftCard) {
      setValue('paymentType', 'oneTime')
      setValue('attachCard', false)
    }
  }, [])

  if (isLoadingProject || isLoadingPeerProject) {
    return (
      <Layout isLoading={isLoadingProject}>
        <Spinner color="blue.700" />
      </Layout>
    )
  }

  return (
    <>
      {project?.fbPixel && <FbPixel pixelId={project?.fbPixel} />}
      {w <= 481 && (
        <>
          <Header
            isBuyingGiftCard={isBuyingGiftCard}
            title={
              isContribution
                ? t('banner@Heading')
                : isBuyingGiftCard
                ? t('Gift card')
                : t('payment@donate')
            }
            goBack={() => history.goBack()}
            project={peerProject?.id ? peerProject : project}
            language={language}
            t={t}
            reArmenia={watch('reArmenia')}
            giftCardMode={giftCardMode}
            giftCardInfo={giftCardInfo}
            giftCardAmount={giftCardInfo.amountWithCurrency}
            giftCardCurrency={giftCardInfo.currency}
            GiftCardBanner={GiftCardBanner}
            isContribution={isContribution}
            hasPerkIndex={hasPerkIndex}
          />
        </>
      )}
      <Layout onFormSubmit={handleSubmit(onDonate)}>
        {w > 481 && <GridItem colSpan={1} />}
        <GridItem colSpan={{ base: 12, sm: 6 }}>
          {w > 481 && (
            <BackButton
              to={
                projectId === 'giftCard'
                  ? '/select-gift-card'
                  : isContribution
                  ? '/'
                  : `/fundraisers/${peerProjectId || projectId}`
              }
            />
          )}
          {giftCardMode && (
            <>
              {w > 481 && (
                <GiftCardBanner
                  giftCardAmount={giftCardInfo.amountWithCurrency}
                  giftCardCurrency={giftCardInfo.currency}
                  border
                />
              )}
              <ReArmeniaTip
                price={giftCardInfo.amount / giftCardInfo.currencyRate}
                options={TIP_PERCENT_OPTIONS}
                onChange={handleGiftCardAmount}
                currency={giftCardInfo.currency}
                t={t}
              />

              {user.id === 'guest' && (
                <PaymentGuestUserForm
                  register={register}
                  errors={errors}
                  title={t('donation@addPersonalInfo')}
                  firstName={t('donation@firstName')}
                  lastName={t('donation@lastName')}
                  email={t('donation@email')}
                  emailErrorMessage={t(errors?.email?.message)}
                />
              )}
            </>
          )}
          {isContribution && <ContributeBanner />}
          <Box w="full" mb="12">
            {!giftCardMode && (
              <>
                <CartAmountForm
                  isBuyingGiftCard={isBuyingGiftCard}
                  project={peerProject?.id ? peerProject : project}
                  giftCardMode={giftCardMode}
                  isContribution={isContribution}
                  donation={{
                    amount: watch('amount'),
                    reArmenia: watch('reArmenia'),
                  }}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  setPercent={setPercent}
                  note={isContribution && t('donation@donateToRearmeniaNote')}
                />

                <AttachedCards
                  watch={watch}
                  setPaymentMethod={setPaymentMethod}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  trigger={trigger}
                  onChange={(bindingId) => {
                    if (bindingId) {
                      setValue('attachCard', false)
                    }
                    setValue('bindingId', bindingId)
                  }}
                  isRecurring={
                    getValues('paymentType') === 'recurring' &&
                    !isBuyingGiftCard
                  }
                  isContribution={isContribution}
                  showGiftCard={
                    !(
                      queryParams.has('giftCard') ||
                      watch('paymentType') !== 'oneTime'
                    ) &&
                    !giftCardMode &&
                    !isContribution
                  }
                  register={register}
                  errors={errors}
                  isGiftCardDisabled={isGiftCardDisabled}
                  donationPage
                  clientSecret={clientSecret}
                  showStripe={
                    (project?.stripeId && project?.isStripeOnBoardingDone) ||
                    isBuyingGiftCard ||
                    isContribution
                  }
                  setTokenId={setTokenId}
                  setNewStripeCard={setNewStripeCard}
                />
              </>
            )}
            {!giftCard && (
              <Checkbox display="flex" mt={4} {...register('isAnonymous')}>
                <Text fontSize="sm" lineHeight="16px">
                  {giftCardMode ? (
                    t('redeemAnonymous')
                  ) : (
                    <>
                      <b>
                        {t('donation@donateAnonymouslyText').split('(bold)')[0]}
                      </b>
                      {t('donation@donateAnonymouslyText').split('(bold)')[1]}
                    </>
                  )}
                </Text>
              </Checkbox>
            )}
            {!isBuyingGiftCard && (
              <Checkbox display="flex" mt={5} {...register('isInfoAnonymous')}>
                <Text fontSize="sm" lineHeight="16px">
                  <b>
                    {
                      t("Don't share my email with the project creator").split(
                        '(bold)'
                      )[0]
                    }
                  </b>
                  {
                    t("Don't share my email with the project creator").split(
                      '(bold)'
                    )[1]
                  }
                </Text>
              </Checkbox>
            )}

            <Box
              borderBottomWidth={isBuyingGiftCard ? 0 : 1}
              borderColor="border.100"
              pb={8}
            >
              <Checkbox
                borderColor="border.100"
                mt={4}
                {...register('subscribeNews')}
              >
                <Text fontSize="sm" lineHeight="16px">
                  {t('donation@subscribeOnDonateText').split('(bold)')[0]}
                  <b>{t('donation@subscribeOnDonateText@bold')}</b>
                  {t('donation@subscribeOnDonateText').split('(bold)')[1]}
                </Text>
              </Checkbox>
            </Box>

            {giftCard && (
              <GiftCardForm
                user={user}
                register={register}
                errors={errors}
                mt={6}
              />
            )}

            {w < 481 && !giftCardMode && (
              <>
                {isContribution ? (
                  <ToRearmeniaTotal
                    amount={watch('amount')}
                    currency={currency.current}
                    mt={6}
                  />
                ) : (
                  <Cart
                    project={peerProject?.id ? peerProject : project}
                    isContribution={isContribution}
                    donation={{
                      amount: watch('amount'),
                      reArmenia:
                        watch('reArmenia') / (giftCardInfo.currencyRate || 1),
                    }}
                    isGiftCardDisabled={isGiftCardDisabled}
                    isBuyingGiftCard={isBuyingGiftCard}
                    giftCardMode={giftCardMode}
                  />
                )}
              </>
            )}
            {getValues('paymentType') === 'recurring' && (
              <ProjectHelperInfo
                title={
                  project?.isOneTimeDisabled
                    ? t('Monthly Donations Only')
                    : t('Monthly Donations')
                }
                description={t(
                  'Donations will be processed on the 1st of each month until you cancel the subscription.'
                )}
              />
            )}
            <Box mt="8">
              {!giftCard && (
                <>
                  {giftCardMode ? (
                    <>
                      <Text
                        textAlign="left"
                        fontSize="sm"
                        color="gray.700"
                        fontWeight="400"
                        mb="4"
                      >
                        {t('giftCardRedeemSubscribe')}
                      </Text>
                    </>
                  ) : (
                    <Text
                      textAlign="left"
                      fontSize="sm"
                      color="gray.700"
                      fontWeight="400"
                      mb="4"
                    >
                      {t('donation@terms')}{' '}
                      <Link target="_blank" to="/terms-and-conditions">
                        <Box color="gray.800" as="span" fontWeight="500">
                          {t('donation@termsUse')}
                        </Box>
                      </Link>{' '}
                      {t('donation@privacy')}
                      <Link target="_blank" to="/privacy-policy">
                        <Box color="gray.800" as="span" fontWeight="500">
                          {' '}
                          {t('donation@policy')}.
                        </Box>
                      </Link>
                    </Text>
                  )}
                </>
              )}
              {watch('paymentMethod') === 'APPLE_PAY' ||
              watch('paymentMethod') === 'GOOGLE_PAY' ? (
                <WalletPayButton
                  project={project}
                  peerProject={peerProject}
                  getValues={getValues}
                  user={user}
                  trigger={trigger}
                  setError={setError}
                  watch={watch}
                  clearErrors={clearErrors}
                  amount={Number(watch('amount')) + Number(watch('reArmenia'))}
                  currency={currency.current}
                  applicationFeeAmount={Number(watch('reArmenia'))}
                  paymentMethod={watch('paymentMethod')}
                />
              ) : (
                <Button
                  mt="2"
                  variant="green"
                  w="full"
                  type="submit"
                  ref={(el) => {
                    if (!el) return
                    const rect = el.getBoundingClientRect()
                    const parentRect = el.offsetParent?.getBoundingClientRect()
                    buttonRef.current = {
                      bottom: parentRect?.bottom - rect.bottom,
                      height: rect.height,
                      left: rect.left - parentRect?.left,
                      right: parentRect?.right - rect.right,
                      top: rect.top - parentRect?.top,
                      y: rect.top - parentRect?.top,
                      width: rect.width,
                    }
                  }}
                  isDisabled={
                    watch('paymentMethod') === 'giftCard' && !giftCardMode
                  }
                  borderRadius="12px"
                  isLoading={isLoading}
                >
                  {giftCardMode
                    ? t('redeem')
                    : queryParams.has('giftCard')
                    ? t('order')
                    : t('donation@donate')}
                </Button>
              )}
              {w < 481 &&
                isContribution &&
                watch('paymentMethod') !== 'APPLE_PAY' && (
                  <motion.div
                    initial={{
                      top: '100%',
                      left: '0',
                      position: 'fixed',
                      zIndex: '50',
                      width: '100%',
                    }}
                    animate={{ top: nav === 'block' ? '91%' : '100%' }}
                    transition={{ duration: 0.5, stiffness: 1000 }}
                  >
                    <HStack
                      zIndex="1000"
                      borderTop="1px solid"
                      borderColor="border.100"
                      justifyContent="space-between"
                      py="3"
                      px="3"
                      spacing="3"
                      background="white"
                    >
                      <Button
                        variant="green"
                        w="full"
                        type="submit"
                        borderRadius="12px"
                        isLoading={isLoading}
                      >
                        {t('donation@donate')}
                      </Button>
                    </HStack>
                  </motion.div>
                )}
              {w < 481 &&
                isContribution &&
                watch('paymentMethod') !== 'APPLE_PAY' && (
                  <motion.div
                    initial={{
                      top: '100%',
                      left: '0',
                      position: 'fixed',
                      zIndex: '50',
                      width: '100%',
                    }}
                    animate={{ top: nav === 'block' ? '91%' : '100%' }}
                    transition={{ duration: 0.5, stiffness: 1000 }}
                  >
                    <HStack
                      zIndex="1000"
                      borderTop="1px solid"
                      borderColor="border.100"
                      justifyContent="space-between"
                      py="3"
                      px="3"
                      spacing="3"
                      background="white"
                    >
                      <Button
                        variant="green"
                        w="full"
                        type="submit"
                        borderRadius="12px"
                        isLoading={isLoading}
                      >
                        {t('donation@donate')}
                      </Button>
                    </HStack>
                  </motion.div>
                )}
              <PoweredBy
                text={t('Powered By')}
                project={project}
                mt={8}
                showStripe={
                  (project?.stripeId && project?.isStripeOnBoardingDone) ||
                  isBuyingGiftCard ||
                  isContribution
                }
                pb={(w < 481) & !isContribution ? 12 : 0}
              />
              {isContribution && project?.id && w < 481 && (
                <DonorList
                  project={project}
                  mt={6}
                  pt={12}
                  pb={28}
                  borderTopWidth="1px"
                  borderColor="border.100"
                />
              )}
            </Box>
          </Box>
        </GridItem>
        {w > 481 && (
          <>
            <GridItem colSpan={1} />{' '}
            <GridItem colSpan={{ base: 6, sm: 4 }}>
              {isContribution ? (
                <VStack>
                  <ToRearmeniaTotal
                    currency={currency.current}
                    amount={watch('amount')}
                    mb={6}
                  />
                  {project?.id && <DonorList project={project} w="full" />}
                </VStack>
              ) : (
                <Cart
                  project={peerProject?.id ? peerProject : project}
                  isContribution={isContribution}
                  donation={{
                    amount: watch('amount'),
                    reArmenia:
                      watch('reArmenia') / (giftCardInfo.currencyRate || 1),
                  }}
                  isGiftCardDisabled={isGiftCardDisabled}
                  isBuyingGiftCard={isBuyingGiftCard}
                  giftCardMode={giftCardMode}
                />
              )}
            </GridItem>
          </>
        )}
      </Layout>
    </>
  )
}

export default Donation

function getBrowserType() {
  const test = (regexp) => {
    return regexp.test(navigator.userAgent)
  }

  if (test(/opr\//i) || !!window.opr) {
    return 'Opera'
  } else if (test(/edg/i)) {
    return 'Microsoft Edge'
  } else if (test(/chrome|chromium|crios/i)) {
    return 'Google Chrome'
  } else if (test(/firefox|fxios/i)) {
    return 'Mozilla Firefox'
  } else if (test(/safari/i)) {
    return 'Apple Safari'
  } else if (test(/trident/i)) {
    return 'Microsoft Internet Explorer'
  } else if (test(/ucbrowser/i)) {
    return 'UC Browser'
  } else if (test(/samsungbrowser/i)) {
    return 'Samsung Browser'
  } else {
    return 'Unknown browser'
  }
}

function getUserInfo() {
  const userInfo = {}
  userInfo.userAgent = navigator.userAgent

  // Geolocation
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      userInfo.latitude = position.coords.latitude
      userInfo.longitude = position.coords.longitude
    })
  }

  // Cookies
  userInfo.cookiesEnabled = navigator.cookieEnabled

  // Local Storage and Session Storage
  // userInfo.localStorage = window.localStorage //TODO Too large for sentry
  userInfo.sessionStorage = window.sessionStorage

  // Screen Resolution
  userInfo.screenResolution = `${window.screen.width}x${window.screen.height}`

  // Time Zone
  userInfo.timeZone = new Date().getTimezoneOffset()

  // Web Storage
  // userInfo.localStorageData = localStorage //TODO Too large for sentry
  userInfo.sessionStorageData = sessionStorage

  // Referrer Information
  userInfo.referrer = document.referrer

  return userInfo
}
