import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/hy-am'
import { I18n } from 'aws-amplify/utils'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { configure as configureI18n } from '../translations'
import { useLocalStorage } from '../hooks'
import { Box } from '@chakra-ui/react'
import enTranslations from '../translations/messages/en-us.json'
import ruTranslations from '../translations/messages/ru-ru.json'
import hyTranslations from '../translations/messages/hy-am.json'

configureI18n()

const LOCAL_STORAGE_KEY = `${process.env.REACT_APP_ENV}_lang`
const DEFAULT_LANG = ['en', 'en-US']
export const MOMENT_LANGUAGES = { en: 'en', ru: 'ru', hy: 'hy-am' }

export const LANGUAGES = {
  en: {
    code: 'en-US',
    currency: 'USD',
    currencySymbol: '$',
  },
  ru: {
    code: 'ru-RU',
    currency: 'RUB',
    currencySymbol: '₽',
  },
  hy: {
    code: 'hy-AM',
    currency: 'AMD',
    currencySymbol: '$',
  },
}

const getLangFromURL = (location, localStorageLang) => {
  const lang = location.pathname.split('/')[1]
  if (lang !== 'en' && lang !== 'ru' && lang !== 'hy') {
    if (!localStorageLang) {
      return ['en', LANGUAGES.en.code]
    }
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  }
  if (localStorageLang && localStorageLang === lang) {
    return [localStorageLang, LANGUAGES[localStorageLang].code]
  } else {
    return [lang, LANGUAGES[lang].code]
  }
}

export const TranslationContext = createContext({
  language: '',
  setLanguage: () => {},
  t: () => {},
  tt: () => {},
})

export const useTranslation = () => useContext(TranslationContext)

export const validateStringLang = (text, lang) => {
  text = text.replaceAll('&amp;', '&').replaceAll('&nbsp;', ' ')
  // eslint-disable-next-line no-useless-escape
  const cyrillicPattern =
    /^[\p{Script=Cyrl}\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/u
  const armenianPattern =
    /^[\u0531-\u0556\u0561-\u0587\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/
  const latinPattern =
    /^[a-zA-Z\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/

  switch (lang) {
    case 'ru':
      return cyrillicPattern.test(text)

    case 'hy':
      return armenianPattern.test(text)
    case 'en':
      return latinPattern.test(text)

    default:
      break
  }
}

export const validateStringLangPatterns = (lang) => {
  const cyrillicPattern =
    /^[\p{Script=Cyrl}\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/u
  const armenianPattern =
    /^[\u0531-\u0556\u0561-\u0587\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/
  const latinPattern =
    /^[a-zA-Z\s\d.,/#!$%'’՝<>?|+^&*;:{}=\-_`~()[\]\\|/՛«»՞"@՜]*$/
  switch (lang) {
    case 'ru':
      return cyrillicPattern

    case 'hy':
      return armenianPattern
    case 'en':
      return latinPattern
    default:
      break
  }
}

export const LANGUAGES_SHORT = ['en', 'hy', 'ru']

export const TranslationProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { put, get } = useLocalStorage()
  const lang = getLangFromURL(location, get(LOCAL_STORAGE_KEY))
  const [appLanguage, setAppLanguage] = useState(lang || DEFAULT_LANG)

  const translate = useCallback((...args) => I18n.get(...args), [])

  const translateTo = (key, languageArg) => {
    switch (languageArg) {
      case 'ru':
        return ruTranslations[key]
      case 'hy':
        return hyTranslations[key]
      default:
        return enTranslations[key]
    }
  }

  const retrieveLanguage = useCallback(() => {
    const localStorageLang = get(LOCAL_STORAGE_KEY)
    if (lang) {
      I18n.setLanguage(lang[1])
    }
    if (!lang) {
      return history.push(
        `/${localStorageLang}${
          location.pathname === '/'
            ? window.location.search
            : location.pathname + window.location.search
        }`
      )
    }
  }, [appLanguage, lang])

  const setLanguage = useCallback(
    (oldLanguage, newLanguage) => {
      if (newLanguage && ['en', 'ru', 'hy'].includes(newLanguage)) {
        const I18Value = LANGUAGES[newLanguage].code
        const redirectURL =
          window.location.pathname.replace(oldLanguage, newLanguage) +
          window.location.search

        I18n.setLanguage(I18Value)
        moment.locale(MOMENT_LANGUAGES[newLanguage])
        setAppLanguage([newLanguage, I18Value])
        history.push(redirectURL)
        put(LOCAL_STORAGE_KEY, newLanguage)
      }
    },
    [setAppLanguage]
  )

  useEffect(() => {
    if (!lang) return

    I18n.setLanguage(lang[1])
    if (!get(LOCAL_STORAGE_KEY)) {
      put(LOCAL_STORAGE_KEY, lang[0])
    } else if (get(LOCAL_STORAGE_KEY) !== lang[0]) {
      put(LOCAL_STORAGE_KEY, lang[0])
    }
  }, [lang])

  useEffect(() => {
    const localStorageLang = get(LOCAL_STORAGE_KEY)

    let key = 'US'
    if (localStorageLang === 'ru' || localStorageLang === 'hy') {
      key = localStorageLang.toUpperCase()
    }
    const i18Key = `${localStorageLang}-${key}`

    setAppLanguage([localStorageLang, i18Key])
    moment.locale(MOMENT_LANGUAGES[localStorageLang])
  }, [localStorage])

  useEffect(() => {
    if (!LANGUAGES_SHORT.includes(location.pathname.split('/')[1].trim())) {
      const localStorageLang = get(LOCAL_STORAGE_KEY)
      history.push(
        `/${localStorageLang}${
          location.pathname === '/'
            ? window.location.search
            : location.pathname + window.location.search
        }`
      )
    }
  }, [location])

  if (location.pathname === '/') {
    return <Redirect to={`/${lang[0] || 'en'}${window.location.search}`} />
  }

  return (
    <TranslationContext.Provider
      value={{
        setLanguage,
        language: 'en',
        retrieveLanguage,
        t: translate,
        tt: translateTo,
      }}
    >
      <Box>{children}</Box>
    </TranslationContext.Provider>
  )
}
