import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  Spinner,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import React, { useState, useEffect } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { useListGiftCardsByCode, useQueryParams } from 'hooks'
import { Link, useNavigation } from 'pages'
import { useGiftCard } from 'contexts/GiftCardContext'

export default function RedeemModalMobile() {
  const { t } = useTranslation()

  const params = useQueryParams()
  const giftCardCode = params.get('giftCardCode')
  const { mutate: getGiftCard, data, isLoading } = useListGiftCardsByCode()
  const { setGiftCardMode, setGiftCard } = useGiftCard()
  const { navigationPush } = useNavigation()
  const [redirect, setRedirect] = useState('/fundraisers')
  const onGiftCardUse = () => {
    if (data && !data.used) {
      const card = data
      card.amountWithCurrency = Math.floor(
        Number(card.amount) / Number(card.currencyRate)
      )
      setGiftCardMode(true)
      setGiftCard(card)
    }

    navigationPush(redirect)
  }

  useEffect(() => {
    // TODO: remove this when languages will be ready
    // setLanguage(language, 'hy')
    const redirectURL = localStorage.getItem('fromUrl')
    localStorage.removeItem('fromUrl')
    if (redirectURL) {
      const url = redirectURL.includes('&amount')
        ? redirectURL.split('&amount')[0]
        : redirectURL
      setRedirect(url)
    }
    if (giftCardCode) {
      getGiftCard(
        { code: giftCardCode },
        {
          onError: () => {
            navigationPush('/')
          },
        }
      )
    }
  }, [])
  console.log(data)
  return (
    <Box mx={5} mt={5}>
      <Link to={redirect}>
        <CloseIcon width="14px" fill="black" />
      </Link>
      <Image
        src={
          isLoading || (data && !data?.used)
            ? '/assets/images/dialog_mobile.jpg'
            : '/assets/images/alreadyRedeemed.jpg'
        }
        h="180px"
        mt={8}
        mb={24}
        mx="auto"
        objectFit="cover"
        borderRadius={12}
      />

      <Flex flexDir="column" alignItems="center">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Heading fontSize="2xl" textAlign="center">
              {t(`giftCard@modal@title${data && !data?.used ? '' : '@done'}`)}
            </Heading>
            <Text fontSize="lg" textAlign="center" mt={2}>
              {t(
                `giftCard@modal@description${
                  data && !data?.used ? '' : '@done'
                }`
              )}
            </Text>
            <Button mt="12" variant="orange" onClick={onGiftCardUse} w="full">
              {t('giftCard@modal@button')}
            </Button>
          </>
        )}
      </Flex>
    </Box>
  )
}
