/* eslint-disable */
import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import moment from 'moment'
import { useTransactions } from 'contexts'
import SeeAllModal from './SeeAllModal'
import ListItem from 'components/ListItem'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCY_SYMBOLS,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import { mapTopDonorFirstName } from 'pages/Donation/donationHelpers'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

export default function DonationList({
  project,
  donationPage = false,
  ...rest
}) {
  const { setLoadMore, nextToken, data, isMoreLoading } = useTransactions()
  const { t, language } = useTranslation()
  const { onOpen, isOpen, onClose } = useDisclosure()
  if (data.length === 0) return null

  return (
    <>
      <SeeAllModal
        isMoreLoading={isMoreLoading}
        nextToken={nextToken}
        setLoadMore={setLoadMore}
        transactions={{ items: data }}
        projectId={project.id}
        isOpen={isOpen}
        onClose={onClose}
        donationPage={donationPage}
        project={project}
      />
      {!donationPage && data.length > 0 && (
        <Box
          mb={6}
          mt={
            ['closed', 'ended', 'terminated'].includes(project.status) ? 10 : 0
          }
          border="1px solid"
          borderRadius="16px"
          borderColor="border.100"
          w={{ base: 'auto', lg: '500px' }}
          {...rest}
        >
          <TopDonors data={project.topDonors} />
        </Box>
      )}
      <Box
        mb="16"
        border="1px solid"
        borderRadius="16px"
        borderColor="border.100"
        w={{ base: 'auto', lg: '500px' }}
        {...rest}
      >
        {data.length > 0 && (
          <Box px="8" mt={6}>
            {donationPage ? (
              <Heading fontSize="lg" mb={2}>
                {t('monthlyDonors')}
              </Heading>
            ) : (
              <Text
                fontWeight="700"
                textTransform="uppercase"
                fontSize="3xl"
                mb={6}
              >
                {t('Donors')}
              </Text>
            )}

            {data?.slice(0, 5).map((transaction, index) => (
              <React.Fragment key={transaction.id}>
                <ListItem
                  key={`one-time-transaction-${transaction.id}`}
                  data={mapTopDonorFirstName(transaction, project.topDonors)}
                  goalAmount={CurrencyConverter({
                    amount: transaction.amount,
                    currency: 'USD',
                    normalize: true,
                  })}
                  reachedText={t('reached')}
                  goalText={t(
                    project?.fundraisingType === 'oneTime'
                      ? 'goal'
                      : 'monthlyGoal'
                  )}
                  index={index}
                  SUPPORTED_CURRENCY_SYMBOLS={SUPPORTED_CURRENCY_SYMBOLS}
                  language={language}
                  dataLength={data.length}
                  dateFromNow={moment(transaction.createdAt).fromNow()}
                  variant={'donor'}
                  donationPage={donationPage}
                  isPeerToPeerFundraising={
                    project.fundraisingType === FUNDRAISER_PEER_TO_PEER
                  }
                  t={t}
                />
              </React.Fragment>
            ))}
            {data.length > 3 && (
              <Button my={6} onClick={onOpen} variant="transparent" w="full">
                {t('seeAll')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export const TopDonors = ({ data = [], ...rest }) => {
  const { t } = useTranslation()
  if (!data) {
    return null
  }
  return (
    <Box px={{ base: 0, md: 8 }} my={{ base: 3, sm: 6 }} {...rest}>
      <Text fontWeight="700" textTransform="uppercase" fontSize="3xl">
        ⭐️ {t('topDonors')}
      </Text>
      <Stack mt={6}>
        {data
          .filter(({ totalAmount }) => !!totalAmount)
          .sort((a, b) => b.totalAmount - a.totalAmount)
          .map(({ fullName, totalAmount }, index) => (
            <Flex
              key={`${fullName}_${index}`}
              borderTopWidth={index !== 0 ? 1 : 0}
              borderStyle="solid"
              borderColor="border.100"
              justifyContent="space-between"
              py={4}
              fontWeight={500}
              fontSize="md"
              color="gray.800"
            >
              <Text mr={2.5}>⭐️ {t(fullName)}</Text>
              <Text flexShrink={0}>
                {thousandSeparator(totalAmount, 'USD') + ' USD'}
              </Text>
            </Flex>
          ))}
      </Stack>
    </Box>
  )
}
