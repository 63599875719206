import React from 'react'
import { signInWithRedirect } from 'aws-amplify/auth'
import {
  Box,
  Button,
  Flex,
  Checkbox,
  IconButton,
  HStack,
  Heading,
  VStack,
  Text,
} from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import { useHistory } from 'react-router-dom'
import { Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { PasswordField } from 'components/PasswordField'
import { ReactComponent as Facebook } from 'assets/icons/authFacebook.svg'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { useQueryParams } from 'hooks'

const Mobile = ({
  register,
  errors,
  handleSubmit,
  onSubmit,
  loading,
  user,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useQueryParams()
  const onGoogleSignUp = () => {
    amplitude.getInstance().logEvent('signupgmail_mobile')
    console.log('sent event to amplitude(signupgmail_mobile)')

    signInWithRedirect({ provider: 'Google' })
  }
  const onFacebookSignUp = () => {
    amplitude.getInstance().logEvent('signupfb_mobile')
    console.log('sent event to amplitude(signupfb_mobile)')

    signInWithRedirect({ provider: 'Facebook' })
  }
  return (
    <Box pb="5rem">
      <Flex
        w="full"
        justifyContent="center"
        position="relative"
        alignItems="center"
        mb="10"
        borderBottom="1px solid"
        borderColor="border.100"
        py="3"
      >
        <Flex
          position="absolute"
          left="12px"
          onClick={() => history.goBack()}
          width="30px"
          height="30px"
          alignItems="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
        <Heading as="h1" textAlign="center" fontSize="3xl">
          {t('login@signUp')}
        </Heading>
      </Flex>
      <Box
        as="span"
        display="block"
        textAlign="center"
        fontSize="xl"
        fontWeight="600"
        letterSpacing="0.06px"
        mt="6"
        mb="4"
      >
        {t('signUp@heading')}
      </Box>
      <HStack justifyContent="center" mb="10" spacing="2">
        <IconButton
          onClick={onFacebookSignUp}
          p="0"
          icon={<Facebook width="59px" height="59px" />}
        />
        <IconButton
          onClick={onGoogleSignUp}
          p="0"
          icon={<Google width="59px" height="59px" />}
        />
      </HStack>
      <Box px="5">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <VStack pb="1.875rem" spacing="6">
            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              errorMessage={t('signUp@firstNameErrorMessage')}
              label={t('signUp@firstName')}
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />

            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              errorMessage={t('signUp@lastNameErrorMessage')}
              label={t('signUp@lastName')}
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />

            <InputField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              isInvalid={!!errors.email}
              label={t('signUp@email')}
              type="email"
              errorMessage={t(errors?.email?.message)}
              {...register('email', {
                required: {
                  message: 'createEditProject@thisFieldIsRequired',
                  value: true,
                },
                pattern: {
                  message: 'createEditInitiative@emailError',
                  value: /\S+@\S+\.\S+/,
                },
              })}
            />
            <PasswordField
              border="1px solid #CDCDCD"
              borderRadius="12px"
              size="lg"
              isInvalid={!!errors.password}
              newPassword
              {...register('password', {
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                minLength: 8,
              })}
              errorMessage={t('signUp@passwordErrorMessage')}
              label={t('signUp@password')}
            />
          </VStack>
          {user.id === 'guest' && (
            <Checkbox
              borderColor="border.100"
              mt={4}
              {...register('subscribeNews')}
            >
              <Text fontSize="sm" lineHeight="16px">
                {t('donation@subscribeOnDonateText').split('(bold)')[0]}
                <b>{t('donation@subscribeOnDonateText@bold')}</b>
                {t('donation@subscribeOnDonateText').split('(bold)')[1]}
              </Text>
            </Checkbox>
          )}
          <Box mt="33px" textAlign="center" fontSize="md" color="gray.900">
            {t('signUp@agreeToThe')}
            <br />
            <Link
              style={{
                color: '#1A1A1A',
                fontSize: '14px',
                fontWeight: 'bold',
                marginLeft: '5px',
                marginRight: '5px',
              }}
              target="_blank"
              to="/terms-and-conditions"
            >
              {t('signUp@termsConditions')}
            </Link>
            {t('signUp@andThe')}
            <Link
              style={{
                color: '#1A1A1A',
                fontSize: '14px',
                fontWeight: 'bold',
                marginLeft: '5px',
              }}
              target="_blank"
              to="/privacy-policy"
            >
              {t('signUp@privacyPolicy')}
            </Link>
          </Box>
          <Box mt="6" mb="4" w="full">
            <Button
              w="full"
              isLoading={loading}
              type="submit"
              variant="blue"
              fontSize="md"
              fontWeight="bold"
            >
              {t('login@signUp')}
            </Button>
          </Box>
          <Text
            mt={6}
            textAlign="center"
            color="gray.700"
            fontSize="sm"
            fontWeight="semibold"
          >
            {t('signUp@haveAnAccount')}{' '}
            <Button fontSize="sm" color="orange.400">
              <Link
                to={`/sign-in?from=${
                  encodeURIComponent(params.get('from')) || '/'
                }`}
              >
                {t('signUp@signIn')}
              </Link>
            </Button>
          </Text>
        </form>
      </Box>
    </Box>
  )
}

export default Mobile
