import React, { useMemo } from 'react'
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { Link } from 'pages'
import FilteredData from '../FilteredData'
import NoScrollbar from 'components/NoScrollbar'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

const TABS = [
  {
    id: 1,
    title: 'profile@all',
  },
  {
    id: 2,
    title: 'profile@active',
  },
  {
    id: 3,
    title: 'profile@completed',
  },
  {
    id: 4,
    title: 'profile@submitted',
  },
  {
    id: 5,
    title: 'profile@onHold',
  },
  {
    id: 6,
    title: 'status@draft',
  },
]
const SingleTab = ({ title }) => (
  <NoScrollbar
    Component={Tab}
    id="a"
    minW="136px"
    py={2}
    mb={0}
    _active={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _focus={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _selected={{
      backgroundColor: 'white',
      borderRadius: 'lg',
    }}
    textTransform="capitalize"
    lineHeight="shorter"
    textAlign="center"
    fontSize="lg"
    fontWeight="semibold"
    color="gray.900"
    border="none"
    whiteSpace="nowrap"
  >
    {title}
  </NoScrollbar>
)

export default function PeerToPeer({ projects, onDraftDelete, draftData, t }) {
  const peerToPeerProjects = useMemo(() => {
    return projects.filter(
      (project) => project.fundraisingType === FUNDRAISER_PEER_TO_PEER
    )
  }, [projects])

  return (
    <>
      {projects?.length > 0 && (
        <Link to="/pre-create-project">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            color="blue.400"
            minH="50px"
            variant="transparent"
            textTransform="capitalize"
          >
            {t('profile@createProject')}
          </Button>
        </Link>
      )}
      <Tabs>
        <Flex justifyContent="space-between" alignItems="center">
          <TabList
            sx={{
              '&': {
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
              },
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            borderRadius="lg"
            border="none"
            bg="#D9E0EA"
            p={1.5}
          >
            {TABS.map(({ id, title }) => (
              <SingleTab key={id} title={t(title)} />
            ))}
          </TabList>
          {projects?.length > 0 && (
            <Link to="/pre-create-project">
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                variant="border"
                lineHeight="none"
                textTransform="capitalize"
                fontSize="sm"
                fontWeight="medium"
                color="#292929"
              >
                {t('createEditProject@create')}
              </Button>
            </Link>
          )}
        </Flex>

        <TabPanels>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType="oneTime"
              filterStatus={['all']}
              data={peerToPeerProjects}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType="oneTime"
              filterStatus={[
                'live',
                'launching_soon',
                'waiting_for_transfer_confirmation',
              ]}
              data={peerToPeerProjects}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType="oneTime"
              filterStatus={['ended', 'closed', 'terminated']}
              data={peerToPeerProjects}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType="oneTime"
              filterStatus={[
                'submitted',
                'reviewed_by_ss',
                'reviewed_by_am',
                'reviewed_by_tns',
                'reviewed_by_bot',
                'project_page_development',
                'review_project_page',
                'published_by_owner',
              ]}
              data={peerToPeerProjects}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType="oneTime"
              filterStatus={[
                'on_hold_by_am',
                'on_hold_by_tns',
                'rejected_by_ss',
                'rejected_by_am',
                'rejected_by_tns',
                'rejected_by_bot',
                'project_page_development',
                'review_project_page',
              ]}
              data={peerToPeerProjects}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
          <TabPanel p="0" position="relative" pt="8">
            <FilteredData
              type="fundraisers"
              fundraisingType={FUNDRAISER_PEER_TO_PEER}
              filterStatus={['all']}
              data={draftData?.items}
              onDraftDelete={onDraftDelete}
              emptyButtonText={t('profile@createProject')}
              desktopMT="104px"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
