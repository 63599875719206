import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Center, Container, Text } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { ProjectProvider } from './ProjectContext'
import Tabs from './Tabs'
import Navbar from 'components/Navbar'
import { useTranslation } from 'contexts/TranslationContext'
const w = window.innerWidth

const CreateEditProject = () => {
  const { t } = useTranslation()
  const { projectId, peerProjectId } = useParams()
  const location = useLocation()

  const isPeerToPeerProject = location.pathname.includes('peer-to-peer')
  const isEditMode = location.pathname.includes('edit')

  return (
    <ProjectProvider
      projectId={projectId}
      peerProjectId={peerProjectId}
      isPeerToPeerProject={isPeerToPeerProject}
      isEditMode={isEditMode}
    >
      <MainLayout>
        {w < 481 && <Navbar title={t('creatorSpace')} />}
        <Container
          pb="5.5rem"
          maxW="6xl"
          pt="8"
          h={{ base: '100dvh', sm: 'auto' }}
          mt={{ base: -12, sm: 0 }}
        >
          {w > 481 ? (
            <Tabs projectId={projectId || peerProjectId} />
          ) : (
            <Center h="full">
              <Text color="gray.700" textAlign="center">
                {t('editProjectMobile')}
              </Text>
            </Center>
          )}
        </Container>
      </MainLayout>
    </ProjectProvider>
  )
}

export default CreateEditProject
