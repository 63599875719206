import React, { useState } from 'react'
import { Container, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUpdateUser } from 'hooks'
import { CATEGORY, REGIONS } from '_constants'
import { ReactComponent as Discord } from 'assets/icons/discord.svg'
import { ReactComponent as Arrow } from 'assets/icons/arrowRightBlue.svg'
import DonorSettingsSelect from './DonorSettingsSelect'
import CreditCards from 'components/CreditCards'
import { useLocation } from 'react-router-dom'

export default function Settings({ user, setUser }) {
  const { t, language } = useTranslation()
  const location = useLocation()
  const { updateUser, isLoading } = useUpdateUser()
  const [selectedCategories, setSelectedCategories] = useState(
    user.selectedCategories || []
  )
  const [selectedRegions, setSelectedRegions] = useState(
    user.selectedLocations || []
  )
  const onCategoriesMenuClose = (onClose) => {
    onClose()

    if (user.selectedCategories !== selectedCategories) {
      updateUser(
        {
          id: user.id,
          selectedCategories: selectedCategories,
        },
        {
          onSuccess: (response) =>
            setUser((oldUser) => ({
              ...oldUser,
              user: response.data.updateUser,
            })),
          onError: (err) => {
            console.log(err)
          },
        }
      )
    }
  }
  const onRegionsMenuClose = (onClose) => {
    onClose()

    if (user.selectedLocations !== selectedRegions) {
      updateUser(
        {
          id: user.id,
          selectedLocations: selectedRegions,
        },
        {
          onSuccess: (response) =>
            setUser((oldUser) => ({
              ...oldUser,
              user: response.data.updateUser,
            })),
          onError: (err) => {
            console.log(err)
          },
        }
      )
    }
  }

  return (
    <Container maxW="540px" ml={0}>
      <Heading fontSize="lg" fontWeight="bold">
        {t('profile@dashboard@donor@settings@heading')}
      </Heading>

      <Text mt={6} fontSize="md" color="gray.700">
        {t('profile@dashboard@donor@settings@description')}
      </Text>

      <Flex
        mt={5}
        justifyContent="space-between"
        flexDirection={{ md: 'row', base: 'column' }}
      >
        <DonorSettingsSelect
          t={t}
          label={t('profile@dashboard@donor@settings@selectCategory')}
          options={CATEGORY[language]}
          translationKey="category"
          selectedOptions={selectedCategories}
          setSelectedOptions={setSelectedCategories}
          isLoading={isLoading}
          onMenuClose={onCategoriesMenuClose}
        />

        <DonorSettingsSelect
          t={t}
          label={t('profile@dashboard@donor@settings@selectRegion')}
          options={REGIONS}
          translationKey="region"
          selectedOptions={selectedRegions}
          setSelectedOptions={setSelectedRegions}
          isLoading={isLoading}
          onMenuClose={onRegionsMenuClose}
        />
      </Flex>

      <Flex mt={2} flexDirection={{ md: 'row', base: 'column' }}>
        <Text fontSize="md" color="gray.700">
          {t('profile@dashboard@donor@settings@categories')}
        </Text>

        <Text ml={{ md: 2, base: 0 }} fontSize="md" color="blue.400">
          {user.selectedCategories
            ?.map((item) => t(`category@${item}`))
            .join(', ')}
        </Text>
      </Flex>

      <Flex mt={2} flexDirection={{ md: 'row', base: 'column' }}>
        <Text fontSize="md" color="gray.700">
          {t('profile@dashboard@donor@settings@regions')}
        </Text>

        <Text ml={{ md: 2, base: 0 }} fontSize="md" color="blue.400">
          {user.selectedLocations
            ?.map((item) => t(`region@${item}`))
            .join(', ')}
        </Text>
      </Flex>

      <Heading as="h2" fontSize="lg" mb={6} mt={8}>
        {t('profile@dashboard@donor@settings@savedCards')}
      </Heading>

      <CreditCards
        fullRedirectUrl={location.pathname.slice(3)}
        removable
        showIcons
        privacyPolicy={false}
        makeDefault
        scheme="gray"
      />

      <Heading as="h2" fontSize="lg" mt={8} mb={6}>
        {t('profile@dashboard@donor@settings@community')}
      </Heading>

      <Flex
        px={5}
        py={4}
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        borderRadius={8}
        h="72px"
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/adn5mgmNUg"
      >
        <Flex alignItems="center">
          <Discord />

          <Text fontSize="md" fontWeight={600} color="gray.800" ml={4}>
            {t('profile@dashboard@donor@settings@investorClub')}
          </Text>
        </Flex>

        <Arrow />
      </Flex>
    </Container>
  )
}
