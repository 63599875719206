import React from 'react'
import { Box, Image, Heading, Text } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'

const UserCard = ({ name, lastName, position, member, photo }) => {
  return (
    <Box>
      <LazyLoad height={240}>
        <Image
          mb="3"
          borderRadius="12px"
          objectFit="cover"
          w="full"
          height="160px"
          src={`/assets/images/${photo}`}
        />
      </LazyLoad>
      <Heading mb="2" fontSize="lg" as="h2">
        {name}
        <br />
        {lastName}
      </Heading>
      <Text fontSize="sm" color="gray.700" mb="5">
        {position}
        <Text>{member && member}</Text>
      </Text>
    </Box>
  )
}

export default UserCard
