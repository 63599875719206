import React from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCY_SYMBOLS,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useGetDonationSubscription } from 'hooks'
import { getNextRecurringChargeDate } from 'utils/date'

export default function RecurringInvestmentCard({ transaction, t }) {
  const { data: subscription } = useGetDonationSubscription(
    transaction.subscriptionId
  )
  const { currency } = useCurrencySettings()
  const nextChargeDate = getNextRecurringChargeDate()

  const currencyFormat = (amount) =>
    CurrencyConverter({
      currency: currency.current,
      amount: amount,
    }) + SUPPORTED_CURRENCY_SYMBOLS[currency.current]

  return (
    <Flex direction="column" mb={8} key={transaction.id}>
      <Flex justifyContent="space-between" h={9} mt={8} mb={4}>
        <Text fontWeight={600} fontSize="lg" color="gray.700">
          {t('profile@dashboard@investor@recurring@lastCharge')}
        </Text>
        <Flex direction="column" gap={1}>
          <Text
            fontWeight={600}
            fontSize="lg"
            color="gray.800"
            textAlign="right"
            lineHeight="normal"
          >
            {currencyFormat(
              transaction?.amount || transaction?.donationToProject || 0
            )}
          </Text>
          <Text
            fontWeight={500}
            fontSize="md"
            color="gray.800"
            lineHeight="normal"
          >
            {moment(new Date(transaction.createdAt)).format('MMMM DD, YYYY')}
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Text fontWeight={600} fontSize="lg" color="gray.700">
          {t('profile@dashboard@investor@recurring@nextCharge')}
        </Text>
        <Flex direction="column" gap={1}>
          <Text
            fontWeight={600}
            fontSize="lg"
            color="gray.800"
            textAlign="right"
            lineHeight="normal"
          >
            {subscription?.unsubscribedDate
              ? 0
              : currencyFormat(
                  transaction?.amount || transaction?.donationToProject || 0
                )}
          </Text>
          <Text
            fontWeight={500}
            fontSize="md"
            color="gray.800"
            lineHeight="normal"
            textAlign="right"
          >
            {moment(nextChargeDate).format('MMMM DD, YYYY')}
          </Text>
        </Flex>
      </Flex>
      <Divider h="1px" bg="#EEBF45" w="full" mt={4} mb={3} />
      <Flex justifyContent="space-between">
        <Text fontWeight={600} fontSize="lg" color="gray.700">
          {t('profile@dashboard@investor@recurring@paidWith')}
        </Text>
        <Text
          fontWeight={600}
          fontSize="lg"
          color="gray.800"
          textAlign="right"
          lineHeight="normal"
        >
          {transaction?.creditCard?.[0]?.pan}
        </Text>
      </Flex>
    </Flex>
  )
}
