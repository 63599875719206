import React from 'react'
import { Button, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import EmptyState from 'components/EmptyState'
import InvestmentCard from './InvestmentCard'
import { BlueSelect } from 'components/Select/BlueSelect'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import RecurringInvestmentCard from './RecurringInvestmentCard'
import useListInvestments from './useListInvestments'

export default function Recurring({ user }) {
  const { currency, changeDefaultCurrency } = useCurrencySettings()
  const { t } = useTranslation()
  const { isLoading, isRefetching, onLoadNext, items, nextToken } =
    useListInvestments({
      email: user.email,
      isRecurring: true,
    })
  const newestSubscription = items[0]

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : items.length > 0 && !!user.investmentRecurringTotal ? (
        <Flex direction="column" alignItems="flex-start">
          <Flex alignItems="center" mb="26px">
            <Heading color="gray.700" fontSize="2xl" fontWeight={800} mr={2}>
              {t('profile@dashboard@investor@recurring@totalInvestment')}:
            </Heading>
            <Text color="gray.900" fontSize="2xl" fontWeight={800}>
              {CurrencyConverter({
                currency: currency.current,
                amount: Math.round(+user?.investmentRecurringTotal),
              })}
            </Text>
            <BlueSelect
              variant="noBorder"
              display="flex"
              type="currency"
              amount={Math.round(+user?.investmentRecurringTotal)}
              label={SUPPORTED_CURRENCY_SYMBOLS[currency.current]}
              options={SUPPORTED_CURRENCIES.map((option) => ({
                title: t(`currency@${option}`),
                amount: `${CurrencyConverter({
                  currency: option,
                  amount: Math.round(+user?.investmentRecurringTotal),
                })} ${option}`,
                value: option,
              }))}
              selectOption={changeDefaultCurrency}
              currentSelectedOption={t(`currency@${currency.current}`)}
              labelFontSize="xl"
            />
          </Flex>
          {newestSubscription ? (
            <RecurringInvestmentCard
              subscription={newestSubscription}
              amount={
                CurrencyConverter({
                  currency: currency.current,
                  amount:
                    newestSubscription.amount ||
                    newestSubscription.donationToProject,
                }) + SUPPORTED_CURRENCY_SYMBOLS[currency.current]
              }
              creditCard={newestSubscription?.creditCard?.[0]}
              t={t}
            />
          ) : (
            <Spinner />
          )}
          <Heading
            fontSize="2xl"
            fontWeight={600}
            color="gray.800"
            mt="42px"
            mb={4}
          >
            {t('profile@dashboard@investor@recurring@investmentHistory')}{' '}
          </Heading>
          <Flex direction="column" w="full">
            {items.map(
              ({ amount, donationToProject, formattedDate, creditCard }) => (
                <InvestmentCard
                  key={formattedDate}
                  amount={
                    SUPPORTED_CURRENCY_SYMBOLS[currency.current] +
                    ' ' +
                    CurrencyConverter({
                      currency: currency.current,
                      amount: amount || donationToProject,
                    })
                  }
                  formattedDate={formattedDate}
                  creditCard={creditCard?.[0]}
                  t={t}
                />
              )
            )}
            {nextToken && (
              <>
                {isRefetching ? (
                  <Flex
                    w={{ base: 'full', sm: '417px' }}
                    justifyContent="center"
                    my="52px"
                  >
                    <Spinner color="blue.300" />
                  </Flex>
                ) : (
                  <Button
                    mt="4"
                    minH="40px"
                    w={{ base: 'full', sm: '417px' }}
                    variant="white"
                    isLoading={isLoading}
                    onClick={onLoadNext}
                  >
                    {t('seeMore')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
      ) : (
        <Center h="100%">
          <EmptyState
            description={t(
              'profile@dashboard@investor@recurring@emptyDescription'
            )}
            button={t('profile@dashboard@investor@recurring@emptyButton')}
            link="/fundraisers?type=oneTime"
          />
        </Center>
      )}
    </>
  )
}
