import { Heading } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import { Redirect } from 'pages'
import React from 'react'

export default function Analytics() {
  const { user } = useUser()
  const { t } = useTranslation()
  if (!user.isAdmin) {
    return <Redirect to={'/'} />
  }
  return (
    <MainLayout>
      <Heading mt={8} mb={4} mx="auto">
        {t('analytics')}
      </Heading>
      <iframe
        title="EventsDashboard - events"
        width={window.innerWidth}
        height="1060"
        src="https://lookerstudio.google.com/embed/reporting/35668df5-fa72-4074-b02c-b447a46509af/page/tEnnC"
        allowFullScreen="true"
      ></iframe>
    </MainLayout>
  )
}
