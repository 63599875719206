import React, { useEffect, useState } from 'react'
import {
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as InvestorIcon } from 'assets/icons/dashboardDonorRecurringIcon.svg'
import { ReactComponent as ManageSubscriptions } from 'assets/icons/manageSubscriptions.svg'
import { useTranslation } from 'contexts/TranslationContext'
import { useModal } from 'providers/ModalProvider'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useGetRecurringTransactionByEmailDate } from 'hooks'
import { BlueSelect } from 'components/Select/BlueSelect'
import EmptyState from 'components/EmptyState'
import DonationCard from './DonationCard'
import RecurringInvestmentCard from './RecurringInvestmentCard'
import { useNavigation } from 'pages'

const w = window.innerWidth

export default function Recurring({ user }) {
  const { t, language } = useTranslation()
  const { navigationPush } = useNavigation()
  const { openModal } = useModal()
  const [transactions, setTransactions] = useState([])
  const [filters, setFilters] = useState({
    email: user.email,
    sortDirection: 'DESC',
    limit: 10,
    nextToken: null,
    filter: {
      status: { eq: 'DONE' },
      and: {
        subscriptionId: { attributeExists: true },
      },
    },
  })
  const {
    data: transactionsResponse,
    refetch,
    isLoading,
    isRefetching,
  } = useGetRecurringTransactionByEmailDate(filters)

  const { currency, changeDefaultCurrency } = useCurrencySettings()

  const onLoadNext = () => {
    if (transactionsResponse.nextToken) {
      refetch()
    }
  }

  useEffect(() => {
    if (!transactionsResponse?.items && !transactionsResponse?.nextToken) {
      return
    }
    setTransactions((oldTransactions) => [
      ...oldTransactions,
      ...(transactionsResponse?.items || []),
    ])
    setFilters((oldFilters) => ({
      ...oldFilters,
      nextToken: transactionsResponse.nextToken,
    }))
    if (
      !transactionsResponse?.items?.length &&
      !!transactionsResponse?.nextToken
    ) {
      refetch()
    }
  }, [transactionsResponse])

  useEffect(() => {
    return () => {
      setFilters({
        email: user.email,
        sortDirection: 'DESC',
        limit: 10,
        nextToken: null,
        filter: {
          status: { eq: 'DONE' },
          and: {
            subscriptionId: { attributeExists: true },
          },
        },
      })
      setTransactions([])
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : transactions && transactions?.length > 0 ? (
        <Flex direction="column" alignItems="flex-start">
          <Flex alignItems="center" mb={12}>
            <Heading color="gray.700" fontSize="2xl" fontWeight={800} mr={2}>
              {t('profile@dashboard@donor@oneTime@totalInvestment')}:
            </Heading>
            <Text color="gray.900" fontSize="2xl" fontWeight={800}>
              {CurrencyConverter({
                currency: currency.current,
                amount: Math.round(+user?.totalRecurringDonation),
              })}
            </Text>
            <BlueSelect
              variant="noBorder"
              display="flex"
              type="currency"
              amount={Math.round(+user?.totalOneTimeDonation)}
              label={SUPPORTED_CURRENCY_SYMBOLS[currency.current]}
              options={SUPPORTED_CURRENCIES.map((option) => ({
                title: t(`currency@${option}`),
                amount: `${CurrencyConverter({
                  currency: option,
                  amount: Math.round(+user?.totalRecurringDonation),
                })} ${option}`,
                value: option,
              }))}
              selectOption={changeDefaultCurrency}
              currentSelectedOption={t(`currency@${currency.current}`)}
              labelFontSize="xl"
            />
          </Flex>

          {transactions?.length ? (
            <Flex
              pt={4}
              px={{ base: 4, sm: '22px' }}
              pb={{ base: 5, sm: 6 }}
              direction="column"
              w={{ base: 'full', sm: '417px' }}
              bg="white"
              borderRadius={8}
              boxShadow=" 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0.00)"
            >
              <Flex justify="space-between" align="self-end" mt={-7}>
                <InvestorIcon height="64px" width="64px" />
                <IconButton
                  icon={<ManageSubscriptions height="24px" width="24px" />}
                  onClick={() => {
                    if (w < 481) {
                      navigationPush('/list-subscriptions')
                    } else {
                      openModal(
                        'listSubscriptions',
                        null,
                        null,
                        null,
                        { user },
                        { size: '2xl' }
                      )
                    }
                  }}
                />
              </Flex>
              {transactions.slice(0, 3).map((transaction, index) => {
                return (
                  <RecurringInvestmentCard
                    key={`${transaction.id}${index}`}
                    transaction={transaction}
                    t={t}
                  />
                )
              })}
            </Flex>
          ) : (
            <Spinner />
          )}

          <Heading fontSize="2xl" fontWeight={600} color="gray.800" mt={8}>
            {t('Donation History')}
          </Heading>
          <Flex direction="column" w="full">
            {transactions.map((item) => (
              <DonationCard
                key={item[`projectTitle_${language}`]}
                projectImage={item.projectImage}
                projectTitle={item[`projectTitle_${language}`]}
                projectId={item.projectId}
                peerProjectId={item.peerProjectId}
                amount={
                  SUPPORTED_CURRENCY_SYMBOLS[currency.current] +
                  ' ' +
                  CurrencyConverter({
                    currency: currency.current,
                    amount: item.amount || item.donationToProject,
                  })
                }
                formattedDate={item.formattedDate}
                creditCard={item.creditCard?.[0]}
                t={t}
                navigationPush={navigationPush}
              />
            ))}
            {transactionsResponse?.nextToken && (
              <>
                {isRefetching ? (
                  <Flex
                    w={{ base: 'full', sm: '417px' }}
                    justifyContent="center"
                    my="52px"
                  >
                    <Spinner color="blue.300" />
                  </Flex>
                ) : (
                  <Button
                    mt="4"
                    minH="40px"
                    w={{ base: 'full', sm: '417px' }}
                    variant="white"
                    isLoading={isLoading}
                    onClick={onLoadNext}
                  >
                    {t('seeMore')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
      ) : (
        <Center h="100%">
          <EmptyState
            description={t('profile@dashboard@donor@monthly@emptyDescription')}
            button={t('profile@dashboard@donor@monthly@emptyButton')}
            link="/fundraisers?type=recurring"
          />
        </Center>
      )}
    </>
  )
}
