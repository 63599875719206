import React from 'react'
import { Box, Flex, Img } from '@chakra-ui/react'
import { Link } from 'pages'

const AuthWrapper = ({ children }) => {
  return (
    <Flex
      w="full"
      minH="85vh"
      direction={'column'}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box as={Link} mb={8} to="/">
        <Img src="/logo_blue.png" w="166px" alt="Logo blue" />
      </Box>
      <Flex justifyContent="center" alignItems="center">
        {children}
      </Flex>
    </Flex>
  )
}

export default AuthWrapper
