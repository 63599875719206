import {
  Box,
  Flex,
  Button,
  Stack,
  Heading,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Remove } from 'assets/icons/remove-goal.svg'
import ImageUploader from 'pages/CreateEditProject/Content/FormData/ImageUploader'
import { TextAreaComponent } from 'components/TextAreaComponent'

const OptionsForm = ({
  validationErrors,
  collaborationFields,
  appendCollaboration,
  removeCollaboration,
  register,
  setValue,
  getValues = () => {},
  formLang = 'en',
  handleChange,
  imageUpload = true,
}) => {
  const { t } = useTranslation()
  const LIMIT = 5
  return (
    <Box>
      <Heading mb="6" as="h2" fontSize="xl">
        {t('Collaborations')}
      </Heading>
      <Stack mb="10" spacing="6">
        {collaborationFields
          ?.filter((e) => e.needs !== null && e.needs !== undefined)
          ?.map((item, index) => (
            <Flex key={index}>
              <Flex direction="column" w="full">
                {imageUpload && (
                  <ImageUploader
                    type="image"
                    label={t('createEditProject@clickToUploadImage')}
                    name={`collaborations.${index}.image`}
                    setValue={(name, value) => setValue(name, value)}
                    image={
                      getValues(`collaborations.${index}.image`) || item.image
                    }
                    mb={2}
                  />
                )}
                <InputField
                  id={`option_${index}`}
                  maxLength={50}
                  isInvalid={
                    validationErrors?.collaborations?.[index]?.[
                      `needs${handleChange ? '_' + formLang : ''}`
                    ]
                  }
                  label={t('describe the needs')}
                  {...register(
                    `collaborations.${index}.needs${
                      handleChange ? '_' + formLang : ''
                    }`,
                    {
                      required: t('field is required'),
                    }
                  )}
                />
                <FormControl>
                  <FormLabel mt={2} fontWeight="normal">
                    {t('edit@collaborationDesc')}
                  </FormLabel>
                  <TextAreaComponent
                    isInvalid={
                      validationErrors?.collaborations?.[index]?.[
                        `needsDescription${handleChange ? '_' + formLang : ''}`
                      ]
                    }
                    {...register(
                      `collaborations.${index}.needsDescription${
                        handleChange ? '_' + formLang : ''
                      }`,
                      {
                        required: false,
                      }
                    )}
                    maxLength={100}
                  />
                </FormControl>
              </Flex>
              {collaborationFields?.length > 1 && (
                <Button
                  minW="24px"
                  w="24px"
                  h="24px"
                  maxH="24px"
                  marginLeft={'16px !important'}
                  marginTop={'45px !important'}
                  borderRadius="100px"
                  id={`option_btn_${index}`}
                  onClick={() => {
                    removeCollaboration(index)
                  }}
                >
                  <Remove width="24px" />
                </Button>
              )}
            </Flex>
          ))}
      </Stack>
      <Button
        width="max-content"
        mb="10"
        onClick={() => {
          appendCollaboration({
            needs: '',
            needs_en: '',
            needs_ru: '',
            needs_hy: '',
            needsDescription: '',
            needsDescription_en: '',
            needsDescription_ru: '',
            needsDescription_hy: '',
          })
        }}
        isDisabled={collaborationFields?.length >= LIMIT}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          h="28px"
          w="28px"
          borderRadius="100%"
          bg={collaborationFields?.length < LIMIT ? 'blue.300' : 'gray.500'}
          color="white"
          mr="3"
        >
          +
        </Flex>
        {t('Add more options')}
      </Button>
    </Box>
  )
}

export default OptionsForm
