import React, { useEffect, useState, useCallback } from 'react'
import UserContext from './UserContext'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import {
  useLocalStorage,
  useAuthStateChange,
  useUserFactory,
  useUserUpdateSubscription,
} from 'hooks'

export const guestUser = {
  id: 'guest',
  username: 'guest',
  email: 'guest',
  firstName: 'guest',
  lastName: 'guest',
  emailVerified: false,
  isAdmin: false,
  seenSubscribeModal: false,
}

const useGetCachedUser = () => {
  const { get } = useLocalStorage()
  const getCachedUser = () => {
    try {
      let userCache = get('rearmenia_user')
      if (userCache) {
        userCache = JSON.parse(userCache)
        return { user: userCache, loading: false }
      } else {
        return { user: guestUser, loading: true }
      }
    } catch (err) {
      console.log(err)
      return { user: guestUser, loading: true }
    }
  }

  return {
    cachedUser: getCachedUser(),
  }
}
const UserProvider = ({ children }) => {
  const userUseCase = useUserFactory()
  const { cachedUser } = useGetCachedUser()
  const [userObj, setUserObj] = useState(cachedUser)
  const { retrieveLanguage } = useTranslation()
  const [company, setCompany] = useState({})
  const { navigationPush } = useNavigation()
  const { subscribeToUserUpdates } = useUserUpdateSubscription({
    userId: userObj?.user?.id,
    setUserObj,
    isAdmin: userObj?.user?.isAdmin,
  })
  const retrieveUser = useCallback(async () => {
    try {
      const signedInUser = await userUseCase.getUser()
      const companyResponse = await userUseCase.getCompany(
        signedInUser.email.split('@')[1]
      )
      setCompany(companyResponse)
      setUserObj({ user: signedInUser, loading: false })
      subscribeToUserUpdates(signedInUser.id)
      retrieveLanguage()
    } catch (e) {
      console.log('❌', e)
      setUserObj({ user: guestUser, loading: false })
      retrieveLanguage()
    }
  }, [])

  const onAuthStateChange = (event) => {
    switch (event) {
      case 'autoSignIn':
        retrieveUser()
        break
      default:
        break
    }
  }

  useAuthStateChange(onAuthStateChange)

  useEffect(() => {
    retrieveUser()
  }, [])

  useEffect(() => {
    if (userObj?.user?.id !== 'guest') {
      const redirectLocal = localStorage.getItem('redirect')
      if (redirectLocal) {
        localStorage.removeItem('redirect')
        navigationPush(redirectLocal)
      }
    }
  }, [userObj])
  return (
    <UserContext.Provider
      value={{
        setUser: setUserObj,
        user: userObj.user,
        setUserLoggedIn: retrieveUser,
        getUser: retrieveUser,
        isLoading: userObj.loading,
        company,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
