import { post, get } from 'aws-amplify/api'
import { getResponseToJSON } from 'hooks/useResponseManipulations'

const donationService = () => {
  const payment = async ({ path, body }) => {
    const res = post({
      apiName: 'paymentAPI',
      path,
      options: {
        body,
      },
    })
    return await getResponseToJSON(res)
  }
  const checkOrderStatus = async ({ orderId, method }) => {
    try {
      const res = await get({
        apiName: 'paymentAPI',
        path: `/orderStatus?id=${orderId}&method=${method}`,
      })
      return await getResponseToJSON(res)
    } catch (error) {
      console.log('❌', error)
      return {
        success: false,
        orderStatus: 0,
        data: '{}',
      }
    }
  }
  return {
    payment,
    checkOrderStatus,
  }
}

export default donationService
