import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Spinner } from '@chakra-ui/react'
import EmptyState from 'components/EmptyState'
import { useTranslation } from 'contexts/TranslationContext'
import useListFeed from 'hooks/feed/useListFeed'
import UpdateCard from 'components/UpdateCard'

export default function Impact({ user }) {
  const { t, language } = useTranslation()
  const [nextToken, setNextToken] = useState(null)
  const [updates, setUpdates] = useState([])

  const { data, isLoading, isRefetching, refetch } = useListFeed({
    userId: user.id || '',
    limit: 10,
    nextToken: nextToken,
    sortDirection: 'DESC',
  })
  const isEmpty = !user?.totalOneTimeDonation && !user?.totalRecurringDonation

  const onLoadNext = () => {
    if (data.nextToken) {
      setNextToken(data.nextToken)
      refetch()
    }
  }

  useEffect(() => {
    if (!data?.items && !data?.nextToken) {
      return
    }
    setUpdates((oldUpdates) => [...oldUpdates, ...(data?.items || [])])
    setNextToken(data.nextToken)
    if (!data?.items?.length && !!data?.nextToken) {
      refetch()
    }
  }, [data])

  useEffect(() => {
    return () => {
      setNextToken(null)
      setUpdates([])
    }
  }, [])

  return (
    <Flex
      h="100%"
      w={{ base: 'full', sm: isEmpty || isLoading ? 'full' : '716px' }}
      justify="center"
    >
      {isEmpty ? (
        <EmptyState
          description={t('profile@dashboard@donor@impact@emptyDescription')}
          button={t('profile@dashboard@donor@impact@emptyButton')}
          link="/fundraisers"
        />
      ) : isLoading ? (
        <Spinner />
      ) : updates?.length > 0 ? (
        <Flex direction="column" w="full">
          {updates?.map((update) => (
            <UpdateCard
              key={update.id}
              t={t}
              update={update}
              creatorImage={update?.creatorImage}
              creatorName={update?.creatorName}
              language={language}
              showAvatar
              bg="white"
              mb={8}
            />
          ))}

          {data?.nextToken && (
            <>
              {isLoading || isRefetching ? (
                <Flex w="full" justifyContent="center" my="52px">
                  <Spinner color="blue.300" />
                </Flex>
              ) : (
                <Button
                  mt="4"
                  minH="40px"
                  w="full"
                  variant="white"
                  isLoading={isLoading}
                  onClick={onLoadNext}
                >
                  {t('seeMore')}
                </Button>
              )}
            </>
          )}
        </Flex>
      ) : (
        <Box
          fontWeight="800"
          my="12"
          display="block"
          fontSize="6xl"
          color="blue.300"
          as="span"
        >
          {t('project@updates')}{' '}
          <Box as="span" fontWeight="400">
            {t('project@updatesText')}
          </Box>
        </Box>
      )}
    </Flex>
  )
}
