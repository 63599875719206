import React, { useEffect, useState } from 'react'
import { Button, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  CurrencyConverter,
  SUPPORTED_CURRENCIES,
  SUPPORTED_CURRENCY_SYMBOLS,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { useGetTransactionByEmailDate } from 'hooks'
import { BlueSelect } from 'components/Select/BlueSelect'
import EmptyState from 'components/EmptyState'
import DonationCard from './DonationCard'
import { useNavigation } from 'pages'

export default function OneTime({ user }) {
  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()
  const [transactions, setTransactions] = useState([])
  const [filters, setFilters] = useState({
    email: user.email,
    sortDirection: 'DESC',
    limit: 10,
    nextToken: null,
    filter: {
      status: { eq: 'DONE' },
      and: {
        subscriptionId: { attributeExists: false },
      },
    },
  })
  const {
    data: transactionsResponse,
    refetch,
    isLoading,
    isRefetching,
  } = useGetTransactionByEmailDate(filters)

  const { currency, changeDefaultCurrency } = useCurrencySettings()

  const onLoadNext = () => {
    if (transactionsResponse.nextToken) {
      refetch()
    }
  }

  useEffect(() => {
    if (!transactionsResponse?.items && !transactionsResponse?.nextToken) {
      return
    }
    setTransactions((oldTransactions) => [
      ...oldTransactions,
      ...(transactionsResponse?.items || []),
    ])
    setFilters((oldFilters) => ({
      ...oldFilters,
      nextToken: transactionsResponse.nextToken,
    }))
    if (
      !transactionsResponse?.items?.length &&
      !!transactionsResponse?.nextToken
    ) {
      refetch()
    }
  }, [transactionsResponse])

  useEffect(() => {
    return () => {
      setFilters({
        email: user.email,
        sortDirection: 'DESC',
        limit: 10,
        nextToken: null,
      })
      setTransactions([])
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : transactions && transactions?.length > 0 ? (
        <Flex
          direction="column"
          alignItems="flex-start"
          sx={{ '> div': { width: '100%' } }}
        >
          <Flex alignItems="center" mb={12}>
            <Heading color="gray.700" fontSize="2xl" fontWeight={800} mr={2}>
              {t('profile@dashboard@donor@oneTime@totalInvestment')}:
            </Heading>
            <Text color="gray.900" fontSize="2xl" fontWeight={800}>
              {CurrencyConverter({
                currency: currency.current,
                amount: +user?.totalOneTimeDonation,
              })}
            </Text>
            <BlueSelect
              variant="noBorder"
              display="flex"
              type="currency"
              amount={Math.round(+user?.totalOneTimeDonation)}
              label={SUPPORTED_CURRENCY_SYMBOLS[currency.current]}
              options={SUPPORTED_CURRENCIES.map((option) => ({
                title: t(`currency@${option}`),
                amount: `${CurrencyConverter({
                  currency: option,
                  amount: Math.round(+user?.totalOneTimeDonation),
                })} ${option}`,
                value: option,
              }))}
              selectOption={changeDefaultCurrency}
              currentSelectedOption={t(`currency@${currency.current}`)}
              labelFontSize="xl"
            />
          </Flex>
          <Heading fontSize="2xl" fontWeight={600} color="gray.800" mt={0}>
            {t('Donation History')}
          </Heading>
          <Flex direction="column" w="full">
            {transactions.map((item) => (
              <DonationCard
                key={item[`projectTitle_${language}`]}
                projectImage={item.projectImage}
                giftCardTransactionType={item.giftCardTransactionType}
                projectTitle={item[`projectTitle_${language}`]}
                projectId={item.projectId}
                peerProjectId={item.peerProjectId}
                amount={
                  SUPPORTED_CURRENCY_SYMBOLS[currency.current] +
                  ' ' +
                  CurrencyConverter({
                    currency: currency.current,
                    amount: item.amount || item.donationToProject,
                  })
                }
                formattedDate={item.formattedDate}
                creditCard={item.creditCard?.[0]}
                t={t}
                navigationPush={navigationPush}
              />
            ))}
            {transactionsResponse?.nextToken && (
              <>
                {isRefetching ? (
                  <Flex
                    w={{ base: 'full', sm: '417px' }}
                    justifyContent="center"
                    my="52px"
                  >
                    <Spinner color="blue.300" />
                  </Flex>
                ) : (
                  <Button
                    mt="4"
                    minH="40px"
                    w={{ base: 'full', sm: '417px' }}
                    variant="white"
                    isLoading={isLoading}
                    onClick={onLoadNext}
                  >
                    {t('seeMore')}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
      ) : (
        <Center h="100%">
          <EmptyState
            description={t('profile@dashboard@donor@oneTime@emptyDescription')}
            button={t('profile@dashboard@donor@oneTime@emptyButton')}
            link="/fundraisers?type=oneTime"
          />
        </Center>
      )}
    </>
  )
}
