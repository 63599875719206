import { useState, useEffect } from 'react'
import { useNavigation } from 'pages'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  useGetTransaction,
  useQueryParams,
  useCustomToast,
  useGetProject,
} from 'hooks'
import useUpdateTransaction from 'hooks/transaction/useUpdateTransaction'
import { useTranslation } from 'contexts/TranslationContext'
import { Flex, Spinner } from '@chakra-ui/react'
import useSendOneTimeEmail from 'hooks/stripe/useSendOneTimeEmail'
import moment from 'moment'
import { useCurrencySettings } from 'contexts/CurrencySettingsContext'

const CheckTransactionStripe = () => {
  const queryParams = useQueryParams()
  const { mutate: updateTransaction } = useUpdateTransaction()
  const toast = useCustomToast()
  const clientSecret = queryParams.get('clientSecret')
  const transactionId = queryParams.get('transactionId')
  const subscriptionId = queryParams.get('subscriptionId')
  const { data: transactionInfo } = useGetTransaction(transactionId)
  const stripe = useStripe()
  const { navigationReplace } = useNavigation()
  const { language } = useTranslation()
  const {
    firstName,
    projectId,
    peerProjectId,
    email,
    id,
    amount,
    donationToProject,
    donationToReArmenia,
    createdAt,
    creditCard,
    giftCardTransactionType,
  } = transactionInfo || {}
  const { mutate: sendStripeOneTimeEmail } = useSendOneTimeEmail()
  const { data: peerProject } = useGetProject({
    projectId: peerProjectId,
  })
  const { currency, settings } = useCurrencySettings()

  const currencyRate =
    currency.current === 'AMD' ? 1 : settings?.currency[currency.current]

  const [message, setMessage] = useState()

  useEffect(() => {
    if (!stripe || !transactionInfo) {
      return
    }
    if (peerProjectId && !peerProject?.id) {
      return
    }
    let statusValues = {
      requires_payment_method: 'error',
      succeeded: 'success',
      processing: 'info',
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received.')
            break

          case 'processing':
            setMessage(
              "Payment processing. We'll update you when payment is received."
            )
            break

          case 'requires_payment_method':
            setMessage('Payment failed. Please try another payment method.')
            break

          default:
            setMessage('Something went wrong.')
            break
        }

        if (['APPLE_PAY', 'GOOGLE_PAY'].includes(creditCard?.[0].type)) {
          await sendStripeOneTimeEmail({
            transactionId: id,
            email,
            name: firstName,
            donationAmount: amount,
            donationToProject,
            donationToReArmenia,
            currencyRate,
            currency: currency.current,
            projectName: transactionInfo[`projectTitle_${language}`],
            projectName_en: transactionInfo.projectTitle_en,
            projectId: peerProjectId || projectId,
            donationDate: moment(createdAt).format('MMM D, YYYY'),
            donationDate_en: moment(createdAt)
              .locale('en')
              .format('MMM D, YYYY'),
            transactionLanguage: transactionInfo.language,
            paymentType: creditCard[0].type,
            last4: creditCard[0].pan,
            isOneTime: !subscriptionId,
          })
        }

        let url = `/donate/thank-you-stripe?name=${firstName}&title=${
          transactionInfo?.[`projectTitle_${language}`]
        }&id=${projectId}&monthly=${!!subscriptionId}&contribution=${[
          process.env.REARMENIA_ID,
          'rearmenia',
        ].includes(projectId)}&transactionId=${id}`

        if (giftCardTransactionType === 'buy') {
          url = `/gift-card/thank-you`
        }

        const status = statusValues[paymentIntent.status] || 'error'
        if (['success', 'error'].includes(status)) {
          const updateTransactionInput = {
            id: transactionId,
            status: transactionStatusTransformer[status],
          }

          if (subscriptionId) {
            updateTransactionInput.subscriptionId = subscriptionId
          }
          await updateTransaction(updateTransactionInput, {
            onSuccess: () => {
              navigationReplace(url)
            },
            onError: (error) => {
              toast({
                title: 'Something went wrong',
                status: 'error',
              })
              console.log(error)
            },
          })
        }
        if (message && status) {
          toast({
            title: message,
            status: status,
          })
        }
        console.log(message)
      })
  }, [stripe, transactionInfo, peerProject])

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="lg" />
    </Flex>
  )
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE)
const PaymentStatusWithStripe = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckTransactionStripe />
    </Elements>
  )
}

export default PaymentStatusWithStripe

const transactionStatusTransformer = {
  success: 'DONE',
  error: 'FAILED',
}
