import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import React, { useState } from 'react'
import Header from 'components/Header'
import { useListGiftCardsByCode } from 'hooks'
import { useNavigation } from 'pages'
const w = window.innerWidth
export default function RedeemGiftCard() {
  const { t } = useTranslation()
  const { mutate: getGiftCard, isLoading } = useListGiftCardsByCode()
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const { navigationPush } = useNavigation()
  const onRedeem = () => {
    console.log(code)
    getGiftCard(
      { code },
      {
        onSuccess: (res) => {
          console.log(res.code, !res.code)
          if (!res.code) {
            setError(t('giftCard@invalid'))
            return
          }
          res.amountWithCurrency = Math.floor(
            Number(res.amount) / Number(res.currencyRate)
          )
          if (res.used) {
            setError(t('giftCard@alreadyUsed'))
            return
          }

          const redirectUrl =
            w > 485
              ? `/fundraisers?type=oneTime&action=redeemGiftCard&giftCardCode=${res.code}`
              : `/redeem-mobile?giftCardCode=${res.code}`
          navigationPush(redirectUrl)
        },
        onSettled: () => {},
      }
    )
  }
  return (
    <>
      {w < 485 && <Header title={t('redeem')} />}
      <Flex
        w={{ base: 'auto', sm: 'full' }}
        minH="85vh"
        flexDir={{ base: 'column', sm: 'row' }}
        mx={{ base: 5, sm: 0 }}
      >
        {w > 485 ? (
          <Image src="/assets/images/redeem_giftcard.jpg" h="100vh" />
        ) : (
          <Image
            src="/assets/images/dialog_mobile.jpg"
            h="180px"
            mb={8}
            borderRadius={12}
            objectFit="cover"
          />
        )}

        <Flex w="full" justifyContent="center" alignItems="center">
          <Box w="full" maxW="lg">
            <Heading
              as="h1"
              fontSize="4xl"
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign="center"
              mb="12"
              display={{ base: 'none', sm: 'block' }}
            >
              {t('redeemGiftCard')}
            </Heading>

            <Box w="full" bg="white" pb="8">
              <Stack spacing="8">
                {error && <Text color="red">{error}</Text>}
                <InputField
                  size="lg"
                  label={t('giftCard@enterCode')}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="green"
                  size="lg"
                  fontSize="md"
                  borderRadius="12px"
                  onClick={onRedeem}
                  isLoading={isLoading}
                  isDisabled={!code}
                >
                  {t('redeem')}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}
