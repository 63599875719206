import React, { useRef, useState } from 'react'
import { Button, Box, Flex, Text, Stack, Input } from '@chakra-ui/react'
import { FaFile, FaTimesCircle } from 'react-icons/fa'
import { useUploadFiles } from '../../hooks'
import { useTranslation } from 'contexts/TranslationContext'
import FileUpload from 'components/FileUpload'
import { ReactComponent as Attach } from '../../assets/icons/attach.svg'

export const BudgetFiles = ({
  setValue,
  getValues,
  filesWrapperProps,
  errors,
  register,
  budgetFiles,
  clearErrors,
  required = true,
}) => {
  const { mutate: filesUpload } = useUploadFiles()
  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null)
  const { t, language } = useTranslation()

  const handleClick = () => inputRef.current?.click()
  const uploadFiles = (event) => {
    const { files } = event.target
    if (files && files[0]) {
      const file = files[0]
      const newFile = new File([file], `${Date.now()}_${file.name}`, {
        type: file.type,
      })
      setLoading(true)
      filesUpload(
        {
          data: [newFile],
        },
        {
          onSuccess: (data) => {
            clearErrors('budgetFiles')
            const urls = data.map((image) => image.key)
            setValue('budgetFiles', [...getValues('budgetFiles'), ...urls])
            setLoading(false)
          },
          onError: () => {
            setLoading(false)
          },
        }
      )
    }
  }

  const onRemove = (e, file) => {
    e.preventDefault()
    e.stopPropagation()
    setValue('budgetFiles', [
      ...getValues('budgetFiles').filter((f) => f !== file),
    ])
  }

  return (
    <Box>
      <Flex mb="1">
        <Attach />
        <Button
          ml="2"
          display="flex"
          flexDir="column"
          alignItems="flex-start"
          fontSize="sm"
          fontWeight="regular"
          textTransform="capitalize"
          color={errors.budgetFiles ? 'red.400' : 'gray.800'}
          isLoading={loading}
        >
          <Box onClick={handleClick}>{t('Attach a budget sheet')}</Box>
          <Flex mt="6px" alignItems="center" textTransform="none">
            <Text
              color="gray.700"
              fontWeight={400}
              fontSize="sm"
              onClick={handleClick}
            >
              {t('Attach a budget sheet Text').split('...')[0]}
            </Text>
            <Text
              color="blue.400"
              as="span"
              mr={1}
              ml={language === 'ru' ? 0 : 1}
            >
              <a
                href="/assets/files/budgetTemplate.xlsx"
                target="_blank"
                style={{
                  fontWeight: 700,
                }}
              >
                {t('Attach a budget sheet Text').split('...')[1]}
              </a>
            </Text>
            <Text
              color="gray.700"
              fontWeight={400}
              fontSize="sm"
              onClick={handleClick}
            >
              {t('Attach a budget sheet Text').split('...')[2]}
            </Text>
          </Flex>
        </Button>
        <Input display="none" {...register('budgetFiles', { required })} />
      </Flex>
      <Stack {...filesWrapperProps} spacing="2">
        {budgetFiles &&
          budgetFiles?.map((file) => (
            <Flex
              key={`edit-project-application-file-${file}`}
              borderWidth="1px"
              borderColor="border.100"
              borderRadius="7px"
              pl={3}
              py="14px"
              alignItems="center"
              position="relative"
              _hover={{
                button: {
                  opacity: 1,
                },
              }}
            >
              <FaFile color="gray.600" />
              <a
                style={{ marginLeft: '0.875rem' }}
                href={`${
                  process.env.REACT_APP_S3_BUCKET_URL
                }/public/${file.replaceAll(' ', '+')}`}
                download
              >
                {file}
              </a>
              <Button
                position="absolute"
                right="12px"
                top="50%"
                transform="translateY(-50%)"
                bg="none"
                border="none"
                minW="auto"
                minH="auto"
                opacity="0"
                transition="opacity 200ms"
                onClick={(e) => onRemove(e, file)}
              >
                <FaTimesCircle color="gray.600" />
              </Button>
            </Flex>
          ))}
      </Stack>
      <input
        multiple
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={uploadFiles}
        id="budgetFiles"
      />
    </Box>
  )
}

FileUpload.defaultProps = {
  getValues: () => {},
  setValue: () => {},
  filesWrapperProps: {},
}
