import React, { useState } from 'react'
import { signInWithRedirect } from 'aws-amplify/auth'
import { Button, Stack, Text, IconButton, Flex, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useNavigation } from 'pages'
import { PasswordField } from 'components/PasswordField'
import { InputField } from 'components/InputField'
import { useTranslation } from 'contexts/TranslationContext'
import { useQueryParams, useSignIn, useUser } from 'hooks'
import { ReactComponent as Google } from 'assets/icons/authGoogle.svg'
import useCustomToast from 'hooks/useCustomToast'

export const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
  })
  const queryParams = useQueryParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { signIn } = useSignIn()
  const { getUser } = useUser()
  const { navigationPush, navigationReplace } = useNavigation()
  const toast = useCustomToast()
  const submit = (data) => {
    setLoading(true)
    signIn(
      {
        ...data,
        email: data.email.toLowerCase(),
      },
      {
        onSuccess: async () => {
          await getUser()
          if (queryParams.get('token')) {
            return navigationPush(
              `/collaboration-request?token=${queryParams.get(
                'token'
              )}&initiativeId=${queryParams.get(
                'initiativeId'
              )}&email=${queryParams.get('email')}&response=${queryParams.get(
                'response'
              )}`
            )
          }
          const from = queryParams.get('from')
          if (from) {
            if (from === '/donation') {
              const redirectURL = localStorage.getItem('redirectURL')
              localStorage.removeItem('redirectURL')
              navigationReplace(redirectURL || '/')
              return
            }
            navigationReplace(from)
            return
          }
          navigationReplace('/')
        },
        onError: (err) => {
          setLoading(false)
          if (err.__type === 'UserNotConfirmedException') {
            navigationPush(`/verify?email=${data.email}`)
          }
          toast({
            title: t(err.message),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        },
      }
    )
  }
  const onError = () => {
    console.log(errors)
  }
  return (
    <form onSubmit={handleSubmit(submit, onError)}>
      <Flex justifyContent="center" mb={8}>
        {/* <IconButton
          onClick={() => signInWithRedirect({ provider: 'Facebook' })}
          p="0"
          mx={3}
          icon={<Facebook />}
        /> */}
        <IconButton
          onClick={() => signInWithRedirect({ provider: 'Google' })}
          p="0"
          mx={3}
          icon={<Google />}
        />
      </Flex>
      <Box
        as="span"
        display="block"
        textAlign="center"
        textTransform="uppercase"
        fontSize="md"
        color="gray.700"
        letterSpacing="0.05px"
        mb={6}
      >
        {t('or')}
      </Box>
      {(!!errors.password || !!errors.email) && (
        <Text mb="4" ml="2" color="red.400">
          {t('Credentials are required')}
        </Text>
      )}
      <Stack spacing="4">
        <InputField
          size="lg"
          label={t('login@email')}
          {...register('email', {
            required: true,
            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
          })}
        />
        <PasswordField
          size="lg"
          label={t('login@password')}
          onUpdate={(e) => {
            setValue('password', e.target.value)
          }}
          {...register('password', { required: true })}
          notValidate
        />
        <Button
          display="inline-block"
          textAlign="start"
          textTransform="initial"
          color="gray.800"
          fontSize="sm"
          fontWeight="semibold"
          mb="30px"
        >
          <Link to="/forgot-password">{t('login@forgotPassword')}</Link>
        </Button>
      </Stack>
      <Button
        isLoading={loading}
        mb={{ base: '4', md: '0' }}
        w="full"
        type="submit"
        size="lg"
        variant="blue"
        colorScheme="blue"
        mt={8}
      >
        {t('login@submit')}
      </Button>
      <Flex mt={8} py={4} px={6} justifyContent="center" alignItems="center">
        <Text
          display="inline-block"
          textAlign="center"
          color="gray.800"
          fontSize="sm"
          fontWeight="semibold"
          mr={1}
        >
          {t('login@dontHaveAccount')}{' '}
        </Text>
        <Button color="orange.400">
          <Link to="/sign-up">{t('login@signUp')}</Link>
        </Button>
      </Flex>
    </form>
  )
}
