import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { ReactComponent as ArrowMobile } from 'assets/icons/arrow.svg'
import { useTranslation } from 'contexts/TranslationContext'

const w = window.innerWidth

export default function Pagination({
  total,
  size = 16,
  currentPage,
  setCurrentPage,
  showTotal = true,
  onClickCallback = () => {},
}) {
  const { t } = useTranslation()
  const pages = useMemo(() => {
    return total && size
      ? new Array(Math.ceil(total / size)).fill(0).map((_, i) => i + 1)
      : []
  }, [total, size])
  const showDots = (currentPage, pages, i) => {
    return (
      (currentPage >= 6 && i === 2) ||
      (currentPage <= pages.length - 5 && i === currentPage + 3)
    )
  }
  const showButton = (currentPage, pages, i) => {
    return (
      Math.abs(currentPage - i) <= 2 ||
      i === 1 ||
      i === pages.length ||
      (currentPage === 5 && i === 2) ||
      (currentPage === pages.length - 4 && i === pages.length - 1)
    )
  }
  return (
    <Flex
      my={8}
      px={6}
      w="full"
      justifyContent={
        showTotal ? 'space-between' : { base: 'center', sm: 'end' }
      }
      alignItems="center"
    >
      {showTotal && (
        <Text>
          {t('dashboard@results')}: {total}
        </Text>
      )}
      <HStack spacing={{ base: '12', sm: '4' }}>
        <Button
          textTransform="capitalize"
          isDisabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            onClickCallback()
          }}
          leftIcon={
            <ArrowMobile
              style={{
                fill: currentPage === 1 ? '#CDCDCD' : '#012F73',
                transform: 'rotate(180deg)',
                width: w > 481 ? '6px' : '8px',
              }}
            />
          }
          fontWeight={{ base: '500', sm: '400' }}
          fontSize={{ base: 'xl', sm: 'sm' }}
        >
          {t('dashboard@previous')}
        </Button>
        <Box display={{ base: 'none', sm: 'block' }}>
          {pages.map((i) => (
            <Box key={i} as="span">
              {showDots(currentPage, pages, i) && (
                <Button
                  key={`showDots-${i}`}
                  textTransform="capitalize"
                  fontWeight="400"
                  borderColor="#ED8B00"
                  paddingBlock={'12px'}
                >
                  ...
                </Button>
              )}
              {showButton(currentPage, pages, i) && (
                <Button
                  textTransform="capitalize"
                  fontWeight="400"
                  key={`pagination-${i}`}
                  color={i === currentPage ? '#ED8B00' : 'black'}
                  border={i === currentPage ? '1px solid' : 'none'}
                  borderColor="#ED8B00"
                  paddingBlock={'12px'}
                  onClick={() => {
                    setCurrentPage(i)
                    onClickCallback()
                  }}
                >
                  {i}
                </Button>
              )}
            </Box>
          ))}
        </Box>
        <Button
          textTransform="capitalize"
          isDisabled={pages.at(-1) === currentPage}
          onClick={() => {
            setCurrentPage(currentPage + 1)
            onClickCallback()
          }}
          rightIcon={
            <ArrowMobile
              style={{
                fill: pages.at(-1) === currentPage ? '#CDCDCD' : '#012F73',
                width: w > 481 ? '6px' : '8px',
              }}
            />
          }
          fontWeight={{ base: '500', sm: '400' }}
          fontSize={{ base: 'xl', sm: 'sm' }}
        >
          {t('dashboard@next')}
        </Button>
      </HStack>
    </Flex>
  )
}
