import { useTranslation } from 'contexts/TranslationContext'
import { useEffect } from 'react'

const RedirectWithLanguage = ({ fileName }) => {
  const { language } = useTranslation()

  useEffect(() => {
    let redirectURL = `${process.env.REACT_APP_BASE_URL}/${fileName}`
    if (language === 'ru') {
      redirectURL += '-rs'
    }

    if (language === 'hy') {
      redirectURL += '-am'
    }
    redirectURL += '.html'
    window.location.replace(redirectURL)
  }, [])
  return <></>
}

const WithType =
  (fileName) =>
  ({ props }) =>
    <RedirectWithLanguage fileName={fileName} {...props} />

export default WithType
