import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Button,
  Heading,
  Flex,
  Image,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { SigninForm } from './SigninForm'
import { useQueryParams, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { Link, useNavigation } from 'pages'
import AuthWrapper from 'components/AuthWrapper'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import MobileLogin from './MobileLogin'

const w = window.innerWidth

const Login = () => {
  const { navigationReplace } = useNavigation()
  const [newRegistered, setNewRegistered] = useState(false)
  const { setUserLoggedIn } = useUser()
  const { t } = useTranslation()
  const from = useQueryParams().get('from')
  const socialSignIn = useQueryParams().get('socialSignIn')
  const socialSignOut = useQueryParams().get('socialSignOut')

  const checkUser = async () => {
    try {
      await setUserLoggedIn()
      navigationReplace('/user')
    } catch (e) {
      navigationReplace('/sign-in')
    }
  }

  useEffect(() => {
    if (socialSignIn || socialSignOut) {
      checkUser()
    }
  }, [])

  useEffect(() => {
    if (from === 'verify') {
      setNewRegistered(true)
    }
  }, [from])

  useMemo(() => {
    setTimeout(() => setNewRegistered(false), 5000)
  }, [newRegistered])

  if (w < 481) {
    return <MobileLogin />
  }

  return (
    <AuthWrapper>
      {newRegistered && (
        <Flex
          zIndex="1000"
          top="0"
          left="0"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          position="fixed"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p="6"
            boxShadow="0 4px 24px 0 rgba(0,0,0,0.15)"
            borderRadius="16px"
            bg="white"
            w="full"
            maxW="460px"
          >
            <Image
              mb="3"
              objectFit="cover"
              w="210px"
              h="157px"
              src="/assets/images/welcome.png"
            />
            <Box mb="3" textAlign="center" as="span" fontSize="6xl">
              {t('signUp@welcome')}
              <b />
              <Box color="blue.300" fontWeight="bold">
                {t('signUp@community')}
              </Box>
            </Box>
            <Box color="green.500" fontSize="lg" as="span">
              {t('signUp@success')}
            </Box>
          </Flex>
        </Flex>
      )}
      <Box w="full" maxW="lg">
        <Link to="/">
          <Button
            display={{ base: 'inline-flex', md: 'none' }}
            position="absolute"
            top="20px"
            right="20px"
            variant="transparent"
          >
            <Close width="20px" />
          </Button>
        </Link>
        <Heading
          as="h1"
          fontSize="5xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign="center"
        >
          {t('login@heading')}
        </Heading>

        <Box
          minW={{ md: '350px' }}
          rounded="xl"
          bg={{ md: mode('white', 'gray.700') }}
          pt={{ base: '8', md: '12' }}
          pb="8"
        >
          <SigninForm />
        </Box>
      </Box>
    </AuthWrapper>
  )
}

export default Login
