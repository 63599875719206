import React, { useEffect, useState } from 'react'
import { SimpleGrid, GridItem, Heading, Flex, Spinner } from '@chakra-ui/react'
import AllProductsWrapper from 'components/AllProductsWrapper'
import ProjectCard from 'components/ProjectCard'
import {
  useQueryParams,
  useUser,
  useProjects,
  calculateProjectDurationLeft,
} from 'hooks'
import MobileSearch from './mobileSearch'
import { CATEGORY, GENERAL_USER_VISIBLE_STATUSES } from '_constants'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { useNavigation } from 'pages'

const w = window.innerWidth

const Search = ({ items, isLoading: isInitialLoading, refetch, total }) => {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const queryParams = useQueryParams()
  const [isLoading, toggleLoading] = useState(isInitialLoading)
  const [filters, setFilters] = useState({
    text: queryParams.get('text'),
    category: queryParams.get('category'),
  })
  const text = queryParams.get('text')
  const { user } = useUser()

  useEffect(() => {
    toggleLoading(true)
    refetch(elasticQuery(text, null, language))
  }, [text])

  useEffect(() => {
    toggleLoading(true)
    refetch(elasticQuery(filters.text, filters.category, language))
  }, [filters])

  useEffect(() => {
    if (total !== 0 && !isInitialLoading) {
      toggleLoading(false)
    }
  }, [items, isInitialLoading])

  if (w < 481) {
    return (
      <MobileSearch
        filters={filters}
        setFilters={setFilters}
        projects={items}
      />
    )
  }

  return (
    <AllProductsWrapper type="both" filters={filters} setFilters={setFilters}>
      {isLoading ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : items.length > 0 ? (
        <SimpleGrid
          position="relative"
          minH="720px"
          pt="12"
          pb="20"
          w="full"
          columns={{
            base: 1,
            md: 2,
            lg: 3,
            xl: 4,
          }}
          spacing="30px"
        >
          {items.map((card) => (
            <GridItem key={`fundraiser-card-${card.id}`} colSpan={1}>
              {card.sortOrder}
              <ProjectCard
                card={card}
                goalPrice={card.activeGoal?.amount || card.goal}
                raisedMoney={thousandSeparator(
                  convert({
                    amount: card.amount,
                    currencyRate: settings?.currency[currency.current],
                  }),
                  currency.current
                )}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? card.amount + Number(card.updateAmount)
                    : card.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(card.updateAmount),
                  currency.current
                )}
                edit={user.isAdmin}
                type={card.isProject ? 'fundraisers' : 'collaborations'}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    card.campaignImplementorPeriod_en,
                  fundraisingType: card.fundraisingType,
                })}
                language={language}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                cardStatusText={t(`status@${card.status}`)}
                cardCategoryText={t(`category@${card.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
                collaborationProposalsText={t(
                  card?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
              />
            </GridItem>
          ))}
        </SimpleGrid>
      ) : (
        <Flex
          minH="520px"
          alignItems="center"
          justifyContent="center"
          h="full"
          w="full"
        >
          <Heading as="h2" fontSize="5xl" color="blue.400">
            {t('noResults')}
          </Heading>
        </Flex>
      )}
    </AllProductsWrapper>
  )
}

const elasticQuery = (text, category, language = 'en') => {
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY[language],
    },
  }
  const searchingText = text?.toLowerCase()

  const must = [
    {
      match: {
        isTestProject: false,
      },
    },
    {
      match: {
        isHidden: false,
      },
    },
    {
      terms: {
        status: GENERAL_USER_VISIBLE_STATUSES,
      },
    },
    categoryTerms,
  ]

  if (searchingText) {
    must.push({
      multi_match: {
        query: searchingText,
        type: 'phrase_prefix',
        fields: [
          'region',
          'category',
          'title_en',
          'title_hy',
          'title_ru',
          'creatorName_en',
          'creatorName_hy',
          'creatorName_ru',
          'ownerName',
          'ownerLastName',
          'tagline_en',
          'tagline_hy',
          'tagline_ru',
          'content_en',
          'content_hy',
          'content_ru',
        ],
      },
    })
  }

  return {
    size: 40,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `int sortOrder = 0;       
            if (doc['status.keyword'].value == 'live') {sortOrder =  1}
            else if (doc['status.keyword'].value == 'launching_soon') {sortOrder =  2}
            else if (doc['status.keyword'].value == 'ended') {sortOrder =  3}
            else if (doc['status.keyword'].value == 'implemented') {sortOrder =  4}
            else if (doc['status.keyword'].value == 'closed') {sortOrder =  5}
            else if (doc['status.keyword'].value == 'terminated') {sortOrder =  6}
            sortOrder;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
            if (doc['status.keyword'].value == 'live') {
              if(doc['publishedAt'].size()==0) {return 10}
              return doc['publishedAt'].value.getMillis()}     
             return 0;`,
          },
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must,
      },
    },
  }
}

const withProjects =
  (Component) =>
  ({ ...props }) => {
    const queryParams = useQueryParams()
    const { language } = useTranslation()
    const { projects, isLoading, filters, setFilters } = useProjects(
      elasticQuery(queryParams.get('text'), null, language)
    )

    const refetch = (newFilters = elasticQuery(queryParams.get('text'))) => {
      setFilters((filters) => {
        return {
          ...filters,
          ...newFilters,
        }
      })
    }

    return (
      <Component
        {...props}
        isRefetchResponse={filters.nextToken ? true : false}
        items={projects?.items || []}
        total={projects?.total || 0}
        nextToken={projects?.nextToken}
        isLoading={isLoading}
        refetch={refetch}
      />
    )
  }

export default withProjects(Search)
