/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToProjectNewsletter = /* GraphQL */ `
  mutation SubscribeToProjectNewsletter(
    $input: SubscribeToProjectNewsletterInput
  ) {
    subscribeToProjectNewsletter(input: $input)
  }
`
export const sendCosmoEmails = /* GraphQL */ `
  mutation SendCosmoEmails($input: SendCosmoEmailsInput) {
    sendCosmoEmails(input: $input)
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      cosmoId
      customerId
      expertId
      email
      seenSubscribeModal
      firstName
      lastName
      phone
      country
      currency
      companyName
      city
      postalCode
      monetoryDonor
      aboutMe
      language
      shortDescription
      youtubeLink
      facebookLink
      linkedinLink
      websiteLink
      otherLinks
      creditCards {
        bindingId
        pan
        cardholderName
        expiration
        type
        isDefault
        __typename
      }
      isSeenExpertPopup
      unreadNotifications
      isReArmeniaInvestor
      isInitiator
      isFundraiserCreator
      isGoodraiserCreator
      investmentOneTimeTotal
      investmentRecurringTotal
      notificationSettings
      totalOneTimeDonation
      totalRecurringDonation
      selectedCategories
      selectedLocations
      stripeAttachedCards {
        id
        type
        last4
        __typename
      }
      isSeenExpertProfileNotification
      isSeenExpertProfileModal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      body
      userId
      language
      isRead
      image
      link
      createdAt
      updatedAt
      id
      __typename
    }
  }
`
export const createGiftCard = /* GraphQL */ `
  mutation CreateGiftCard(
    $input: CreateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    createGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateGiftCard = /* GraphQL */ `
  mutation UpdateGiftCard(
    $input: UpdateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    updateGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteGiftCard = /* GraphQL */ `
  mutation DeleteGiftCard(
    $input: DeleteGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    deleteGiftCard(input: $input, condition: $condition) {
      id
      userId
      code
      amount
      from
      message
      toEmail
      image
      currency
      currencyRate
      paymentStatus
      language
      fromEmail
      used
      usedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createLambdaInvocation = /* GraphQL */ `
  mutation CreateLambdaInvocation(
    $input: CreateLambdaInvocationInput!
    $condition: ModelLambdaInvocationConditionInput
  ) {
    createLambdaInvocation(input: $input, condition: $condition) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateLambdaInvocation = /* GraphQL */ `
  mutation UpdateLambdaInvocation(
    $input: UpdateLambdaInvocationInput!
    $condition: ModelLambdaInvocationConditionInput
  ) {
    updateLambdaInvocation(input: $input, condition: $condition) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteLambdaInvocation = /* GraphQL */ `
  mutation DeleteLambdaInvocation(
    $input: DeleteLambdaInvocationInput!
    $condition: ModelLambdaInvocationConditionInput
  ) {
    deleteLambdaInvocation(input: $input, condition: $condition) {
      id
      functionName
      awsRequestId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createSubscriptionCharge = /* GraphQL */ `
  mutation CreateSubscriptionCharge(
    $input: CreateSubscriptionChargeInput!
    $condition: ModelSubscriptionChargeConditionInput
  ) {
    createSubscriptionCharge(input: $input, condition: $condition) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateSubscriptionCharge = /* GraphQL */ `
  mutation UpdateSubscriptionCharge(
    $input: UpdateSubscriptionChargeInput!
    $condition: ModelSubscriptionChargeConditionInput
  ) {
    updateSubscriptionCharge(input: $input, condition: $condition) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteSubscriptionCharge = /* GraphQL */ `
  mutation DeleteSubscriptionCharge(
    $input: DeleteSubscriptionChargeInput!
    $condition: ModelSubscriptionChargeConditionInput
  ) {
    deleteSubscriptionCharge(input: $input, condition: $condition) {
      id
      subscriptionId
      subscriptionIdDate
      isFailedAttempt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createDonationSubscription = /* GraphQL */ `
  mutation CreateDonationSubscription(
    $input: CreateDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    createDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      currencyRate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateDonationSubscription = /* GraphQL */ `
  mutation UpdateDonationSubscription(
    $input: UpdateDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    updateDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      currencyRate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteDonationSubscription = /* GraphQL */ `
  mutation DeleteDonationSubscription(
    $input: DeleteDonationSubscriptionInput!
    $condition: ModelDonationSubscriptionConditionInput
  ) {
    deleteDonationSubscription(input: $input, condition: $condition) {
      id
      projectId
      peerProjectId
      bindingId
      userId
      userEmail
      firstName
      image
      lastName
      donationToReArmenia
      projectTitle
      isAnonymous
      isInfoAnonymous
      donationToProject
      unsubscribedDate
      month
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      day
      stripeData
      currency
      language
      paymentMethod
      currencyRate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createFailedMonthlyAttempts = /* GraphQL */ `
  mutation CreateFailedMonthlyAttempts(
    $input: CreateFailedMonthlyAttemptsInput!
    $condition: ModelFailedMonthlyAttemptsConditionInput
  ) {
    createFailedMonthlyAttempts(input: $input, condition: $condition) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateFailedMonthlyAttempts = /* GraphQL */ `
  mutation UpdateFailedMonthlyAttempts(
    $input: UpdateFailedMonthlyAttemptsInput!
    $condition: ModelFailedMonthlyAttemptsConditionInput
  ) {
    updateFailedMonthlyAttempts(input: $input, condition: $condition) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteFailedMonthlyAttempts = /* GraphQL */ `
  mutation DeleteFailedMonthlyAttempts(
    $input: DeleteFailedMonthlyAttemptsInput!
    $condition: ModelFailedMonthlyAttemptsConditionInput
  ) {
    deleteFailedMonthlyAttempts(input: $input, condition: $condition) {
      id
      projectId
      userId
      email
      firstName
      lastName
      amount
      subscriptionId
      doneAttempts
      itRetryCanceled
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createBindingCard = /* GraphQL */ `
  mutation CreateBindingCard(
    $input: CreateBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    createBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateBindingCard = /* GraphQL */ `
  mutation UpdateBindingCard(
    $input: UpdateBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    updateBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteBindingCard = /* GraphQL */ `
  mutation DeleteBindingCard(
    $input: DeleteBindingCardInput!
    $condition: ModelBindingCardConditionInput
  ) {
    deleteBindingCard(input: $input, condition: $condition) {
      id
      userEmail
      bindingId
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      userId
      creditCard {
        pan
        type
        stripePaymentMethod
        __typename
      }
      giftCardId
      transferredToId
      transferredFromId
      giftCardCode
      giftCardTransactionType
      email
      firstName
      lastName
      peerProjectId
      projectId
      projectTitle_en
      projectTitle_hy
      projectTitle_ru
      projectImage
      donationToReArmenia
      donationToProject
      giftCardAmount
      status
      orderStatus
      statusCode
      statusDescription
      amount
      orderId
      subscriptionId
      donationsCountWithEmail
      currency
      currencyRate
      matchedAmount
      matchedBy
      language
      isValid
      isManual
      isEdit
      isAnonymous
      isMonthlyCharge
      isStripe
      isInfoAnonymous
      projectClosedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createUpdate = /* GraphQL */ `
  mutation CreateUpdate(
    $input: CreateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    createUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateUpdate = /* GraphQL */ `
  mutation UpdateUpdate(
    $input: UpdateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    updateUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteUpdate = /* GraphQL */ `
  mutation DeleteUpdate(
    $input: DeleteUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    deleteUpdate(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createFeed = /* GraphQL */ `
  mutation CreateFeed(
    $input: CreateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    createFeed(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed(
    $input: UpdateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    updateFeed(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed(
    $input: DeleteFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    deleteFeed(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      userId
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorImage
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      type
      projectId
      data_en
      data_ru
      data_hy
      hasImage
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      peerProjectId
      occasion
      slug
      budgetFiles
      applicationTitle
      oldCollaborationId
      sequence
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      campaignImplementorPeriod_en
      campaignImplementorPeriod_ru
      campaignImplementorPeriod_hy
      owner
      ownerName
      ownerLastName
      ownerEmail
      region
      summary
      isSubmittedByLegalEntity
      organizationName
      organizationLegalForm
      problem
      solution
      duration
      durationType
      fundraisingType
      isInKind
      isFeatured
      isUrgent
      isHidden
      isPopular
      isTestProject
      isIndividual
      isLive
      isProject
      isCollaboration
      isOneTimeDisabled
      isCompany
      noCTA
      popular
      featured
      video_en
      video_ru
      video_hy
      creatorName_en
      creatorName_ru
      creatorName_hy
      creatorSocialLink
      amount
      goal
      image
      files
      total {
        oneTime
        monthlyOneTime
        subscriptions
        subscriptionsTransactions
        giftCard
        total
        goalMetPercentage
        __typename
      }
      totalAmount
      budgetBreakdown
      contactFullName
      contactEmail
      phoneNumber
      similarProjects
      partnership
      projectTeam
      otherDetails
      links
      creatorImage
      cover
      metaImage
      metaImage_ru
      metaImage_hy
      metaImage_en
      TIN
      category
      status
      language
      perks {
        id
        title
        title_en
        title_hy
        title_ru
        description_en
        description_hy
        description_ru
        price
        image
        __typename
      }
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      content_en
      content_ru
      content_hy
      support
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      implementor {
        id
        email
        fullName
        __typename
      }
      collaborators {
        id
        email
        fullName
        __typename
      }
      collaboratorsTotal
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      phases
      beneficiaries
      risks
      objective
      keyResults
      topDonors {
        email
        fullName
        totalAmount
        __typename
      }
      fbPixel
      stripeId
      isStripeOnBoardingDone
      otherPlayers
      startDate
      endDate
      statusUpdatedAt
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createProjectSystem = /* GraphQL */ `
  mutation CreateProjectSystem(
    $input: CreateProjectSystemInput!
    $condition: ModelProjectSystemConditionInput
  ) {
    createProjectSystem(input: $input, condition: $condition) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateProjectSystem = /* GraphQL */ `
  mutation UpdateProjectSystem(
    $input: UpdateProjectSystemInput!
    $condition: ModelProjectSystemConditionInput
  ) {
    updateProjectSystem(input: $input, condition: $condition) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteProjectSystem = /* GraphQL */ `
  mutation DeleteProjectSystem(
    $input: DeleteProjectSystemInput!
    $condition: ModelProjectSystemConditionInput
  ) {
    deleteProjectSystem(input: $input, condition: $condition) {
      id
      projectId
      userId
      mailerLiteApiKey
      iDramApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createDraft = /* GraphQL */ `
  mutation CreateDraft(
    $input: CreateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    createDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft(
    $input: UpdateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    updateDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft(
    $input: DeleteDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    deleteDraft(input: $input, condition: $condition) {
      id
      budgetFiles
      applicationTitle
      owner
      ownerName
      ownerLastName
      region
      summary
      isSubmittedByLegalEntity
      creatorSocialLink
      organizationName
      organizationLegalForm
      problem
      solution
      phases
      beneficiaries
      risks
      objective
      keyResults
      isOneTimeDisabled
      otherPlayers
      phoneNumber
      occasion
      creatorName_hy
      creatorName_ru
      creatorName_en
      peerProjectId
      files
      fundraisingType
      isInKind
      category
      projectTeam
      requestedAmount
      contactFullName
      email
      phone
      links
      terms_and_conditions
      privacy_policy
      collaborations {
        image
        needs
        needs_en
        needs_hy
        needs_ru
        needsDescription
        needsDescription_en
        needsDescription_hy
        needsDescription_ru
        __typename
      }
      goals {
        amount
        description_en
        description_ru
        description_hy
        __typename
      }
      isProject
      isCollaboration
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      domain
      amount
      period
      from
      to
      projectIds
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createInitiative = /* GraphQL */ `
  mutation CreateInitiative(
    $input: CreateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    createInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateInitiative = /* GraphQL */ `
  mutation UpdateInitiative(
    $input: UpdateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    updateInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteInitiative = /* GraphQL */ `
  mutation DeleteInitiative(
    $input: DeleteInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    deleteInitiative(input: $input, condition: $condition) {
      id
      slug
      owner
      ownerName
      ownerLastName
      contactEmail
      implementor {
        id
        email
        fullName
        __typename
      }
      region
      other
      applicationTitle
      about
      support
      partnership
      fullName
      phoneNumber
      image
      organizationName
      organizationLegalForm
      description
      TIN
      links
      summary
      files
      isFeatured
      isPopular
      isUrgent
      category
      collaborators {
        id
        email
        fullName
        __typename
      }
      status
      cover
      requiredSupport
      title_en
      title_ru
      title_hy
      tagline_en
      tagline_ru
      tagline_hy
      embedVideo_en
      embedVideo_ru
      embedVideo_hy
      content_en
      content_ru
      content_hy
      initiatorImage
      initiatorName_en
      initiatorName_ru
      initiatorName_hy
      updates {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      updates_en
      updates_ru
      updates_hy
      reports {
        id
        data_en
        data_ru
        data_hy
        createdAt
        updatedAt
        __typename
      }
      reports_en
      reports_ru
      reports_hy
      collaboratorsTotal
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      pk
      sk
      sequence
      jsonPageData
      title_en
      title_ru
      title_hy
      type
      image
      cv
      keywords
      firstName_en
      firstName_hy
      firstName_ru
      lastName_en
      lastName_hy
      lastName_ru
      isPrivate
      isVolunteer
      description_en
      description_hy
      description_ru
      spheres {
        title_en
        title_hy
        title_ru
        __typename
      }
      socialLinks
      interestedIn {
        interested_in
        __typename
      }
      getInvolvedStatus
      hrsPerWeek
      selectedLanguage {
        language
        language_other
        __typename
      }
      isEngFilled
      isArmFilled
      isRusFilled
      additionalInfo
      expert_status
      email
      slug
      phone
      imageUrl
      linkedinLink
      link
      location_en
      location_ru
      location_hy
      language
      countryCode
      userId
      publishedAt
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createExpertInitiatorConnection = /* GraphQL */ `
  mutation CreateExpertInitiatorConnection(
    $input: CreateExpertInitiatorConnectionInput!
    $condition: ModelExpertInitiatorConnectionConditionInput
  ) {
    createExpertInitiatorConnection(input: $input, condition: $condition) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateExpertInitiatorConnection = /* GraphQL */ `
  mutation UpdateExpertInitiatorConnection(
    $input: UpdateExpertInitiatorConnectionInput!
    $condition: ModelExpertInitiatorConnectionConditionInput
  ) {
    updateExpertInitiatorConnection(input: $input, condition: $condition) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteExpertInitiatorConnection = /* GraphQL */ `
  mutation DeleteExpertInitiatorConnection(
    $input: DeleteExpertInitiatorConnectionInput!
    $condition: ModelExpertInitiatorConnectionConditionInput
  ) {
    deleteExpertInitiatorConnection(input: $input, condition: $condition) {
      id
      adminStatus
      expertStatus
      initiatorFirstName
      initiatorLastName
      initiatorEmail
      initiatorPhone
      initiatorLink
      initiatorMessage
      initiatorSupport
      projectTitle
      expertFirstName
      expertLastName
      expertEmail
      expertPhone
      expertLinkedinLink
      expertLink
      expertImageUrl
      expertTitle
      expertMessage
      expertType
      expertHours
      expertSpheres
      expertDirections
      expertOccupation
      expertLanguage
      connectionStatus
      files
      language
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      currency
      version
      bigQueryLastSyncDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      currency
      version
      bigQueryLastSyncDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      currency
      version
      bigQueryLastSyncDate
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createNeeds = /* GraphQL */ `
  mutation CreateNeeds(
    $input: CreateNeedsInput!
    $condition: ModelNeedsConditionInput
  ) {
    createNeeds(input: $input, condition: $condition) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateNeeds = /* GraphQL */ `
  mutation UpdateNeeds(
    $input: UpdateNeedsInput!
    $condition: ModelNeedsConditionInput
  ) {
    updateNeeds(input: $input, condition: $condition) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteNeeds = /* GraphQL */ `
  mutation DeleteNeeds(
    $input: DeleteNeedsInput!
    $condition: ModelNeedsConditionInput
  ) {
    deleteNeeds(input: $input, condition: $condition) {
      id
      title_en
      title_ru
      title_hy
      type
      image
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createMailerLiteFields = /* GraphQL */ `
  mutation CreateMailerLiteFields($input: CreateMailerLiteFieldsInput) {
    createMailerLiteFields(input: $input)
  }
`
