import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import ImageWrapper from 'components/ImageWrapper'
import { ReactComponent as CreditCard } from 'assets/icons/credit-card.svg'
import { PAYMENT_METHODS_DATA } from 'pages/Donation/constants'
import { getImageUrl } from 'pages/SelectGiftCard/helpers'

export default function DonationCard({
  projectImage,
  projectTitle,
  projectId,
  peerProjectId,
  amount,
  formattedDate,
  creditCard,
  giftCardTransactionType,
  t,
  navigationPush,
}) {
  return (
    <Flex
      p={4}
      direction="column"
      w={{ base: 'full', sm: '417px' }}
      bg="white"
      borderRadius={8}
      boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 16px 6px 0px rgba(0, 0, 0, 0.01), 0px 25px 7px 0px rgba(0, 0, 0, 0)"
      mt={4}
    >
      <Flex alignItems="center">
        <Box
          w="50px"
          h="50px"
          flexShrink={0}
          onClick={() =>
            projectId === 'giftCard'
              ? navigationPush('/select-gift-card')
              : navigationPush(`/fundraisers/${peerProjectId || projectId}`)
          }
        >
          {projectImage ? (
            <>
              {giftCardTransactionType === 'buy' ? (
                <Image
                  borderRadius="50%"
                  cursor="pointer"
                  objectFit="cover"
                  width="50px"
                  bgColor="border.100"
                  h="50px"
                  ignoreFallback={true}
                  src={getImageUrl(projectImage)}
                />
              ) : (
                <ImageWrapper
                  borderRadius="50%"
                  cursor="pointer"
                  objectFit="cover"
                  width="50px"
                  bgColor="border.100"
                  h="50px"
                  ignoreFallback={true}
                  src={`/thumbnail/small/${projectImage}`}
                />
              )}
            </>
          ) : (
            <Image
              cursor="pointer"
              objectFit="cover"
              borderRadius="50%"
              bgColor="border.100"
              ignoreFallback={true}
              src="/assets/images/noImg.svg"
            />
          )}
        </Box>

        <Text
          ml={3}
          fontSize="xl"
          fontWeight="semibold"
          cursor="pointer"
          onClick={() =>
            projectId === 'giftCard'
              ? navigationPush('/select-gift-card')
              : navigationPush(`/fundraisers/${peerProjectId || projectId}`)
          }
        >
          {projectTitle ? projectTitle : 'Project Name'}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" h={7} mb={1} alignItems="center">
        <Text fontWeight={500} fontSize="md" color="gray.700">
          {t('profile@dashboard@donor@oneTime@amount')}
        </Text>
        <Text fontWeight={500} fontSize="md" color="gray.800">
          {amount}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" h={7} mb={1} alignItems="center">
        <Text fontWeight={500} fontSize="md" color="gray.700">
          {t('profile@dashboard@donor@oneTime@date')}
        </Text>
        <Text fontWeight={500} fontSize="md" color="gray.800">
          {formattedDate}
        </Text>
      </Flex>
      {creditCard && (
        <Flex justifyContent="space-between" h={7} mb={1} alignItems="center">
          <Text fontWeight={500} fontSize="md" color="gray.700">
            {t('profile@dashboard@donor@oneTime@paidWith')}
          </Text>
          <Text fontWeight={500} fontSize="md" color="gray.800">
            {creditCard?.pan}
            {['GOOGLE_PAY', 'APPLE_PAY', 'MIR'].includes(creditCard.type) ? (
              <Image
                src={PAYMENT_METHODS_DATA[creditCard.type].image}
                w="28px"
                h="20px"
                ml={2}
                float="right"
              />
            ) : isSupportedType(creditCard?.type) ? (
              <Image
                src={`/assets/images/${creditCard?.type}.png`}
                w="28px"
                h="20px"
                ml={2}
                float="right"
              />
            ) : (
              <CreditCard
                style={{
                  width: '28px',
                  height: '20px',
                  float: 'right',
                  marginLeft: '4px',
                }}
              />
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const isSupportedType = (type) =>
  ['master', 'mastercard', 'visa'].includes(type.toLowerCase())
