const userUseCase = (UserService, authRepo) => {
  const getUser = async () => {
    const USER_CACHE_KEY = 'rearmenia_user'
    const cognitoUser = await authRepo.getCognitoCurrentUser()
    const user = await UserService.getUser(cognitoUser)
    if (user) {
      localStorage.setItem(USER_CACHE_KEY, JSON.stringify(user))
    }
    return user
  }

  const getUserById = async (id) => UserService.getUserById(id)

  const getUserByEmail = async (email) => UserService.getUserByEmail(email)

  const editEmail = async (email) => UserService.editEmail(email)

  const verifyEmail = async (code) => UserService.verifyEmail(code)

  const editUserInfo = async (userInfo) => UserService.editUserInfo(userInfo)

  const changePassword = async (passwords) =>
    UserService.changePassword(passwords)

  const signOut = async () => UserService.signOut()

  const updateUser = async (input) => UserService.updateUser(input)

  const getCompany = async (input) => {
    if (!input) {
      return null
    }
    const res = await UserService.getCompany(input)

    if (!res) {
      return null
    }

    return res.data.companyByDomain.items[0]
  }

  const onUserUpdateSubscription = (input) =>
    UserService.onUserUpdateSubscription(input)

  return {
    getUser,
    getUserById,
    getUserByEmail,
    editEmail,
    verifyEmail,
    editUserInfo,
    changePassword,
    signOut,
    updateUser,
    getCompany,
    onUserUpdateSubscription,
  }
}

export default userUseCase
