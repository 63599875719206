import { Flex, Text } from '@chakra-ui/react'
import { InputField } from 'components/InputField'

export default function PaymentGuestUserFormDonationPage({
  register,
  errors,
  isRecurring,
  isStripe,
  t,
  firstName = '',
  lastName = '',
  email = '',
  emailErrorMessage = '',
}) {
  return (
    <Flex direction="column" gap={2} mt={3}>
      <Flex direction={{ base: 'column', md: 'row' }}>
        <InputField
          size="sm"
          label={firstName + '*'}
          labelProps={{ marginBottom: 1.5, padding: 0 }}
          {...register('firstName', {
            required: true,
          })}
          borderRadius="8px"
          formControlProps={{
            margin: { base: '10px 0', sm: '0 10px 0 0' },
          }}
          maxLength={50}
        />
        <InputField
          size="sm"
          label={lastName + '*'}
          labelProps={{ marginBottom: 1.5, padding: 0 }}
          {...register('lastName', {
            required: true,
          })}
          borderRadius="8px"
          maxLength={50}
        />
      </Flex>
      <InputField
        size="sm"
        label={email + '*'}
        labelProps={{ marginBottom: 1.5, padding: 0 }}
        errorMessage={emailErrorMessage}
        {...register('email', {
          required: true,
          pattern: {
            message: 'createEditInitiative@emailError',
            value: /\S+@\S+\.\S+/,
          },
        })}
        borderRadius="8px"
      />
      {!isStripe && isRecurring && (
        <Text fontSize="md">
          <b>{t('donation@cardWillBeSaved').split('(bold)')[0]}</b>
          {t('donation@cardWillBeSaved').split('(bold)')[1]}
        </Text>
      )}
    </Flex>
  )
}
