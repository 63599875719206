import { Box } from '@chakra-ui/react'
import { TransactionsProvider } from 'contexts'
import DonationList from 'pages/Project/DonationList'
import Donates from 'pages/Project/MobileProject/Donations'
import React from 'react'
const w = window.innerWidth
export default function DonorList({ project, ...rest }) {
  return (
    <TransactionsProvider project={project}>
      <Box {...rest}>
        {w > 481 ? (
          <DonationList project={project} donationPage w="full" />
        ) : (
          <Donates project={project} donationPage />
        )}
      </Box>
    </TransactionsProvider>
  )
}
