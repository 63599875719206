import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import COUNTRY_LIST from 'translations/countries.json'

//** Icons
import { ReactComponent as Pointer } from 'assets/icons/pointer.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SmallCheck } from 'assets/icons/small_check.svg'
import { ReactComponent as ArrowDown } from 'assets/icons/small_chevron_down.svg'
import { DisabledBox } from 'components/DisabledBox'

const w = window.innerWidth

const CountrySelect = ({
  t,
  language,
  setValue = () => {},
  watch,
  getValues,
  isInvalid,
  isDisabled,
}) => {
  const [search, setSearch] = useState()
  useEffect(() => {
    const country = watch('country')
    if (country && !getValues('phone')) {
      setValue('phone', country.dialCode)
    }
  }, [watch('country')])
  const menuItems = [
    {
      id: 0,
      code: '',
      name_en: 'Select location',
      name_hy: 'Ընտրել տարածք',
      name_ru: 'Выбрать местоположение',
      image: '',
      dialCode: '',
    },
    ...COUNTRY_LIST,
  ]
  const isSelected = (code) => watch('country')?.code === code

  return (
    <Box pos="relative">
      {isDisabled && <DisabledBox />}

      <Menu
        closeOnBlur={w > 481}
        placement={w > 481 ? 'bottom-start' : 'top-start'}
        isLazy
        variant="white"
      >
        {({ onClose, isOpen }) => (
          <>
            <Flex
              mt={0}
              _hover={{}}
              as={MenuButton}
              w="full"
              type="button"
              cursor="pointer"
              h={10}
              align="center"
              justify="center"
              p={2}
              border="1px solid"
              borderColor={isInvalid ? 'red.400' : 'border.400'}
              borderRadius={8}
              bg={isOpen ? '#EAECEF' : 'white'}
              sx={{
                '& > span': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}
            >
              <Flex>
                <Pointer />
                {watch('country')?.code ? (
                  <Flex align="center">
                    <Text ml={3} fontSize="md" color="gray.800">
                      {watch('country')?.[`name_${language}`]}
                    </Text>
                    <Image
                      src={`/assets/flags/${watch('country').image}`}
                      ml={1}
                      w="16px"
                    />
                  </Flex>
                ) : (
                  <Text ml={3} fontSize="md" color="gray.800">
                    {t('expert@form@country', language)}
                  </Text>
                )}
              </Flex>
              <ArrowDown />
            </Flex>
            <MenuList
              w="277px"
              bg="white"
              border="1px solid"
              borderColor="border.400"
              px={4}
              pt={4}
              pb={0}
              borderRadius={8}
            >
              <Box borderTopRadius={16}>
                <InputGroup>
                  {search?.length && (
                    <InputRightElement
                      children={
                        <IconButton
                          onClick={() => {
                            setSearch('')
                          }}
                        >
                          <CloseIcon width="10px" fill="gray.700" />
                        </IconButton>
                      }
                    />
                  )}
                  <Input
                    background="white"
                    border="1px solid"
                    borderColor="blue.400"
                    placeholder={t(
                      'profile@settings-profile@selectCountry',
                      language
                    )}
                    autoFocus={w > 481}
                    color="black"
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    value={search}
                    _placeholder={{
                      color: 'gray.600',
                      fontSize: 'md',
                    }}
                    mx="auto"
                  />
                </InputGroup>
              </Box>
              <Stack
                mt={4}
                w={{ base: 'full', sm: 'calc(100% + 16px)' }}
                maxH={{ base: '422px', sm: '330px' }}
                borderBottomRadius={16}
                overflowY="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: `gray.400`,
                    marginRight: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `gray.600`,
                    borderRadius: 4,
                  },
                }}
              >
                <>
                  {menuItems
                    ?.filter((f) =>
                      search
                        ? f?.[`name_${language}`]
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase())
                        : true
                    )
                    ?.map((country) => (
                      <Flex
                        cursor="pointer"
                        key={country.id}
                        alignItems="center"
                        _hover={{ sm: { bg: 'gray.300' }, base: {} }}
                        color="gray.800"
                        bg="white"
                        minH={8}
                        onClick={() => {
                          setValue('country', country)
                          setValue('phone', country.dialCode)
                          onClose()
                        }}
                      >
                        {isSelected(country.code) && (
                          <SmallCheck style={{ margin: '0 8px' }} />
                        )}
                        <Text pl={isSelected(country.code) ? 0 : '30px'}>
                          {country[`name_${language}`]}
                        </Text>
                      </Flex>
                    ))}
                </>
              </Stack>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

export default CountrySelect
