import moment from 'moment'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => `
      <img
        src="https://${node.data.target.fields.file.url}"
        height={node.data.target.fields.file.details.image.height}
        width={node.data.target.fields.file.details.image.width}
        alt={node.data.target.fields.description}
      />
    `,
  },
}

const filterLang = (items, lang) =>
  items.filter((item) => item.metadata.tags[0].sys.id === lang)

const generateContent = (items) =>
  items.map((item) => {
    const images =
      item.fields.images?.map(
        (image) => image?.fields?.file?.url && `https:${image.fields.file.url}`
      ) || []
    return {
      id: item.sys.id,
      title: item.fields.title,
      slug: item.fields.slug,
      description: item.fields.description,
      story: documentToHtmlString(item.fields.story, renderOptions),
      date: moment(item.fields.date).format('ll'),
      images,
      image: images[0],
    }
  })

const successStoryUseCase = (successStoryRepo) => {
  const listSuccessStories = async () => {
    const response = await successStoryRepo.listSuccessStories()

    const result = {
      skip: response.skip,
      total: response.total,
      limit: response.limit,
      items_en: generateContent(filterLang(response.items, 'en')),
      items_ru: generateContent(filterLang(response.items, 'ru')),
      items_hy: generateContent(filterLang(response.items, 'hy')),
    }

    return result
  }

  const getSuccessStory = async (slug, language) => {
    const response = await successStoryRepo.getSuccessStory(slug)
    let result = null
    if (response.items?.length > 0) {
      result = generateContent(filterLang(response.items, language))[0]
    }

    return result
  }

  return {
    listSuccessStories,
    getSuccessStory,
  }
}

export default successStoryUseCase
