import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import AmountWithCurrency from 'components/AmountWithCurrency'
import ImageWrapper from 'components/ImageWrapper'
import LineClamp from 'components/LineClamp'
import TransactionBadge from 'components/TransactionBadge'
import moment from 'moment'
import { Link } from 'pages'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
const Loader = () => (
  <Flex
    w="full"
    margin="auto"
    align="center"
    justifyContent="center"
    position="absolute"
    bottom="-20px"
  >
    <Spinner color="blue.400" />
  </Flex>
)
export default function List({
  hasMore,
  onLoadNext,
  transactions,
  lang = 'en',
  t,
}) {
  if (!transactions) {
    return null
  }

  return (
    <InfiniteScroll
      dataLength={transactions?.length}
      next={onLoadNext}
      hasMore={hasMore}
      loader={<Loader />}
    >
      <Box width="full">
        {transactions
          .filter((item) => item.status === 'DONE')
          .map((item) => (
            <ListItem
              key={item.id}
              id={item.id}
              image={item.projectImage}
              title={item[`projectTitle_${lang}`] || item.projectId}
              donationToProject={item.donationToProject}
              donationToReArmenia={item.donationToReArmenia}
              date={item.createdAt}
              currency={item.currency}
              currencyRate={item.currencyRate}
              projectId={item.projectId}
              subscriptionId={item.subscriptionId}
              t={t}
            />
          ))}
      </Box>
    </InfiniteScroll>
  )
}
const w = window.innerWidth
const ListItem = ({
  id,
  image,
  title,
  donationToReArmenia,
  donationToProject,
  date,
  currency = 'AMD',
  currencyRate = 1,
  projectId,
  subscriptionId,
  t,
}) => (
  <Flex
    borderBottomWidth={1}
    borderColor="border.100"
    pb="4"
    mb="4"
    width="full"
    justifyContent="space-between"
    alignItems="center"
  >
    <Link
      to={
        projectId === 'rearmenia'
          ? '/fundraisers?type=oneTime'
          : `/fundraisers/${projectId}`
      }
    >
      <ImageWrapper
        src={`/thumbnail/small/${image}`}
        ignoreFallback={false}
        alt="project cover"
        objectFit="cover"
        borderRadius={{ base: '12px', sm: '8px' }}
        width={{ base: '60px', sm: '80px' }}
        height={{ base: '60px', sm: '80px' }}
      />
    </Link>
    <Flex
      ml="4"
      flex="1"
      justifyContent="space-between"
      alignItems={{ base: 'start', sm: 'center' }}
      flexDir={{ base: 'column', sm: 'row' }}
    >
      <Box>
        {subscriptionId && subscriptionId !== 'NONE' && w > 481 && (
          <TransactionBadge
            title="monthly"
            width="max-content"
            ml="0"
            mb="1"
            color="white"
          />
        )}

        <Link
          to={
            projectId === 'rearmenia'
              ? '/fundraisers?type=oneTime'
              : `/fundraisers/${projectId}`
          }
        >
          <LineClamp
            Component={Text}
            fontWeight={600}
            color="gray.800"
            textAlign="left"
          >
            {title ? title : id}
          </LineClamp>
        </Link>
        <Flex alignItems="center">
          {subscriptionId && subscriptionId !== 'NONE' && w < 481 && (
            <TransactionBadge
              title="monthly"
              width="max-content"
              ml="0"
              my="0"
              mr="2"
            />
          )}
          <Text
            color="gray.700"
            fontWeight="500"
            fontSize={{ base: 'xs', sm: 'md' }}
          >
            {moment(date).format('DD.MM.YYYY')}
          </Text>
        </Flex>
      </Box>
      <Box textAlign={{ base: 'left', sm: 'right' }}>
        <Text
          color="gray.700"
          fontWeight="400"
          fontSize={{ base: 'xs', sm: 'sm' }}
        >
          {t('donation@yourDonation')}:{' '}
          <AmountWithCurrency
            amount={donationToProject}
            currency={currency || 'AMD'}
            currencyRate={currencyRate || 1}
          />
        </Text>
        {(projectId !== 'rearmenia' || donationToReArmenia !== 0) && (
          <Text
            color="gray.700"
            fontWeight="400"
            fontSize={{ base: 'xs', sm: 'sm' }}
          >
            {t('donation@reArmeniaTip')}:{' '}
            <AmountWithCurrency
              amount={donationToReArmenia}
              currency={currency || 'AMD'}
              currencyRate={currencyRate || 1}
            />
          </Text>
        )}

        <Text fontWeight="bold">
          {t('donation@total')}:{' '}
          <AmountWithCurrency
            amount={
              Number(donationToReArmenia || 0) + Number(donationToProject)
            }
            currency={currency || 'AMD'}
            currencyRate={currencyRate || 1}
          />
        </Text>
      </Box>
    </Flex>
  </Flex>
)
