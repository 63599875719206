import { useCustomToast, useUpdateProjectSystem } from 'hooks'
import ApplicationCard from 'components/ApplicationCard'
import { useTranslation } from 'contexts/TranslationContext'

export default function Idram({ projectSystem }) {
  const { mutate: updateProjectSystem, isLoading } = useUpdateProjectSystem()
  const toast = useCustomToast()
  const { t } = useTranslation()

  const onSubmit = ({ value }) => {
    const onSuccess = () => {
      toast({
        status: 'success',
        title: t('Successfully saved.'),
      })
    }

    updateProjectSystem(
      { id: projectSystem.id, iDramApiKey: value },
      { onSuccess }
    )
  }
  return (
    <ApplicationCard
      variant="variant1"
      logo="idram2.png"
      description="Give your community the option to donate through the IDram Payment System."
      heading="IDram"
      inputPlaceholder="SECRET KEY"
      buttonText="Submit"
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  )
}
