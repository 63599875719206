import {
  donationSubscriptionsByProjectId as donationSubscriptionsByUserProjectId,
  sortedDonationSubscriptionsByUserEmail as sortedDonationSubscriptionsByUserEmailQuery,
  getDonationSubscription as getDonationSubscriptionQuery,
} from '../../graphql/queries'
import { updateDonationSubscription as update } from '../../graphql/mutations'

const DonationSubscriptionService = (appSyncRequest) => {
  // console.log('>>> auth', auth)
  const updateDonationSubscription = (donationSubscription) =>
    appSyncRequest({
      query: update,
      variables: { input: donationSubscription },
    })

  const donationSubscriptionsByUserEmail = async (condition) => {
    const res = await appSyncRequest({
      query: sortedDonationSubscriptionsByUserEmailQuery,
      variables: condition,
    })

    return res.data.sortedDonationSubscriptionsByUserEmail
  }

  const donationSubscriptionsByProjectId = (condition) =>
    appSyncRequest({
      query: donationSubscriptionsByUserProjectId,
      variables: condition,
    })

  const getDonationSubscription = async (id) => {
    const res = await appSyncRequest({
      query: getDonationSubscriptionQuery,
      variables: { id },
    })
    return res.data.getDonationSubscription
  }

  return {
    updateDonationSubscription,
    donationSubscriptionsByUserEmail,
    donationSubscriptionsByProjectId,
    getDonationSubscription,
  }
}

export default DonationSubscriptionService
