import { useState, useRef, useEffect, useMemo } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { Box, AspectRatio, Flex, Container } from '@chakra-ui/react'
import Tabs from './Tabs'
import { Footer } from 'components/Footer'
import Information from './Information'
import { TransactionsProvider, DonationSubscriptionsProvider } from 'contexts'
import { useGetProject, useQueryParams, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import MobileProject from './MobileProject'
import { MainLayout } from 'components/Layouts'
import ImageWrapper from 'components/ImageWrapper'
import useCustomToast from 'hooks/useCustomToast'
import MetaTags from 'components/MetaTags'
import useGetUpdatesByProjectId from 'hooks/update/useGetProjectUpdates'
import { GENERAL_USER_VISIBLE_STATUSES } from '_constants'
import { useNavigation } from 'pages'
import { useGiftCard } from 'contexts/GiftCardContext'
import amplitude from 'amplitude-js'
import useGetReportsByProjectId from 'hooks/report/useGetProjectReports'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import FbPixel from 'components/FbPixel'
import fbPixel from 'fbPixel'

const Project = () => {
  const { navigationPush } = useNavigation()
  const { giftCardMode } = useGiftCard()
  const w = window.innerWidth
  const queryParams = useQueryParams()
  const { user } = useUser()
  const { projectId } = useParams()
  const [play, setPlay] = useState(false)
  const { data: project, isLoading } = useGetProject({
    projectId,
  })
  const { data: peerProject } = useGetProject({
    projectId: project?.peerProjectId,
  })

  const { t, language } = useTranslation()
  const toast = useCustomToast({})
  const metaImage = useMemo(() => {
    if (!project) {
      return `${process.env.REACT_APP_BASE_URL}/logo.png`
    }
    if (project[`metaImage_${language}`]) {
      return `${process.env.REACT_APP_S3_BUCKET_URL}/${
        project[`metaImage_${language}`]
      }`
    }

    if (project?.metaImage) {
      return `${process.env.REACT_APP_S3_BUCKET_URL}/${project.metaImage}`
    }
    if (project?.cover) {
      return `${process.env.REACT_APP_S3_BUCKET_URL}/public/thumbnail/medium/${project.cover}`
    }
    return `${process.env.REACT_APP_BASE_URL}/logo.png`
  }, [project])
  const infoSectionRef = useRef()
  const tabsSectionRef = useRef(null)

  useEffect(() => {
    if (queryParams.get('tab') === 'updates') {
      const tabs = tabsSectionRef.current
      if (tabs) {
        polyfill()
        scrollIntoView(tabs, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [tabsSectionRef.current])

  useEffect(() => {
    if (
      project?.['content_' + language]?.includes(
        `/donation?projectId=${project.id}`
      ) &&
      project?.status !== 'live'
    ) {
      project['content_' + language] = project?.[
        'content_' + language
      ].replaceAll(
        `/donation?projectId=${project.id}`,
        `/fundraisers/${project?.id}?tab=updates&error=isCompleted`
      )
    }
  }, [project])

  useEffect(() => {
    if (w > 481) {
      if (queryParams.get('tab') === 'story' && project?.isProject) {
        amplitude
          .getInstance()
          .logEvent('Web fundraisers details page story tab view')
        console.log(
          'sent event to amplitude: Web fundraisers details page story tab view'
        )
      }
      if (queryParams.get('tab') === 'updates' && project?.isProject) {
        amplitude
          .getInstance()
          .logEvent('Web fundraisers details page updates tab view')
        console.log(
          'sent event to amplitude: Web fundraisers details page updates tab view'
        )
      }
    }
    if (queryParams.get('error') === 'isCompleted') {
      toast({
        title: t('projectCompleted@toaster'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [queryParams.get('tab'), project])

  useEffect(() => {
    if (project?.fbPixel) {
      fbPixel.init(project?.fbPixel)
      fbPixel.pageView()
    }
  }, [project?.fbPixel])

  useEffect(() => {
    if (project?.fbPixel) {
      fbPixel.init(project?.fbPixel)
      fbPixel.pageView()
    }
  }, [project?.fbPixel])

  const {
    data: updates,
    isLoading: isUpdatesLoading,
    refetch: refetchUpdates,
  } = useGetUpdatesByProjectId({
    projectId: project?.id,
    sortDirection: 'DESC',
  })

  const {
    data: reports,
    isLoading: isReportsLoading,
    refetch: refetchReports,
  } = useGetReportsByProjectId({
    projectId: project?.id,
    sortDirection: 'DESC',
  })

  if (!isLoading && !project) {
    navigationPush('/404')
  }
  const url = play
    ? '?autoplay=1&controls=1&enablejsapi=1&rel=0'
    : '?controls=0'

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  const projectIds = useMemo(() => {
    if (peerProject?.id) {
      return {
        projectToDonateId: peerProject.id,
        peerProjectId: project?.id,
      }
    }

    return {
      projectToDonateId: project?.id,
    }
  }, [project, peerProject])

  if (!project) {
    return null
  }
  if (
    !GENERAL_USER_VISIBLE_STATUSES.includes(project.status) &&
    !user.isAdmin &&
    project.owner !== user.id
  ) {
    return <Redirect to={`/${language}/404`} />
  }

  if (w < 481) {
    return (
      <>
        <MetaTags
          title={`${project[`title_en`]} | MyCompany`}
          description={project[`tagline_en`] || project.summary}
          image={metaImage}
        />
        <DonationSubscriptionsProvider project={project}>
          <TransactionsProvider project={project}>
            <MobileProject
              tabsSectionRef={tabsSectionRef}
              giftCardMode={giftCardMode}
              play={play}
              updates={updates}
              isUpdatesLoading={isUpdatesLoading}
              reports={reports}
              isReportsLoading={isReportsLoading}
              setPlay={setPlay}
              getId={getId}
              url={url}
              project={project}
              projectId={projectId}
              isLoading={isLoading}
              peerProject={peerProject}
            />
          </TransactionsProvider>
        </DonationSubscriptionsProvider>
      </>
    )
  }
  return (
    <>
      <MetaTags
        title={`${project[`title_en`]} | MyCompany`}
        description={project[`tagline_en`] || project.summary}
        image={metaImage}
      />
      {project?.fbPixel && <FbPixel pixelId={project?.fbPixel} />}
      <DonationSubscriptionsProvider project={project}>
        <TransactionsProvider project={project}>
          {/* <GoalsProvider project={project}> */}
          <MainLayout>
            <Container maxW="7xl">
              {user.isAdmin && project.id}
              <Flex w="full" pb="9rem" justifyContent="space-between">
                <Box
                  minHeight="480px"
                  maxW="735px"
                  w="full"
                  mr={{ base: '0', lg: '5rem' }}
                >
                  <AspectRatio h="480px" w="100%" ratio={16 / 9}>
                    {project && project[`video_${language}`] ? (
                      play ? (
                        <Flex
                          height="100%"
                          width="100%"
                          zIndex="5"
                          allow="autoplay"
                          as="iframe"
                          title="Armenia"
                          allowFullScreen
                          src={`https://www.youtube.com/embed/${getId(
                            project[`video_${language}`]
                          )}${url}`}
                        />
                      ) : (
                        <Flex
                          bgImage={`url(https://img.youtube.com/vi/${getId(
                            project[`video_${language}`]
                          )}/0.jpg)`}
                          height="100%"
                          bgSize="cover"
                          bgPos="center"
                          bgRepeat="no-repeat"
                          width="100%"
                        >
                          <Box
                            cursor="pointer"
                            onClick={() => setPlay(true)}
                            p="5"
                            backgroundColor="rgba(255, 255, 255, .5)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="20px"
                            width="80px"
                            height="80px"
                          >
                            <Play width="30px" />
                          </Box>
                        </Flex>
                      )
                    ) : project?.cover ? (
                      <ImageWrapper
                        src={`/thumbnail/medium/${project.cover}`}
                        alt="Project"
                        style={{ objectFit: 'cover' }}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Box bg="border.100" width="100%" height="100%" />
                    )}
                  </AspectRatio>
                  <Box
                    display={{ base: 'block', lg: 'none' }}
                    flex="1"
                    px={5}
                    pt={12}
                  >
                    <Information
                      project={project}
                      peerProject={peerProject}
                      projectIds={projectIds}
                    />
                  </Box>
                  <Tabs
                    updates={updates}
                    isUpdatesLoading={isUpdatesLoading}
                    refetchUpdates={refetchUpdates}
                    reports={reports}
                    isReportsLoading={isReportsLoading}
                    refetchReports={refetchReports}
                    project={project}
                    peerProject={peerProject}
                    projectIds={projectIds}
                  />
                </Box>
                <Box
                  display={{ base: 'none', lg: 'block' }}
                  flex="1"
                  minW="450px"
                  maxW="464px"
                  pt="12"
                  ref={infoSectionRef}
                >
                  <Information
                    infoSectionRef={infoSectionRef}
                    project={project}
                    peerProject={peerProject}
                    projectIds={projectIds}
                  />
                </Box>
              </Flex>
            </Container>
            <Footer />
          </MainLayout>
          {/* </GoalsProvider> */}
        </TransactionsProvider>
      </DonationSubscriptionsProvider>
    </>
  )
}

export default Project
