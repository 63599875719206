import { useTranslation } from 'contexts/TranslationContext'
import React, { useEffect } from 'react'

function RedirectFallback({ fileName }) {
  const { language } = useTranslation()
  useEffect(() => {
    let redirectURL = `${process.env.REACT_APP_BASE_URL}/${fileName}`
    if (language === 'ru') {
      redirectURL += `-rs`
    }
    if (language === 'hy') {
      redirectURL += `-am`
    }
    redirectURL += '.html'
    window.location.replace(redirectURL)
  }, [])
  return <div></div>
}

const WithType =
  (fileName) =>
  ({ props }) =>
    <RedirectFallback fileName={fileName} {...props} />

export default WithType
