import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'

const ContactInfo = () => {
  const { t } = useTranslation()

  return (
    <Flex direction={{ base: 'column', lg: 'row' }}>
      <Flex w={{ base: '100%', lg: '50%' }} align="center" justify="center">
        <Image src="/assets/images/contactUsMap.svg" />
      </Flex>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        align="center"
        justify="center"
        direction="column"
        px={{ base: 5, lg: 0 }}
        py={{ base: 8, lg: 0 }}
      >
        <Box>
          <Flex direction="column">
            <Text
              fontSize={{ base: 'lg', sm: 'xl' }}
              href="mailto:my.university@gmail.com"
              as="a"
              fontWeight={700}
              mb={6}
            >
              {t('contactUs@form-emailAddressShort')}
              <br />
              <Text as="span" fontWeight={400}>
                my.company@gmail.com
              </Text>
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={{ base: 'lg', sm: 'xl' }} fontWeight={700} mb={6}>
              {t('contactUs@addressShort')}
              <br />
              <Text as="span" fontWeight={400}>
                1234 Maple Avenue Greenfield, Stateville 56789 United States
              </Text>
            </Text>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize={{ base: 'lg', sm: 'xl' }}
              fontWeight={700}
              as="a"
              href="tel:055958535"
              mb={6}
            >
              {t('Phone')}
              <br />
              <Text as="span" fontWeight={400}>
                +374 55 958 535
              </Text>
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ContactInfo
