import { Flex, Image, Text, Button, Radio } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { useUser } from 'hooks'
import HammerWrapper from './HammerWrapper'
import Remove from './Remove'

const w = window.innerWidth

const cardIcons = {
  visa: 'images/visa.png',
  master: 'images/master.png',
  mastercard: 'images/master.png',
  americanExpress: 'icons/american-express.svg',
  'american express': 'icons/american-express.svg',
  discover: 'images/discover.png',
  arca: 'images/arca.png',
  unknown: 'icons/credit-card.svg',
}

export default function CreditCardItem({
  isSelected,
  index,
  removable,
  makeDefault,
  creditCard,
  onClick,
  onCardClick,
  scheme = 'white',
}) {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <HammerWrapper creditCard={creditCard} removable={removable}>
      <Flex
        h="64px"
        p="22px"
        bgColor={schemeMap[scheme].bgColor}
        borderRadius={8}
        borderWidth="1px"
        borderColor={
          isSelected
            ? schemeMap[scheme].selectedBorderColor
            : schemeMap[scheme].borderColor
        }
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        cursor={(onClick || makeDefault) && 'pointer'}
        _hover={{
          'button[datatype="remove"]': {
            opacity: 1,
          },
        }}
        onClick={() => onCardClick(creditCard, index)}
      >
        <Flex alignItems="center">
          <Image
            src={`/assets/${cardIcons[creditCard.type]}`}
            w="28px"
            h="20px"
            objectFit="contain"
            alt={creditCard.type}
          />
          <Text ml={4} color="black.100" fontSize="md">
            {creditCard.pan} {t('savedCard')}
          </Text>
        </Flex>
        <Flex alignItems="center">
          {w > 481 && removable && <Remove creditCard={creditCard} />}
          {user.creditCards?.length > 1 && (
            <>
              {creditCard.isDefault && (
                <Text color="gray.700" fontSize="sm" fontWeight="500" mr="1">
                  {t('profile@settings-payments-default-selected')}
                </Text>
              )}
              {makeDefault && !creditCard.isDefault && (
                <Button
                  variant="transparent"
                  color="blue.300"
                  fontSize="sm"
                  fontWeight="500"
                  textTransform="lowercase"
                  mr="1"
                >
                  {t('profile@settings-payments-make-default')}
                </Button>
              )}
            </>
          )}
          {(user.creditCards?.length > 1 || !removable) && (
            <Radio value="1" isChecked={isSelected}></Radio>
          )}
        </Flex>
      </Flex>
    </HammerWrapper>
  )
}

const schemeMap = {
  white: {
    borderColor: 'border.500',
    selectedBorderColor: 'blue.400',
    bgColor: 'white',
  },
  gray: {
    borderColor: 'border.100',
    selectedBorderColor: 'blue.400',
    bgColor: 'gray.350',
  },
}
