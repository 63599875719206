import { useCustomToast, useUpdateProject } from 'hooks'
import ApplicationCard from 'components/ApplicationCard'
import { useTranslation } from 'contexts/TranslationContext'

export default function MailerLite({ project }) {
  const { updateProject, isLoading } = useUpdateProject()
  const toast = useCustomToast()
  const { t } = useTranslation()

  const onSubmit = ({ value }) => {
    const onSuccess = () => {
      toast({
        status: 'success',
        title: t('Successfully saved.'),
      })
    }

    updateProject({ id: project.id, fbPixel: value }, { onSuccess })
  }

  return (
    <ApplicationCard
      description="Measure, optimize, and build audiences for your ad campaigns."
      variant="variant1"
      logo="meta.png"
      heading="Meta Pixel"
      inputPlaceholder="Pixel base code"
      buttonText="Save"
      defaultValue={project?.fbPixel}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  )
}
