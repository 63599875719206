import {
  Box,
  Button,
  Stack,
  useColorModeValue as mode,
  Heading,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react'
import { useConfirmSignUp, useQueryParams, useUser } from 'hooks'
import { useNavigation } from 'pages'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'contexts/TranslationContext'
import { InputField } from 'components/InputField'
import { authUseCase } from 'core/factories'
import useCustomToast from 'hooks/useCustomToast'

const VerifySignUpModal = ({
  closeModal,
  redirectTo,
  email: emailFromProps,
  firstName: firstNameFromProps,
}) => {
  const {
    register: verify,
    handleSubmit: handleVerify,
    formState: { errors: verifyErrors },
  } = useForm()
  const queryParams = useQueryParams()
  // const { mutate: subscribeMailerLite } = useSubscribeNews()
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()
  const toast = useCustomToast()
  const firstName =
    firstNameFromProps ||
    localStorage.getItem('firstName') ||
    queryParams.get('firstName')
  const email =
    emailFromProps || localStorage.getItem('email') || queryParams.get('email')
  const { confirmSignUp, isLoading } = useConfirmSignUp()
  const { getUser } = useUser()

  const onResend = async () => {
    try {
      await authUseCase.resendSignUp(email)
      toast({
        status: 'success',
        title: t('verify@resend'),
        isClosable: true,
        duration: 5000,
      })
    } catch (e) {
      console.log(e)
      toast({
        status: 'error',
        title: t(`signUp@${e.name}`),
        isClosable: true,
        duration: 5000,
      })
    }
  }

  const onVerify = async (data) => {
    confirmSignUp(
      {
        code: data.code,
        email,
      },
      {
        onSuccess: async () => {
          await getUser()
          // subscribeMailerLite(
          //   {
          //     email,
          //     firstName,
          //     language,
          //   },
          //   {
          //     onError: (e) => console.log(e),
          //   }
          // )
          const redirectParams = {}
          if (firstName) {
            redirectParams.firstName = firstName
          }
          closeModal(redirectParams)

          if (!redirectTo) {
            navigationPush(`/signup/thank-you?firstName=${firstName}`)
          } else {
            navigationPush(redirectTo)
          }
        },
        onError: (err) => {
          console.log('err:', err)
        },
      }
    )
  }

  return (
    <Box px={4}>
      <ModalHeader marginTop="15px">
        <Heading fontSize="3xl" as="h2" fontWeight="extrabold">
          {t('verify@heading')}
        </Heading>
      </ModalHeader>
      <form onSubmit={handleVerify(onVerify)}>
        <Stack spacing="8">
          <InputField
            size="lg"
            isInvalid={!!verifyErrors.code}
            errorMessage={t('verify@codeRequired')}
            {...verify('code', { required: true })}
            label={t('verify@code')}
            id="confirm_signup_modal_code"
          />
          <Button
            isLoading={isLoading}
            type="submit"
            variant="blue"
            size="lg"
            id="confirm_signup_modal_submit"
          >
            {t('verify@submitButtonText')}
          </Button>
        </Stack>
      </form>
      <ModalFooter>
        <Box
          as="button"
          type="button"
          onClick={onResend}
          color={mode('blue.600', 'blue.200')}
          mx="auto"
        >
          {t('verify@resendCode')}
        </Box>
      </ModalFooter>
    </Box>
  )
}

export default VerifySignUpModal
