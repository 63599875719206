import { useEffect } from 'react'
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Spinner,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useProjectsByUserId, useUser } from 'hooks'
import { useNavigation, Link } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import { ReactComponent as Avatar } from 'assets/icons/avatar.svg'
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard_icon_light.svg'
import { ReactComponent as FundraisersIcon } from 'assets/icons/fundraisers_icon_light.svg'
import { ReactComponent as ExpertsIcon } from 'assets/icons/experts_icon_light.svg'
import { ReactComponent as InitiatorIcon } from 'assets/icons/initiator_icon_light.svg'
import { ReactComponent as DonorSpaceIcon } from 'assets/icons/donor_space_light.svg'
import { ReactComponent as InvestorIcon } from 'assets/icons/investor_icon_light.svg'
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications_icon_light.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_icon_light.svg'
import { ReactComponent as Arrow } from 'assets/icons/arrowRight.svg'
import { ReactComponent as Lock } from '../../assets/icons/blue-lock.svg'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import Navbar from 'components/Navbar'

const w = window.innerWidth

const ProfileLayout = ({ children }) => {
  const location = useLocation()
  const TabItem = ({
    label,
    isActive,
    Icon,
    pathname,
    children,
    divider,
    color,
    isLocked,
  }) =>
    children ? (
      <Accordion allowMultiple>
        <AccordionItem border="none">
          <AccordionButton
            minH="44px"
            px={4}
            py={2.5}
            as={Box}
            cursor="pointer"
          >
            <Flex flex={1}>
              <Icon style={{ marginRight: '12px' }} />
              <Text fontWeight={600} fontSize="lg" mr={2} textAlign="left">
                {label}
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel py={3} pl="50px" overflow="visible">
            {children.map(({ label, pathname }) => (
              <Link key={label} to={pathname}>
                <Flex
                  fontWeight={400}
                  fontSize="lg"
                  alignItems="center"
                  py={2.5}
                  color={isActive ? 'blue.400' : 'gray.800'}
                >
                  {label}
                </Flex>
              </Link>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : divider ? (
      <Divider h="1px" bg="gray.600" mb={3} />
    ) : (
      <Link to={pathname}>
        <Flex
          fontWeight={isActive ? 600 : 400}
          fontSize="lg"
          alignItems="center"
          color={
            isLocked || (isActive && !color) ? 'blue.400' : color || '#696A6F'
          }
          px={4}
          py={2.5}
          bg={isActive ? '#dadfea' : 'transparent'}
        >
          {isLocked ? (
            <Lock
              style={{
                marginRight: '12px',
                width: '24px',
                height: '24px',
              }}
            />
          ) : (
            <Icon
              style={{
                marginRight: '12px',
                color: color,
              }}
            />
          )}
          {label}
        </Flex>
      </Link>
    )

  const { navigationPush } = useNavigation()
  const { t, language } = useTranslation()

  const { user, company } = useUser()

  const { isLoading: isLoadingProjects } = useProjectsByUserId({
    owner: user.id,
    filter: {
      isProject: { eq: true },
    },
  })

  const NAV_LINKS = [
    {
      label: t('profile@dashboard'),
      Icon: DashboardIcon,
      pathname: '/profile/dashboard',
      show: true,
    },
    {
      label: t('profile@expertSpace'),
      Icon: ExpertsIcon,
      pathname: '/profile/expert/card',
      show: true,
      color: location.pathname.includes('/profile/expert')
        ? '#ED8936'
        : '#696A6F',
      isLocked: !user.expertId,
    },
    {
      label: t('profile@initiatorSpace'),
      Icon: InitiatorIcon,
      pathname: '/profile/initiator-space/collaborations',
      show: true,
      color: location.pathname.includes(
        '/profile/initiator-space/collaborations'
      )
        ? '#F56565'
        : '#696A6F',
      isLocked: !user.isInitiator,
    },
    {
      label: t('profile@fundraisers'),
      Icon: FundraisersIcon,
      pathname: '/profile/fundraisers/oneTime',
      show: true,
      color: location.pathname.includes('/profile/fundraisers')
        ? '#3182CE'
        : '#696A6F',
      isLocked: !user.isFundraiserCreator,
    },
    {
      label: t('profile@donorSpace'),
      Icon: DonorSpaceIcon,
      pathname: '/profile/history/impact',
      show: true,
      color: location.pathname.includes('/profile/history')
        ? '#805AD5'
        : '#696A6F',
      isLocked: !user.totalOneTimeDonation && !user.totalRecurringDonation,
    },
    {
      label: t('profile@investorSpace'),
      Icon: InvestorIcon,
      pathname: '/profile/invest/impact',
      show: true,
      color: location.pathname.includes('/profile/invest')
        ? '#718096'
        : '#696A6F',
      isLocked: !user.isReArmeniaInvestor,
    },
    {
      label: t('profile@inKindDonorSpace'),
      Icon: InvestorIcon,
      pathname: '/profile/in-kind',
      show: true,
      color: location.pathname.includes('/profile/in-kind')
        ? '#00B5D8'
        : '#696A6F',
      isLocked: !user.isGoodraiserCreator,
    },
    {
      divider: true,
      show: true,
    },
    {
      label: t('profile@notifications'),
      Icon: NotificationsIcon,
      pathname: '/profile/notifications',
      show: true,
    },
    {
      label: t('profile@settings'),
      Icon: SettingsIcon,
      pathname: '/profile/settings/profile-settings',
      show: true,
    },
  ]

  useEffect(() => {
    if (/\/profile\/?$/gm.test(location.pathname)) {
      navigationPush('/profile/settings/profile-settings')
    }
    if (location.pathname === `/${language}/profile/settings`) {
      navigationPush('/profile/settings/profile-settings')
    }
  }, [])

  return (
    <MainLayout>
      {w < 481 && (
        <Navbar
          title={
            NAV_LINKS.find(({ pathname }) =>
              window.location.pathname.includes(pathname)
            )?.label
          }
        />
      )}
      <Container
        py={0}
        pr={0}
        pl={{ base: 0, sm: '60px' }}
        maxW="full"
        ml={`${Math.max(w / 2 - 770, 0)}px`}
      >
        {isLoadingProjects ? (
          <Flex
            w="full"
            margin="auto"
            align="center"
            justifyContent="center"
            minH="500px"
          >
            <Spinner color="blue.400" />
          </Flex>
        ) : (
          <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            {w > 481 && (
              <Flex
                align="flex-start"
                w="full"
                alignSelf={{ lg: 'flex-start' }}
                position={{ lg: 'sticky' }}
                top={{ lg: '0' }}
                minW={{ base: 'full', lg: '252px' }}
                maxW={{ base: 'full', lg: '256px' }}
                my={8}
                mx={4}
              >
                <Flex
                  overflow="hidden"
                  mx={{ base: '0', sm: '8', lg: '0' }}
                  flex="1"
                  direction="column"
                  mb="4"
                  border="1px solid"
                  borderColor="border.400"
                  borderRadius="16px"
                >
                  <Flex py="18px" px={4} direction="column">
                    {/* // TODO change to user.image when schema updated  */}
                    <Avatar height="50px" width="50px" />
                    <Heading fontSize="md" fontWeight={500} as="h3" mt={1}>
                      {user.firstName} {user.lastName}
                    </Heading>
                  </Flex>
                  <Divider h="1px" bg="gray.600" mb={3} />
                  {NAV_LINKS.filter((tab) => tab.show).map((props, index) => (
                    <TabItem
                      key={`${props.label}${index}`}
                      isActive={isActive({
                        location,
                        pathname: props.pathname,
                      })}
                      {...props}
                    />
                  ))}
                  {company?.domain && (
                    <Link to="/profile/matching">
                      <Box
                        fontSize="md"
                        fontWeight="500"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        px="4"
                        py="2"
                        bg={
                          location.pathname.includes('/profile/matching')
                            ? '#dadfea'
                            : 'transparent'
                        }
                      >
                        {t('Matching Fundraisers')}
                        {location.pathname.includes('/profile/matching') && (
                          <Arrow />
                        )}
                      </Box>
                    </Link>
                  )}
                </Flex>
              </Flex>
            )}
            {children}
          </Flex>
        )}
      </Container>
      <Footer />
    </MainLayout>
  )
}

export default ProfileLayout

const isActive = ({ location, pathname }) =>
  location?.pathname?.includes(pathname?.split('/')?.slice(0, 3)?.join('/'))
