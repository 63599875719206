import React, { useEffect } from 'react'
import { Box, Flex, Spinner, VStack } from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCurrencySettings,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import Navbar from 'components/Navbar'
import ProjectCard from 'components/ProjectCard'
import { Tabs, TabList } from '@chakra-ui/react'
import { StyledTab } from '../../../components/AllProductsWrapper/Tabs'
import { useNavigation } from 'pages'
import { calculateProjectDurationLeft, useQueryParams } from 'hooks'
import GiftCardBanner from 'components/GiftCardBanner'
import InfiniteScroll from 'react-infinite-scroll-component'
import amplitude from 'amplitude-js'
import NoScrollbar from 'components/NoScrollbar'

const Loader = () => (
  <Flex
    w="full"
    margin="auto"
    align="center"
    justifyContent="center"
    position="absolute"
    bottom="100px"
  >
    <Spinner color="blue.400" />
  </Flex>
)
const MobileFundraisers = ({
  projects,
  isLoading,
  filters,
  setFilters,
  giftCardMode,
  fetchMore,
  length,
  hasMore,
}) => {
  const { t, language } = useTranslation()
  const queryParams = useQueryParams()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()

  useEffect(() => {
    if (queryParams.get('type') === 'oneTime') {
      amplitude.getInstance().logEvent('Mobile fundraisers oneTime page view')
      console.log(
        'sent event to amplitude Mobile fundraisers oneTime page view'
      )
    }
    if (queryParams.get('type') === 'recurring') {
      amplitude.getInstance().logEvent('Mobile fundraisers recurring page view')
      console.log(
        'sent event to amplitude Mobile fundraisers recurring page view'
      )
    }
  }, [queryParams.get('type')])

  return (
    <Box pb="6rem">
      <Navbar from="fund" title={t('Fundraisers')} />
      <Tabs mt="2">
        <NoScrollbar
          Component={TabList}
          overflow="scroll"
          flexWrap="wrap"
          mx={5}
          pb={0.5}
        >
          <StyledTab
            title="oneTime"
            tabKey="oneTime"
            mobile
            filters={filters}
            setFilters={setFilters}
          />
          <StyledTab
            title="recurring"
            tabKey="recurring"
            mobile
            filters={filters}
            setFilters={setFilters}
          />
          <StyledTab
            title="Peer-to-peer"
            tabKey="peer-to-peer"
            mobile
            filters={filters}
            setFilters={setFilters}
          />
          <StyledTab
            title="foundations"
            tabKey="foundations"
            mobile
            filters={filters}
            setFilters={setFilters}
          />
          <StyledTab
            title="individuals"
            tabKey="individuals"
            mobile
            filters={filters}
            setFilters={setFilters}
          />
        </NoScrollbar>
      </Tabs>
      {isLoading && (!projects || projects?.length === 0) ? (
        <Flex minH="520px" w="full" align="center" justifyContent="center">
          <Spinner color="blue.400" />
        </Flex>
      ) : (
        <>
          <VStack
            spacing="10"
            px="5"
            py="40px"
            as={InfiniteScroll}
            dataLength={length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<Loader />}
            hasChildren={hasMore}
            overflow="hidden"
          >
            {giftCardMode && <GiftCardBanner />}
            {_.uniqBy(projects, 'id').map((card) => (
              <ProjectCard
                key={card.id}
                card={card}
                goalPrice={card.activeGoal?.amount || card.goal}
                raisedMoney={thousandSeparator(
                  convert({
                    amount: card.amount,
                    currencyRate: settings?.currency[currency.current],
                  }),
                  currency.current
                )}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? card.amount + Number(card.updateAmount)
                    : card.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(card.updateAmount),
                  currency.current
                )}
                isLoading={isLoading}
                width="full"
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                type="fundraisers"
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    card.campaignImplementorPeriod_en,
                  fundraisingType: card.fundraisingType,
                })}
                isMobile
                language={language}
                currency={currency}
                navigationPush={navigationPush}
                cardStatusText={t(`status@${card.status}`)}
                cardCategoryText={t(`category@${card.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                monthlyGoalText={t('monthlyGoal')}
                raisedText={t('raised')}
                collaborationProposalsText={t(
                  card?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
              />
            ))}
          </VStack>
        </>
      )}
    </Box>
  )
}

export default MobileFundraisers
