import { useMemo } from 'react'
import _ from 'lodash'
import { Box, Heading, Flex, Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import moment from 'moment'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useTransactions } from 'contexts'
import { ReactComponent as Papik } from '../../../assets/icons/papiktatik.svg'
import TransactionBadge from 'components/TransactionBadge'
import { Link } from 'pages'
import { TopDonors } from '../DonationList'
import { mapTopDonorFirstName } from 'pages/Donation/donationHelpers'
import { FUNDRAISER_PEER_TO_PEER } from 'constants/fundraiser'

export default function Donates({ project, donationPage = false }) {
  const {
    setLoadMore,
    nextToken,
    data: transactions,
    isMoreLoading,
  } = useTransactions()

  const { t } = useTranslation()

  const transactionsItems = useMemo(() => {
    if (!transactions) return []
    return _.uniqBy(transactions, 'id')
  }, [transactions])

  if (transactions?.length === 0) {
    return <></>
  }

  return (
    <>
      {donationPage && (
        <Heading fontSize="lg" mb={2}>
          {t('monthlyDonors')}
        </Heading>
      )}
      <TopDonors data={project.topDonors} />
      <Heading
        my={6}
        as="h3"
        fontWeight="700"
        textTransform="uppercase"
        fontSize="3xl"
      >
        {t('Donors')}
      </Heading>
      <Box>
        {transactionsItems.map((transaction) => (
          <Transaction
            key={`one-time-transaction-mobile-${transaction.id}`}
            transaction={mapTopDonorFirstName(transaction, project.topDonors)}
            thousandSeparator={thousandSeparator}
            donationPage={donationPage}
            isPeerToPeerFundraising={
              project.fundraisingType === FUNDRAISER_PEER_TO_PEER
            }
            t={t}
          />
        ))}
      </Box>
      {nextToken !== null && (
        <Button
          mt="6"
          mx="auto"
          isLoading={isMoreLoading}
          onClick={() => setLoadMore()}
          variant="transparent"
        >
          {t('more')}
        </Button>
      )}
    </>
  )
}

const Transaction = ({
  transaction,
  donationPage,
  thousandSeparator,
  isPeerToPeerFundraising,
  t,
}) => {
  return (
    <Flex
      key={transaction.id}
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="border.100"
      py="5"
    >
      <Box>
        <Text fontSize="md" fontWeight="500" mr={2.5}>
          {transaction.isAnonymous
            ? t('anonymous')
            : transaction.firstName + ' ' + transaction.lastName}
          {!isPeerToPeerFundraising && transaction.peerProjectId ? (
            <TransactionBadge
              title="Peer-to-peer"
              target="_blank"
              as={Link}
              to={`/fundraisers/${transaction.peerProjectId}`}
            />
          ) : (
            transaction.subscriptionId &&
            transaction.subscriptionId !== 'NONE' &&
            !donationPage && <TransactionBadge title="monthly" />
          )}
        </Text>
        <Box display="block" fontSize="sm" as="span" color="gray.700">
          {moment(transaction.createdAt).fromNow()}
        </Box>
      </Box>
      {transaction.double && (
        <Flex ml="2" mr="2" alignItems="center">
          <Box mr="2" display="block" whiteSpace="nowrap" as="span">
            x 2
          </Box>
          <Papik height="30px" />
        </Flex>
      )}
      <Box fontSize="md" fontWeight="600" as="span" flexShrink={0}>
        {transaction.donationToProject > transaction.currencyRate
          ? thousandSeparator(
              Math.floor(
                transaction.donationToProject /
                  Number(transaction.currencyRate || 1)
              )
            )
          : thousandSeparator(
              Number(
                transaction.donationToProject /
                  Number(transaction.currencyRate || 1)
              ).toFixed(2)
            )}{' '}
        {transaction.currency || 'AMD'}
      </Box>
    </Flex>
  )
}
