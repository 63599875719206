import React, { useEffect, useMemo } from 'react'
import { Flex, Container, Text, Box } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useQueryParams,
  useProjects,
  useGetProject,
  useGetTransaction,
} from 'hooks'
import ShareTransactionSocial from 'components/ShareTransactionSocial'
import { MainLayout } from 'components/Layouts'
import { Footer } from 'components/Footer'
import { ReactComponent as Reached } from 'assets/icons/reachedBig.svg'
import { SimilarProjects } from 'pages/Projects/SimilarProjects'
import { ReactComponent as Close } from 'assets/icons/closeBlack.svg'
import { ReactComponent as Logo } from 'assets/icons/logoA.svg'
import { useNavigation } from 'pages'
import { similarProjectsQuery } from 'elastic/queries'
import useGetNeeds from 'hooks/needs/useGetNeeds'
import amplitude from 'amplitude-js'
import FbPixel from 'components/FbPixel'
import fbPixel from 'fbPixel'
import {
  CurrencyConverter,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'

const w = window.innerWidth

const ThankYou = () => {
  const { t, language } = useTranslation()
  const params = useQueryParams()
  const { data: project } = useGetProject({ projectId: params.get('id') })
  const isContribution = params.get('id') === 'rearmenia'
  const isCollaboration = params.get('collaboration')?.trim() === 'true'
  const transactionId = params.get('transactionId')
  const { data: transactionsResponse } = useGetTransaction(transactionId)
  const { currency } = useCurrencySettings()
  const { firstName, amount } = transactionsResponse || {}
  const { projects, isLoading, setFilters } = useProjects(
    similarProjectsQuery({
      category: project?.category,
      region: project?.region,
      projectId: params.get('id'),
      isCollaboration,
    }),
    true,
    'similar_fundraisers',
    'similar_projects_fundraisers'
  )
  const { data: needCategories } = useGetNeeds()

  useEffect(() => {
    if (!project) {
      return
    }
    setFilters(
      similarProjectsQuery({
        category: project?.category,
        region: project?.region,
        projectId: params.get('id'),
        isCollaboration,
      })
    )
  }, [project])

  const link = useMemo(() => {
    if (params.get('id') === 'rearmenia') {
      return `https://rearmenia.com/${language}`
    }
    return `${process.env.REACT_APP_BASE_URL}/${language}/${
      isCollaboration ? 'collaborations' : 'fundraisers'
    }/${params.get('id')}`
  }, [])

  const { navigationPush } = useNavigation()

  useEffect(() => {
    amplitude.getInstance().logEvent('ThankYou stripe page view')
    console.log('sent event to amplitude: ThankYou stripe page view')
  }, [])

  useEffect(() => {
    if (project?.fbPixel) {
      fbPixel.pageView()
      fbPixel.track('Donation Thank You')
    }
  }, [project?.fbPixel])

  return (
    <MainLayout>
      {project?.fbPixel && <FbPixel fbPixel={project?.fbPixel} />}
      <Box display={{ base: 'flex', sm: 'none' }} py="0.375rem" pl="3" w="full">
        <Flex
          onClick={() => navigationPush('/')}
          w="30px"
          h="30px"
          align="center"
          justify="center"
        >
          <Close width="14px" height="14px" />
        </Flex>
      </Box>
      <Container
        mt={{ base: 'none', sm: '72px' }}
        mb={{ base: 12, sm: '70px' }}
        display="flex"
        alignItems="center"
        flexDir="column"
        maxW="750px"
      >
        <Reached minH="218px" />
        <Text
          fontSize={{ base: '4xl', sm: '6xl' }}
          textAlign="center"
          fontWeight={{ base: 700, sm: 500 }}
          mt={4}
          mb={{ base: 8, sm: 6 }}
        >
          {t('thankyou@thanksForMakingDifference').replace('(name)', firstName)}
        </Text>
        <Text textAlign="center" fontSize="md">
          {t('thankyou@yourDonationHasBeenProcessed1')
            .replace(
              '(amount)',
              CurrencyConverter({
                currency: currency.current,
                amount,
                withSymbol: true,
              })
            )
            .replace(
              '(projectTitle)',
              transactionsResponse?.[`projectTitle_${language}`]
            )}
          <br />
          <br />
          {t('thankyou@yourDonationHasBeenProcessed2')}
        </Text>
        <Box
          w="full"
          h="1px"
          bg="gray.400"
          my={6}
          display={{ base: 'block', sm: 'none' }}
        />
        <Text
          mt={{ base: 'none', sm: '54px' }}
          textAlign="center"
          fontSize="2xl"
          fontWeight={700}
        >
          {t('contribution@multiplyImpact')}
        </Text>

        <ShareTransactionSocial link={link} mb={4} />
      </Container>
      <SimilarProjects
        needCategories={needCategories}
        projects={projects?.items || []}
        isLoading={isLoading}
        isContribution={isContribution}
        isCollaboration={isCollaboration}
      />
      <Flex
        px={{ base: 5, xl: 100 }}
        py={{ base: 5, sm: 10 }}
        position="relative"
        mt={{ base: 12, sm: '130px' }}
        mb={{ base: 10, sm: '176px' }}
        bg="gray.200"
      >
        <Flex flexDir="column" maxW="650px">
          <Text fontSize="6xl" fontWeight={500} mb="8">
            {t('thankYou@thankYouAboutReArmenia')}
          </Text>
          <Text lineHeight="32px">
            {t('thankYou@thankYouAboutReArmeniaText1')}
            <br />
            <br />
            {t('thankYou@thankYouAboutReArmeniaText2')}
          </Text>
        </Flex>
        <Flex
          position="absolute"
          top="-57px"
          right="164px"
          display={{ base: 'none', xl: 'block' }}
        >
          <Logo />
        </Flex>
      </Flex>
      {w > 481 && <Footer />}
    </MainLayout>
  )
}

export default ThankYou
