import React from 'react'
import { useParams } from 'react-router-dom'
import { useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import Impact from './Impact'
import OneTime from './OneTime'
import Recurring from './Recurring'
import Settings from './Settings'
import Space from '../Space'
import TabsLayout from 'components/Layouts/TabsLayout'

const TABS = [
  {
    value: 'impact',
    title: 'profile@dashboard@donor@impact',
    Panel: Impact,
  },
  {
    value: 'oneTime',
    title: 'profile@dashboard@donor@oneTime',
    Panel: OneTime,
  },
  {
    value: 'monthly',
    title: 'profile@dashboard@donor@monthly',
    Panel: Recurring,
  },
  {
    value: 'settings',
    title: 'profile@dashboard@donor@settings',
    Panel: Settings,
  },
]

export default function Donor() {
  const { t } = useTranslation()
  const { tab } = useParams()
  const { navigationPush } = useNavigation()

  const onChange = (tab) =>
    navigationPush(`/profile/history/${TABS[tab].value}`)

  return (
    <Space title={t('profile@donorSpace')}>
      <TabsLayout
        defaultIndex={TABS.findIndex(({ value }) => value === tab)}
        onChange={onChange}
        TABS={TABS}
        maxW="full"
      />
    </Space>
  )
}
