import React, { useState, useRef, useEffect, useMemo } from 'react'
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import Basic from './Basic'
import Details from './Details'
import BottomBar from './BottomBar'
import {
  useUser,
  useCreateProject,
  useQueryParams,
  useSignOut,
  useObjectManipulations,
} from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import Fundraiser from './Fundraiser'
import ContactInformation from './ContactInformation'
import Collaboration from './Collaboration'
import MobileCreate from './MobileCreate'
import { MainLayout } from 'components/Layouts'
import { useNavigation } from 'pages'
import useCustomToast from 'hooks/useCustomToast'
import { useGetDraftById, useSaveDraft } from 'core/Draft/hooks'
import _ from 'lodash'
import { normalizeDraft } from 'core/Draft/helpers'
import { useModal } from 'providers/ModalProvider'
import moment from 'moment'

const w = window.innerWidth

const fieldsArray = [
  [
    'projectTeam',
    'category',
    'region',
    'applicationTitle',
    'summary',
    'problem',
    'solution',
  ],
  [
    'beneficiaries',
    'objective',
    'keyResults',
    'startDate',
    'endDate',
    'otherPlayers',
    'risks',
  ],
  [
    'goals',
    'requestedAmount',
    'isOneTimeDisabled',
    'budgetBreakdown',
    'budgetFiles',
  ],
  ['collaborations'],
  [
    'terms_and_conditions',
    'email',
    'contactFullName',
    'phone',
    'privacy_policy',
  ],
]

function CreateProject() {
  const params = useQueryParams()
  const fundraisingType = params.get('fundraisingType')
  const collabType = params.get('collabType')
  const draftId = params.get('draftId')
  const { data: draft, isLoading, refetch } = useGetDraftById(draftId)
  const { openModal } = useModal()
  const { deleteKey } = useObjectManipulations()
  const { navigationPush } = useNavigation()
  const type = {
    fundraising: params.has('isFundraiser'),
    collaboration: params.has('isCollaboration'),
  }
  const [canValidateEmail, setCanValidateEmail] = useState(false)
  const { t, language } = useTranslation()
  const toast = useCustomToast()
  const signOut = useSignOut()

  const fields = useMemo(
    () =>
      fieldsArray
        .map((arr, index) => {
          if (index === 1 || index === 2) {
            if (type.fundraising) {
              return arr
            } else {
              return null
            }
          }
          if (index === 3) {
            if (type.collaboration) {
              return arr
            } else {
              return null
            }
          }
          return arr
        })
        .filter((arr) => !!arr),
    []
  )
  const methods = useForm({
    mode: 'submit',
    reValidateMode: 'onChange',
    defaultValues: {
      files: [],
      fundraisingType,
      category: '',
      region: '',
      budgetFiles: [],
      goals: [],
      isProject: type.fundraising,
      isCollaboration: type.collaboration,
      isInKind: collabType === 'inKind',
      startDate: null,
      endDate: null,
      isOneTimeDisabled: false,
      isStripeOnBoardingDone: false,
    },
  })
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    watch,
    formState: { errors },
    getValues,
    control,
    setError,
    clearErrors,
    reset,
  } = methods
  const {
    fields: goalFields,
    append: appendGoal,
    remove: removeGoal,
  } = useFieldArray({
    control,
    name: 'goals',
  })
  const {
    fields: collFields,
    append: collAppend,
    remove: collRemove,
  } = useFieldArray({
    control,
    name: 'collaborations',
  })
  const [legal, setLegal] = useState(false)
  const [tabIndex, setTabIndex] = useState(1)
  const { mutate: createProject, isLoading: isCreateProjectLoading } =
    useCreateProject()
  const { user } = useUser()

  const budgetFiles = watch('budgetFiles', [])
  const files = watch('files', [])

  const {
    isDraftSaved,
    saveDraft,
    isCreateDraftLoading,
    isUpdateDraftLoading,
  } = useSaveDraft()
  const [modalAction, setModalAction] = useState()
  const redirectURL = useRef()

  const onDraftSave = async ({ redirect, saveAndSignOut }) => {
    const projectInfo = getValues()
    const input = {
      ...projectInfo,
      owner: user.id,
      requestedAmount: !projectInfo.requestedAmount
        ? '0'
        : projectInfo.requestedAmount,
    }
    deleteKey(input, '__typename')
    await saveDraft({
      input,
      callback: async () => {
        await refetch()
        if (redirect) {
          navigationPush(redirectURL.current)
        }
        if (saveAndSignOut) {
          signOut()
        }
      },
    })
  }

  const next = (errors) => {
    const currentPageErrors = Object.keys(errors).filter((error) =>
      fields[tabIndex - 1].includes(error)
    )
    if (!currentPageErrors.length) {
      clearErrors()
      setTabIndex(tabIndex + 1)
    }
  }

  const nextWithoutErrors = () => {
    setTabIndex(tabIndex + 1)
  }
  const prev = () => {
    const localDraft = normalizeDraft(getValues())
    isDraftSaved.current = _.isEqual(localDraft, draft)
    if (!isDraftSaved.current && tabIndex === 1) {
      openModal('draft', null, false, async (data) => {
        if (data?.action) {
          redirectURL.current = `/pre-create-project`
          setModalAction(data.action)
        }
      })
      return
    }
    if (Object.keys(errors).length) {
      return
    }
    if (tabIndex > 1) {
      setTabIndex(tabIndex - 1)
      return
    }
    navigationPush(`/pre-create-project`)
  }

  const submit = async (data) => {
    if (!type.collaboration && !type.fundraising) {
      return toast({
        title: 'None of Project Type Selected',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (data.requestedAmount === 0) {
      setError('requestedAmount')
      return toast({
        title: "Requested Amount can't be 0!",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (type.collaboration && data.collaborations.length < 1) {
      return toast({
        title: 'Seems like you have forgot to add Collaboration needs',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    const input = {
      files: data.files,
      fundraisingType: data.fundraisingType,
      owner: user.id,
      oldCollaborationId: params.get('id') ?? '',
      ownerName: user.firstName,
      ownerLastName: user.lastName,
      ownerEmail: user.email,
      summary: data.summary,
      applicationTitle: data.applicationTitle,
      organizationName: data.organizationName,
      organizationLegalForm: data.organizationLegalForm,
      region: data.region,
      projectTeam: data.projectTeam,
      problem: data.problem,
      solution: data.solution,
      isSubmittedByLegalEntity: legal,
      contactFullName: data.contactFullName,
      totalAmount: data.requestedAmount,
      contactEmail: data.email.toLowerCase(),
      phoneNumber: data.phone,
      otherDetails: data.otherDetails,
      links: data.links,
      TIN: data.tin,
      category: data.category,
      status: data.status ?? 'submitted',
      goalType: data.goalType,
      startDate: moment(data.startDate).format('MM.DD.YYYY'),
      endDate: moment(data.endDate).format('MM.DD.YYYY'),
      createdAt: new Date().toISOString(),
      publishedAt: new Date().toISOString(),
      statusUpdatedAt: new Date().toISOString(),
      amount: 0,
      language,
      collaborations: data.collaborations,
      goals: data.goals,
      budgetFiles: data.budgetFiles,
      isOneTimeDisabled: !data.isOneTimeDisabled,
      phases: data.phases,
      beneficiaries: data.beneficiaries,
      risks: data.risks,
      otherPlayers: data.otherPlayers,
      objective: data.objective,
      keyResults: data.keyResults,
      isProject: params.has('isFundraiser'),
      isCollaboration: params.has('isCollaboration'),
      isInKind: data.isInKind,
      isCompany: false,
      isTestProject: false,
      isHidden: false,
      isStripeOnBoardingDone: false,
    }
    deleteKey(input, '__typename')

    if (getValues('id') || params.get('draftId')) {
      input.id = getValues('id') || params.get('draftId')
    }

    createProject(input, {
      onSuccess: () => navigationPush('/done'),
      onError: (e) => console.log('❌', e),
    })
  }

  const onLinksClick = (event) => {
    if (event !== 'signOut') {
      redirectURL.current = `/${
        new URL(event.target.parentElement.closest('a').href).pathname.split(
          `/${language}/`
        )[1]
      }`
      event.preventDefault()
    }

    const localDraft = normalizeDraft(getValues())
    isDraftSaved.current = _.isEqual(localDraft, draft)

    if (!isDraftSaved.current) {
      openModal('draft', null, false, async (data) => {
        if (data?.action) {
          const action = event === 'signOut' ? 'saveAndSignOut' : data.action
          setModalAction(action)
        }
      })
      return
    }
    navigationPush(redirectURL.current)
  }

  useEffect(() => {
    if (!modalAction) {
      return
    }
    if (modalAction === 'save') {
      onDraftSave({ redirect: true })
    }
    if (modalAction === 'saveAndSignOut') {
      onDraftSave({ redirect: true, saveAndSignOut: true })
    }
    if (modalAction === 'leave') {
      navigationPush(redirectURL.current)
    }
  }, [modalAction])

  useEffect(() => {
    if (legal) {
      fieldsArray[fieldsArray.length - 1] = [
        ...fieldsArray[fieldsArray.length - 1],
        ...['organizationName', 'organizationLegalForm', 'tin'],
      ]
    } else {
      fieldsArray[fieldsArray.length - 1] = fieldsArray[
        fieldsArray.length - 1
      ].filter(
        (e) => !['organizationName', 'organizationLegalForm', 'tin'].includes(e)
      )
    }
  }, [legal])

  useEffect(() => {
    const localDraft = normalizeDraft(getValues())
    isDraftSaved.current = _.isEqual(localDraft, draft)
    const unloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    if (isDraftSaved) {
      window.removeEventListener('beforeunload', unloadCallback)
      return
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [isDraftSaved.current])

  useEffect(() => {
    if (!draft || !draft?.id) return
    reset({
      ...draft,
    })
  }, [draft])

  if (user.id === 'guest' || (!!params.get('draftId') && isLoading)) {
    return (
      <MainLayout>
        <Flex
          w="full"
          minHeight="540px"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="blue.700" />
        </Flex>
      </MainLayout>
    )
  }
  if (w < 481)
    return (
      <MobileCreate
        submit={handleSubmit(submit)}
        register={register}
        tabIndex={tabIndex}
        errors={errors}
        setValue={setValue}
        unregister={unregister}
        type={type}
        collFields={collFields}
        collAppend={collAppend}
        remove={collRemove}
        fundraisingType={fundraisingType}
        goals={goalFields}
        appendGoal={appendGoal}
        removeGoal={removeGoal}
        oldId={params.get('id')}
        setLegal={setLegal}
        legal={legal}
        getValues={getValues}
      />
    )
  return (
    <MainLayout disableLinks={!isDraftSaved.current} onClick={onLinksClick}>
      <Box
        as="form"
        onSubmit={handleSubmit(submit)}
        position="relative"
        w="full"
      >
        <Tabs
          defaultIndex={1}
          index={tabIndex}
          h="calc(100vh - 80px)"
          orientation="vertical"
          border="none"
        >
          <TabList
            zIndex="100"
            overflow="hidden"
            alignItems="flex-start"
            minW="304px"
            bg="blue.300"
            direction="vertical"
          >
            <Tab
              py="4.5rem"
              pl="1.875rem"
              cursor="default"
              _disabled={{
                color: 'white',
                fontWeight: 700,
              }}
              _active={{}}
              color="white"
              fontSize="3xl"
              isDisabled
            >
              {t('createEditInitiative@application')}
            </Tab>
            <Tab
              borderTop="1px solid #0e377d"
              borderBottom="1px solid #0e377d"
              h="89px"
              w="full"
              justifyContent="space-between"
              _selected={{
                color: 'white',
                background: '#0e377d',
                '& > div': {
                  display: 'block',
                },
              }}
              _active={{}}
              cursor="default"
              color="white"
              pl="1.875rem"
            >
              <Heading textAlign="left" fontSize="3xl" fontWeight="400" as="h2">
                {t('Basic')}
              </Heading>
              <Box
                display="none"
                bg="white"
                minH="30px"
                minW="30px"
                position="relative"
                left="35px"
                transform="rotate(45deg)"
              />
            </Tab>
            {type.fundraising && (
              <Tab
                borderBottom="1px solid #0e377d"
                h="89px"
                w="full"
                justifyContent="space-between"
                _selected={{
                  color: 'white',
                  background: '#0e377d',
                  '& > div': {
                    display: 'block',
                  },
                }}
                _active={{}}
                cursor="default"
                color="white"
                pl="1.875rem"
              >
                <Heading
                  textAlign="left"
                  fontSize="3xl"
                  fontWeight="400"
                  as="h2"
                >
                  {t('details')}
                </Heading>
                <Box
                  display="none"
                  bg="white"
                  minH="30px"
                  minW="30px"
                  position="relative"
                  left="35px"
                  transform="rotate(45deg)"
                />
              </Tab>
            )}
            {type.fundraising && (
              <Tab
                borderBottom="1px solid #0e377d"
                height="89px"
                w="full"
                justifyContent="space-between"
                _selected={{
                  color: 'white',
                  background: '#0e377d',
                  '& > div': {
                    display: 'block',
                  },
                }}
                _active={{}}
                cursor="default"
                color="white"
                pl="1.875rem"
              >
                <Heading
                  fontSize="3xl"
                  fontWeight="400"
                  textAlign="left"
                  as="h2"
                >
                  {t('projectCreate@checkboxFundraiser')}
                </Heading>
                <Box
                  display="none"
                  bg="white"
                  minH="30px"
                  minW="30px"
                  position="relative"
                  left="35px"
                  transform="rotate(45deg)"
                />
              </Tab>
            )}
            {type.collaboration && (
              <Tab
                borderBottom="1px solid #0e377d"
                height="89px"
                w="full"
                justifyContent="space-between"
                _selected={{
                  color: 'white',
                  background: '#0e377d',
                  '& > div': {
                    display: 'block',
                  },
                }}
                _active={{}}
                cursor="default"
                color="white"
                pl="1.875rem"
              >
                <Heading
                  textAlign="left"
                  fontSize="3xl"
                  fontWeight="400"
                  as="h2"
                >
                  {t('Collaboration')}
                </Heading>
                <Box
                  display="none"
                  bg="white"
                  minH="30px"
                  minW="30px"
                  position="relative"
                  left="35px"
                  transform="rotate(45deg)"
                />
              </Tab>
            )}
            <Tab
              borderBottom="1px solid #0e377d"
              height="89px"
              w="full"
              justifyContent="space-between"
              _selected={{
                color: 'white',
                background: '#0e377d',
                '& > div': {
                  display: 'block',
                },
              }}
              _active={{}}
              cursor="default"
              color="white"
              pl="1.875rem"
            >
              <Heading fontSize="3xl" fontWeight="400" textAlign="left" as="h2">
                {t('Contact Information')}
              </Heading>
              <Box
                display="none"
                bg="white"
                minH="30px"
                minW="30px"
                position="relative"
                left="35px"
                transform="rotate(45deg)"
              />
            </Tab>
          </TabList>

          <FormProvider {...methods}>
            <TabPanels>
              <TabPanel />
              <TabPanel
                style={{ height: 'calc(100vh - 80px)' }}
                overflow="scroll"
                pt="10"
                pb="7rem"
                pl="108px"
              >
                <Basic
                  oldId={params.get('id')}
                  type={type}
                  register={register}
                  errors={errors}
                />
              </TabPanel>
              {type.fundraising && (
                <TabPanel
                  style={{ height: 'calc(100vh - 80px)' }}
                  overflow="scroll"
                  pt="10"
                  pb="7rem"
                  pl="108px"
                >
                  <Details
                    setValue={setValue}
                    clearErrors={clearErrors}
                    register={register}
                    errors={errors}
                    watch={watch}
                    isOneTime={fundraisingType === 'oneTime'}
                  />
                </TabPanel>
              )}
              {type.fundraising && (
                <TabPanel
                  style={{ height: 'calc(100vh - 80px)' }}
                  overflow="scroll"
                  pt="10"
                  pb="7rem"
                  pl="108px"
                >
                  <Fundraiser
                    budgetFiles={budgetFiles}
                    oldId={params.get('id')}
                    type={type}
                    tabIndex={tabIndex}
                    getValues={getValues}
                    goals={goalFields}
                    append={appendGoal}
                    remove={removeGoal}
                    fundraisingType={fundraisingType}
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    unregister={unregister}
                    clearErrors={clearErrors}
                  />
                </TabPanel>
              )}
              {type.collaboration && (
                <TabPanel
                  style={{ height: 'calc(100vh - 80px)' }}
                  overflow="scroll"
                  pt="10"
                  pb="7rem"
                  pl="108px"
                >
                  <Collaboration
                    register={register}
                    collaborations={collFields}
                    errors={errors}
                    append={collAppend}
                    remove={collRemove}
                  />
                </TabPanel>
              )}
              <TabPanel
                style={{ height: 'calc(100vh - 80px)' }}
                overflow="scroll"
                pt="10"
                pb="7rem"
                pl="108px"
              >
                <ContactInformation
                  canValidateEmail={canValidateEmail}
                  files={files}
                  legal={legal}
                  setLegal={setLegal}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                />
              </TabPanel>
              <BottomBar
                isDraftLoading={isCreateDraftLoading || isUpdateDraftLoading}
                saveDraft={onDraftSave}
                setCanValidateEmail={setCanValidateEmail}
                isLoading={isCreateProjectLoading}
                errors={errors}
                limit={fields.length}
                next={handleSubmit(nextWithoutErrors, next)}
                fieldsArray={fieldsArray}
                prev={prev}
                setTabIndex={setTabIndex}
                tabIndex={tabIndex}
              />
            </TabPanels>
          </FormProvider>
        </Tabs>
      </Box>
    </MainLayout>
  )
}

export default CreateProject
