import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import MenuItem from './MenuItem'
import { useStringManipulations } from 'hooks'

//** Icons
import { ReactComponent as ArrowDown } from 'assets/icons/angleDown.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as Pen } from 'assets/icons/blue_pen.svg'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import debounce from 'lodash.debounce'

const w = window.innerWidth
const SPHERES_LIMIT = 5

export default function MultiSelect({
  isDisabled = false,
  containerProps,
  label,
  inputPlaceholder,
  isInvalid,
  options,
  lang = 'en',
  onChange = () => {},
  selectedOptions,
  disabledSelectedCount,
  defaultIsOpen = false,
  isProfileSelect = false,
  variant,
  multipleInputsProps,
  appendSphere,
}) {
  const { lowerCase } = useStringManipulations()
  const elRef = useRef(null)
  const [search, setSearch] = useState()
  const selectedCount = selectedOptions?.length
  const handleClick = (menuItem) => {
    const tags = menuItem.tags_en.split(',')
    const categories = {
      title_en: menuItem.title_en,
      title_hy: menuItem.title_hy,
      title_ru: menuItem.title_ru,
    }
    let updatedSelection = [...selectedOptions]
    if (isProfileSelect) {
      const categoryExists = updatedSelection.some(
        (e) => e.title_en === categories.title_en
      )
      if (categoryExists) {
        updatedSelection = updatedSelection.filter(
          (category) => category.title_en !== categories.title_en
        )
      } else {
        updatedSelection = [...updatedSelection, categories]
      }
    } else {
      if (selectedOptions.some((e) => tags.includes(e))) {
        updatedSelection = updatedSelection.filter(
          (t) => !lowerCase(tags).includes(lowerCase(t))
        )
      } else {
        updatedSelection = [...lowerCase(updatedSelection), ...lowerCase(tags)]
      }
    }

    if (appendSphere) {
      onChange(menuItem)
    } else {
      onChange(updatedSelection)
    }
  }
  return (
    <Flex
      alignItems="center"
      {...containerProps}
      ref={elRef}
      position="relative"
    >
      {!!selectedCount && !disabledSelectedCount && w > 481 && (
        <SelectedCount
          mr={3}
          position="absolute"
          right="20px"
          value={selectedCount}
          onClick={() => {
            onChange([])
          }}
        />
      )}
      <Menu
        w={{
          base: variant === 'white' ? 'calc(100vw - 80px)' : '275px',
          sm: variant === 'white' ? '318px' : '244px',
        }}
        closeOnSelect={false}
        defaultIsOpen={defaultIsOpen}
        variant={variant}
      >
        <MenuButton
          as={Button}
          w="full"
          h={variant === 'white' ? 10 : 'auto'}
          color="gray.800"
          fontSize="md"
          textTransform="capitalize"
          fontWeight="normal"
          px={4}
          py={3}
          transition="all 0.2s"
          borderWidth="1px"
          border="1px solid"
          borderColor={isInvalid ? 'red.400' : 'border.400'}
          background="white"
          borderRadius="8px"
          isDisabled={isDisabled}
        >
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Text>{label}</Text> <ArrowDown width="8px" height="6px" />
          </Flex>
        </MenuButton>
        <MenuList
          w={{
            base: variant === 'white' ? 'calc(100vw - 80px)' : '275px',
            sm: variant === 'white' ? '318px' : '244px',
          }}
          padding={0}
          border={variant === 'white' ? '1px solid' : 'none'}
          borderColor="border.400"
          borderRadius={8}
        >
          <Box p={variant === 'white' ? 4 : 2} borderTopRadius={16}>
            <InputGroup>
              {search?.length && (
                <InputRightElement
                  w="50px"
                  h="50px"
                  children={
                    <IconButton
                      onClick={() => {
                        setSearch('')
                      }}
                    >
                      <CloseIcon width="10px" fill="gray.700" />
                    </IconButton>
                  }
                />
              )}
              <Input
                bg={variant === 'white' ? 'white' : '#6495CE'}
                h={variant === 'white' ? '50px' : 'auto'}
                border="1px solid"
                borderColor="blue.400"
                placeholder={inputPlaceholder}
                autoFocus={w > 481}
                color="black"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                value={search}
                _placeholder={{
                  color: 'gray.600',
                  fontSize: 'md',
                }}
                mx="auto"
              />
            </InputGroup>
          </Box>
          <Stack
            w={{
              base: variant === 'white' ? 'calc(100vw - 80px)' : '275px',
              sm: variant === 'white' ? '318px' : '244px',
            }}
            maxH={{ base: '50vh', sm: '400px' }}
            borderBottomRadius={16}
            overflowX="hidden"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: variant === 'white' ? `gray.400` : `blue.400`,
                marginRight: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: variant === 'white' ? `gray.600` : `blue.200`,
                borderRadius: 20,
              },
            }}
            pb={2}
          >
            {options
              ?.filter((f) =>
                search
                  ? lowerCase(f?.[`title_${lang}`])?.includes(lowerCase(search))
                  : true
              )
              ?.sort((a, b) => b.count - a.count)
              ?.map((menuItem, index) => (
                <ListItem
                  key={menuItem.title_en + index}
                  variant={variant}
                  isChecked={
                    isProfileSelect
                      ? selectedOptions.some(
                          (option) =>
                            lowerCase(option.title_en) ===
                            lowerCase(menuItem.title_en)
                        )
                      : lowerCase(selectedOptions).includes(
                          lowerCase(menuItem.title_en)
                        )
                  }
                  menuItem={menuItem}
                  onClick={() => {
                    handleClick(menuItem)
                  }}
                  lang={lang}
                />
              ))}
          </Stack>
          {multipleInputsProps && <MultipleInputs {...multipleInputsProps} />}
        </MenuList>
      </Menu>
    </Flex>
  )
}

const SelectedCount = ({ value, onClick, ...rest }) => (
  <Button
    background="orange.400"
    color="white"
    borderRadius="50px"
    px={3}
    py={1}
    {...rest}
    onClick={onClick}
  >
    <Text fontSize="md" fontWeight="bold" mr={2}>
      {value}
    </Text>
    <CloseIcon width="10px" />
  </Button>
)

const ListItem = ({ isChecked, menuItem, lang, onClick, variant }) => (
  <MenuItem onClick={onClick} isChecked={isChecked} variant={variant}>
    <Text as="span" textTransform="capitalize">
      {menuItem[`title_${lang}`]}{' '}
    </Text>
    {variant !== 'white' && (
      <Text as="span" color="#ffffff6b" ml={2}>
        {' '}
        {menuItem.count}
      </Text>
    )}
  </MenuItem>
)

export const MultipleInputs = ({
  language,
  isDisabled,
  inputSpheres,
  onAddSphereField,
  removeCustomSpheres,
  updateCustomSpheres,
  register,
  t,
}) => {
  const { findNamingTranslation } = useStringManipulations()
  const [disabledButtons, setDisabledButtons] = useState(
    inputSpheres.length === 1 &&
      !findNamingTranslation({
        parentObject: inputSpheres[0],
        value: 'title',
        language,
      })
  )
  const addNewInput = () => {
    onAddSphereField()
  }
  useEffect(() => {
    setDisabledButtons(
      inputSpheres.length === 1 &&
        !findNamingTranslation({
          parentObject: inputSpheres[0],
          value: 'title',
          language,
        })
    )
  }, [inputSpheres])
  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        (value) => setDisabledButtons(!value && inputSpheres.length === 1),
        300
      ),
    [inputSpheres]
  )

  const onConfirm = async () => {
    for (let i = inputSpheres.length - 1; i >= 0; i--) {
      const sphere = inputSpheres[i]
      if (!sphere.title_en && !sphere.title_ru && !sphere.title_hy) {
        removeCustomSpheres(i)
      } else {
        await updateCustomSpheres(i, {
          ...sphere,
          title_en: findNamingTranslation({
            parentObject: sphere,
            value: 'title',
            language: 'en',
          }),
          title_hy: findNamingTranslation({
            parentObject: sphere,
            value: 'title',
            language: 'hy',
          }),
          title_ru: findNamingTranslation({
            parentObject: sphere,
            value: 'title',
            language: 'ru',
          }),
          added: true,
        })
      }
    }
  }

  const onCancel = async () => {
    for (let i = inputSpheres.length - 1; i >= 0; i--) {
      if (!inputSpheres[i].added) {
        await removeCustomSpheres(i)
      }
    }
  }

  return (
    <>
      {!isDisabled && (
        <Box p={4}>
          <Stack spacing={2} w="full">
            {inputSpheres.map((field, index) => (
              <Flex key={field.id} alignItems="center">
                <InputGroup>
                  <InputRightElement maxH="full">
                    {field.added ? (
                      <IconButton
                        w="20px"
                        h="20px"
                        minW="20px"
                        borderRadius="100%"
                        mr={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="sm"
                        fontWeight={600}
                        onMouseDown={() =>
                          updateCustomSpheres(index, {
                            ...field,
                            added: false,
                          })
                        }
                      >
                        <Pen width="14px" fill="black" />
                      </IconButton>
                    ) : (
                      <IconButton
                        background={field.added ? 'transparent' : '#DDDDDD'}
                        w="20px"
                        h="20px"
                        minW="20px"
                        borderRadius="100%"
                        mr={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="sm"
                        fontWeight={600}
                        onMouseDown={() => removeCustomSpheres(index)}
                      >
                        <CloseIcon width="10px" fill="black" />
                      </IconButton>
                    )}
                  </InputRightElement>
                  <Input
                    isDisabled={field.added}
                    w="full"
                    _focus={{ borderColor: 'blue.400' }}
                    _hover={{ borderColor: 'blue.400' }}
                    _disabled={{
                      borderColor: 'border.400',
                      height: 8,
                      borderRadius: '50px',
                      color: 'gray.700',
                    }}
                    sx={{ boxShadow: 'none !important' }}
                    borderColor="gray.600"
                    borderWidth="1px"
                    fontSize="lg"
                    variant="border"
                    {...register(`customSpheres.${index}.title_${language}`, {
                      onBlur: (e) => {
                        updateCustomSpheres(index, {
                          ...field,
                          [`title_${language}`]: e.target.value,
                        })
                      },
                    })}
                    onChange={(e) => debouncedChangeHandler(e.target.value)}
                  />
                </InputGroup>
              </Flex>
            ))}
          </Stack>
          <Divider borderColor="border.100" my={4} />
          <Flex mt={4} mb={inputSpheres.length > 0 ? 4 : 0} justify="center">
            {inputSpheres.length < SPHERES_LIMIT && !isDisabled && (
              <Button
                textTransform="capitalize"
                onClick={() => addNewInput()}
                color="black"
              >
                <Box mr="4">
                  <Plus w="24px" />
                </Box>
                {t('Add a new area', language)}
              </Button>
            )}
          </Flex>
          {inputSpheres.length > 0 && (
            <Flex justify="space-between">
              <Button
                textTransform="capitalize"
                onClick={onCancel}
                w="135px"
                variant="white"
                isDisabled={disabledButtons}
              >
                {t('cancel', language)}
              </Button>
              <Button
                textTransform="capitalize"
                isDisabled={disabledButtons}
                onClick={onConfirm}
                w="135px"
                variant="blue"
              >
                {t('Confirm', language)}
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </>
  )
}
