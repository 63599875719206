/* eslint-disable */
import React, { Fragment, useState } from 'react'
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Text,
  ListItem,
  UnorderedList,
  Button,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import { useTranslation } from 'contexts/TranslationContext'
import { useNavigation } from 'pages'
import { useModal } from 'providers/ModalProvider'
import {
  FUNDRAISER_ONE_TIME,
  FUNDRAISER_PEER_TO_PEER,
} from 'constants/fundraiser'
import {
  PROJECT_TYPE_COLLABORATION,
  PROJECT_TYPE_FUNDRAISER,
} from 'constants/project'
import Mobile from './Mobile'
import Disclaimer from './Disclaimer'
import fundraisers from './fundraisers'
import { CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL } from 'constants/modals'

const w = window.innerWidth

const CreateProject = () => {
  const [projectType, setProjectType] = useState(PROJECT_TYPE_FUNDRAISER)
  const [fundraisingType, setFundraisingType] = useState(FUNDRAISER_ONE_TIME)
  const [collabType, setCollabType] = useState('inKind')
  const { openModal } = useModal()
  // const [isInKind, setIsInKind] = useState(true)
  const { navigationPush } = useNavigation()
  const { t } = useTranslation()

  if (w < 481) {
    return <Mobile />
  }

  const onChoosePeerToPeerFundraiser = (project) => {
    navigationPush(`/create-peer-to-peer-fundraiser/${project.id}/content`)
  }

  const next = () => {
    if (fundraisingType === FUNDRAISER_PEER_TO_PEER) {
      return openModal(
        CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL,
        null,
        false,
        () => {},
        { onChoose: onChoosePeerToPeerFundraiser },
        { maxWidth: '1096px', minH: '50vh' },
        false
      )
    }

    let urlParams = '?'
    if (projectType === PROJECT_TYPE_FUNDRAISER) {
      urlParams += `isFundraiser=true&fundraisingType=${fundraisingType}`
    }
    if (projectType === PROJECT_TYPE_COLLABORATION) {
      if (urlParams !== '?') {
        urlParams += '&'
      }
      urlParams += `isCollaboration=true&collabType=${collabType}`
    }
    navigationPush(`/create-project${urlParams}`)
  }
  return (
    <MainLayout>
      <Flex justifyContent="center" mt={10} pb="100px">
        <Flex flexDir="column" maxW="648px">
          <Heading color="blue.400" fontSize="6xl" fontWeight={700}>
            {t('projectCreate@apply')}
          </Heading>
          <Text color="gray.800" mt={4}>
            {t('projectCreate@applyText')}
          </Text>
          <Text fontSize="xl" color="gray.800" fontWeight={700} mt={8}>
            {t('projectCreate@selectComponents')}
          </Text>
          <Text color="gray.700" mt={1} mb={6} fontSize="md">
            {t('projectCreate@selectComponentsText')}
          </Text>
          <Box borderWidth={1} borderColor="gray.500" borderRadius={12}>
            <RadioGroup value={projectType} onChange={setProjectType}>
              <Flex
                p={4}
                cursor="pointer"
                mt={1}
                mr={3}
                alignItems="flex-start"
                onClick={() => {
                  setProjectType(PROJECT_TYPE_FUNDRAISER)
                  setFundraisingType(FUNDRAISER_ONE_TIME)
                  setTimeout(() => {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      behavior: 'smooth',
                    })
                  }, 0)
                }}
              >
                <Radio mt={1} mr={3} value={PROJECT_TYPE_FUNDRAISER} />
                <Flex flexDir="column" mt="-5px">
                  <Text color="gray.800" fontSize="xl" fontWeight={700}>
                    {t('projectCreate@checkboxFundraiser')}
                  </Text>
                  <Text color="gray.700" fontSize="sm">
                    {
                      t('projectCreate@checkboxFundraiserText').split(
                        '(bold)'
                      )[0]
                    }
                    <b>
                      {
                        t('projectCreate@checkboxFundraiserText').split(
                          '(bold)'
                        )[1]
                      }
                    </b>
                    {
                      t('projectCreate@checkboxFundraiserText').split(
                        '(bold)'
                      )[2]
                    }
                  </Text>
                </Flex>
              </Flex>
              <Box w="100%" h="1px" bg="gray.500"></Box>
              <Flex
                mt={1}
                mr={3}
                p={4}
                cursor="pointer"
                alignItems="flex-start"
                onClick={() => {
                  setProjectType(PROJECT_TYPE_COLLABORATION)
                  setTimeout(() => {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      behavior: 'smooth',
                    })
                  }, 0)
                }}
                control={{ mt: 10 }}
              >
                <Radio mt={1} mr={3} value={PROJECT_TYPE_COLLABORATION} />
                <Flex flexDir="column" mt="-5px">
                  <Text color="gray.800" fontSize="xl" fontWeight={700}>
                    {t('projectCreate@checkboxCollaboration')}
                  </Text>
                  <Text color="gray.700" fontSize="sm">
                    {t('projectCreate@checkboxCollaborationText')}
                  </Text>
                </Flex>
              </Flex>
            </RadioGroup>
          </Box>
          <Box hidden={projectType !== PROJECT_TYPE_FUNDRAISER}>
            <Text fontSize="xl" color="gray.800" fontWeight={700} mt={8}>
              {t('projectCreate@selectFundType')}
            </Text>
            <Text color="gray.700" mt={1} mb={6} fontSize="md">
              {t('projectCreate@selectFundTypeText')}
            </Text>
            <RadioGroup onChange={setFundraisingType} value={fundraisingType}>
              <Box borderWidth={1} borderColor="gray.500" borderRadius={12}>
                {fundraisers.map((fundraiser, idx) => (
                  <Fragment key={`fundraiser-${fundraiser.type}`}>
                    <Flex
                      p={4}
                      alignItems="flex-start"
                      cursor="pointer"
                      onClick={() => setFundraisingType(fundraiser.type)}
                    >
                      <Radio mt={1} mr={3} value={fundraiser.type}></Radio>
                      <Flex flexDir="column">
                        <Text color="gray.800" fontSize="xl" fontWeight={700}>
                          {t(fundraiser.heading)}
                        </Text>
                        <Text color="gray.700" fontSize="sm">
                          {t(fundraiser.body)}
                        </Text>
                      </Flex>
                    </Flex>
                    {idx + 1 !== fundraisers.length && (
                      <Box w="100%" h="1px" bg="gray.500"></Box>
                    )}
                  </Fragment>
                ))}
              </Box>
            </RadioGroup>
          </Box>
          <Box hidden={projectType !== PROJECT_TYPE_COLLABORATION}>
            <Text fontSize="xl" color="gray.800" fontWeight={700} mt={8}>
              {t('projectCreate@selectCollaborationType')}
            </Text>
            <Text color="gray.700" mt={1} mb={6} fontSize="md">
              {t('projectCreate@selectCollaborationTypeText')}
            </Text>
            <RadioGroup onChange={setCollabType} value={collabType}>
              <Box borderWidth={1} borderColor="gray.500" borderRadius={12}>
                <Flex
                  p={4}
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={() => setCollabType('inKind')}
                >
                  <Radio mt={1} mr={3} value="inKind"></Radio>
                  <Flex flexDir="column">
                    <Text color="gray.800" fontSize="xl" fontWeight={700}>
                      {t('projectCreate@isInKind')}
                    </Text>
                    <Text color="gray.700" fontSize="sm">
                      {t('projectCreate@isInKindText')}
                    </Text>
                  </Flex>
                </Flex>
                <Box w="100%" h="1px" bg="gray.500"></Box>
                <Flex
                  p={4}
                  alignItems="flex-start"
                  cursor="pointer"
                  onClick={() => setCollabType('collaboration')}
                >
                  <Radio mt={1} mr={3} value="collaboration"></Radio>
                  <Flex flexDir="column">
                    <Text color="gray.800" fontSize="xl" fontWeight={700}>
                      {t('projectCreate@collaboration@secondOption')}
                    </Text>
                    <Text color="gray.700" fontSize="sm">
                      {t('projectCreate@collaboration@secondOption@Text')}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </RadioGroup>
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          ml="144px"
          bg="gray.400"
          borderRadius={12}
          py={5}
          w="260px"
          h="fit-content"
        >
          <Text color="gray.800" fontSize="md" fontWeight={600} px={5}>
            {t('projectCreate@important')}
          </Text>
          <UnorderedList mt={4} mb={6} px={5}>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText1')}
            </ListItem>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText2')}
            </ListItem>
            <ListItem mb={4} fontSize="sm" color="gray.800">
              {t('projectCreate@importantText3')}
            </ListItem>
          </UnorderedList>
          <Box w="100%" h="1px" bg="gray.500"></Box>
          <Text fontSize="md" fontWeight={600} mt={6} px={5}>
            {t('projectCreate@importantText4').split('...')[0]}
            <Text color="blue.400" as="span">
              <a href="/assets/files/guide.pdf" target="_blank">
                {t('projectCreate@importantText4').split('...')[1]}
              </a>{' '}
            </Text>
            {t('projectCreate@importantText4').split('...')[2]}
          </Text>
        </Flex>
      </Flex>
      <Flex
        position="fixed"
        bottom="0"
        justifyContent="flex-end"
        w="100%"
        boxShadow="0px 2px 24px rgba(0, 0, 0, 0.1)"
        bg="white"
      >
        <Button
          px="14"
          variant="green"
          my={3}
          mr="128px"
          isDisabled={!projectType}
          onClick={next}
        >
          {t(
            `${
              fundraisingType === FUNDRAISER_PEER_TO_PEER
                ? 'Choose Fundraiser'
                : 'projectCreate@next'
            }`
          )}
        </Button>
      </Flex>
    </MainLayout>
  )
}

export default CreateProject
