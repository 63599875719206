import { Flex, HStack } from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import React, { useState } from 'react'
import { ReactComponent as Back } from 'assets/icons/closeBlack.svg'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'
import { Widget } from '@typeform/embed-react'
import { useEffect } from 'react'

const w = window.innerWidth

const FORMS = {
  INITIATOR: {
    en: 'BDwTevrA',
    hy: 'EXqrdO7h',
    ru: 'smbnhm2G',
  },
  EXPERT: {
    en: 'JA8CzwO8',
    hy: 'qpohoZZM',
    ru: 'LBr6xSRb',
  },
}
function ExpertForm({ type }) {
  const history = useHistory()
  const { language } = useTranslation()
  const [formId, setFormId] = useState(FORMS[type][language])
  useEffect(() => {
    setFormId(FORMS[type][language])
  }, language)
  return (
    <MainLayout>
      {w < 481 && (
        <HStack
          w="full"
          borderBottom="1px solid"
          borderColor="border.100"
          alignItems="center"
          position="relative"
          justifyContent="center"
          py={6}
        >
          <Flex
            position="absolute"
            left="12px"
            onClick={() => history.goBack()}
            width="30px"
            height="30px"
            justifyContent="center"
            alignItems="center"
          >
            <Back width="14px" height="14px" />
          </Flex>
        </HStack>
      )}
      <Flex minH={720} justifyContent="center" alignItems="center">
        <Widget
          id={formId}
          style={{ width: '100%', height: 'calc(100vh - 100px)' }}
          className="my-form"
        />
      </Flex>
    </MainLayout>
  )
}
const FormPageWithType =
  (type) =>
  ({ props }) =>
    <ExpertForm type={type} {...props} />
export default FormPageWithType
