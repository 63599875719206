import React from 'react'
import { Heading, Box, Flex, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { thousandSeparator } from 'contexts/CurrencySettingsContext'
import { useProject } from '../ProjectContext'
import EditableApplication from './EditableApplication'
import { FaFile } from 'react-icons/fa'
import { useStringManipulations } from 'hooks'

const LABEL_PROPS = {
  as: 'span',
  fontSize: 'md',
  fontWeight: '500',
  color: 'gray.800',
  lineHeight: '18px',
  display: 'block',
}

const TEXT_PROPS = {
  as: 'p',
  fontSize: 'md',
  color: 'gray.700',
  lineHeight: '18px',
}
const InfoBlock = ({ value, label }) => {
  if (!value) {
    return null
  }
  return (
    <Box>
      <Box {...LABEL_PROPS} mt={4} mb={1}>
        {label}
      </Box>
      <Box {...TEXT_PROPS} mt={4}>
        {value}
      </Box>
    </Box>
  )
}
const MainInfo = () => {
  const { t, language } = useTranslation()
  const { findNamingTranslation } = useStringManipulations()
  const { project, onUpdate } = useProject()
  const isOneTime = project.fundraisingType === 'oneTime'
  if (project.status === 'submitted' || project.status === 'reviewed_by_ss') {
    return (
      <EditableApplication
        project={project}
        onUpdate={onUpdate}
        isOneTime={isOneTime}
      />
    )
  }

  return (
    <Box maxWidth="508px" width="100%">
      <Heading fontSize="5xl" as="h1">
        {t('createEditProject@projectApplication')}
      </Heading>
      <Box
        mt={6}
        mb={5}
        fontSize="sm"
        textTransform="uppercase"
        fontWeight="bold"
        color="blue.400"
        display="block"
        as="span"
      >
        {t(`status@${project.status}`)} | {t(`category@${project.category}`)}
      </Box>
      <Heading fontSize="5xl" as="h2">
        {project.applicationTitle}
      </Heading>
      <InfoBlock />
      <Box {...TEXT_PROPS} mt={4}>
        {t('createEditProject@attachFileNote')}
      </Box>
      <InfoBlock
        label={t('createEditProject@projectSummary')}
        value={project.summary}
      />
      <InfoBlock label={t('createEditProject@region')} value={project.region} />
      <InfoBlock
        label={t('createEditProject@category')}
        value={project.category}
      />
      <InfoBlock
        label={t('createEditProject@projectProblem')}
        value={project.problem}
      />
      <InfoBlock
        label={t('createEditProject@projectSolution')}
        value={project.solution}
      />
      <InfoBlock
        label={t('createEditProject@teamBehindProject')}
        value={project.projectTeam}
      />

      <InfoBlock
        label={t('createEditProject@beneficiaries')}
        value={project.beneficiaries}
      />
      <InfoBlock
        label={t('createEditProject@objective')}
        value={project.objective}
      />
      <InfoBlock
        label={t('createEditProject@keyResults')}
        value={project.keyResults}
      />
      <Flex display={project?.collaborations?.length > 0 ? 'none' : 'block'}>
        <InfoBlock
          label={t('projectCreate@Implementation')}
          value={
            project.startDate && project.endDate
              ? `${project.startDate}  -  ${project.endDate} `
              : null
          }
        />
      </Flex>
      <InfoBlock label={t('createEditProject@phases')} value={project.phases} />
      <InfoBlock label={t('createEditProject@risks')} value={project.risks} />
      <InfoBlock
        label={t('createEditProject@otherPlayers')}
        value={project.otherPlayers}
      />

      <Flex display={project?.collaborations?.length > 0 ? 'none' : 'block'}>
        <InfoBlock
          label={t('createEditProject@requestedAmount')}
          value={thousandSeparator(project.totalAmount) + ' AMD'}
        />
      </Flex>

      {project.goals.map((item) => (
        <InfoBlock
          key={item[`description_${language}`]}
          label={t('createEditProject@goal') + ' ' + item.amount}
          value={item[`description_${language}`]}
        />
      ))}

      {project?.budgetFiles?.length > 0 && (
        <Stack spacing="2" mt={4}>
          <Text
            as="span"
            fontSize="md"
            fontWeight="500"
            color="gray.800"
            lineHeight="18px"
            display="block"
          >
            {t('createEditProject@Budgets')}
          </Text>
          {project.budgetFiles?.map((file) => (
            <Flex
              key={`edit-project-application-file-${file}`}
              borderWidth="1px"
              borderColor="border.100"
              borderRadius="7px"
              pl={3}
              py="14px"
              alignItems="center"
              position="relative"
              _hover={{
                button: {
                  opacity: 1,
                },
              }}
            >
              <FaFile color="gray.600" />
              <a
                style={{ marginLeft: '0.875rem' }}
                href={`${
                  process.env.REACT_APP_S3_BUCKET_URL
                }/public/${file.replaceAll(' ', '+')}`}
                download
              >
                {file}
              </a>
            </Flex>
          ))}
        </Stack>
      )}

      {project?.collaborations?.length > 0 && (
        <Stack spacing="2" mt={4}>
          <Text
            as="span"
            fontSize="md"
            fontWeight="500"
            color="gray.800"
            lineHeight="18px"
            display="block"
          >
            {t('Collaborations')}
          </Text>
          {project.collaborations?.map((item) => (
            <Box key={item[`needs_${language}`]}>
              <InfoBlock
                label={t('describe the needs')}
                value={item[`needs_${language}`]}
              />
              <InfoBlock
                mt={2}
                label={t('edit@collaborationDesc')}
                value={findNamingTranslation({
                  parentObject: item,
                  value: 'needsDescription',
                  language,
                })}
              />
            </Box>
          ))}
        </Stack>
      )}

      <InfoBlock
        label={t('createEditProject@contactPersonFullName')}
        value={project.contactFullName}
      />

      <Flex mt={6} justifyContent="space-between">
        <Box w="100%">
          <InfoBlock
            label={t('createEditProject@email')}
            value={project.contactEmail}
          />
        </Box>
        <Box w="100%">
          <InfoBlock
            label={t('createEditProject@phoneNumber')}
            value={project.phoneNumber}
          />
        </Box>
      </Flex>
      <Box
        h="1px"
        w="100%"
        bg="border.100"
        marginBottom="30px"
        marginTop="40px"
      />
      <InfoBlock
        label={t('createEditProject@organizationName')}
        value={project.organizationName}
      />
      <Flex mt={6} justifyContent="space-between">
        <Box w="100%">
          <InfoBlock
            label={t('createEditProject@organizationLegalForm')}
            value={project.organizationLegalForm}
          />
        </Box>
        <Box w="100%">
          <InfoBlock label={t('createEditProject@TIN')} value={project.TIN} />
        </Box>
      </Flex>
      <InfoBlock
        label={t('createEditProject@otherDetails')}
        value={project.otherDetails}
      />

      <Box {...TEXT_PROPS}>{t('donation@donateToReArmeniaFee')}</Box>

      <Box {...LABEL_PROPS} mt={5} mb={2}>
        {t('createEditProject@link')}
      </Box>
      <Box
        {...TEXT_PROPS}
        fontWeight="500"
        bg="gray.600"
        px={4}
        py={2.5}
        borderRadius="6px"
        color="gray.800"
      >
        {project.links}
      </Box>
      <Box
        h="1px"
        w="100%"
        bg="border.100"
        marginBottom="24px"
        marginTop="30px"
      />
      {project?.files?.length > 0 && (
        <Stack spacing="2" mt={4}>
          <Text
            as="span"
            fontSize="md"
            fontWeight="500"
            color="gray.800"
            lineHeight="18px"
            display="block"
          >
            {t('createEditProject@Files')}
          </Text>
          {project.files?.map((file) => (
            <Flex
              key={`edit-project-application-file-${file}`}
              borderWidth="1px"
              borderColor="border.100"
              borderRadius="7px"
              mt={2}
              pl={3}
              py="14px"
              alignItems="center"
              position="relative"
              _hover={{
                button: {
                  opacity: 1,
                },
              }}
            >
              <FaFile color="gray.600" />
              <a
                style={{ marginLeft: '0.875rem' }}
                href={`${
                  process.env.REACT_APP_S3_BUCKET_URL
                }/public/${file.replaceAll(' ', '+')}`}
                download
              >
                {file}
              </a>
            </Flex>
          ))}
        </Stack>
      )}
    </Box>
  )
}
export default MainInfo
