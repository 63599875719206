import React from 'react'
import {
  Box,
  Image,
  Flex,
  Heading,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'
import { Link, useNavigation } from 'pages'
import { useTranslation } from 'contexts/TranslationContext'
import Mobile from './Mobile'

const w = window.innerWidth

const BlogCard = ({ card, ...props }) => {
  const { navigationPush } = useNavigation()
  const { language } = useTranslation()

  if (w < 481) {
    return <Mobile card={card} {...props} />
  }

  return (
    <Box as={Link} to={`/blog/${card.slug}`} {...props}>
      <Box overflow="hidden" borderRadius="12px">
        <Box
          overflow="hidden"
          position="relative"
          width="100%"
          height={card?.image ? '184px' : 'auto'}
        >
          {card?.image ? (
            <LazyLoad height="184px" once>
              <Image
                onClick={() => navigationPush(`/blog/${card.slug}`)}
                cursor="pointer"
                borderRadius="12px 12px 0 0"
                objectFit="cover"
                width="100%"
                bgColor="border.100"
                height="184px"
                ignoreFallback={true}
                src={card.image}
              />
            </LazyLoad>
          ) : (
            <Box
              onClick={() => navigationPush(`/blog/${card.slug}`)}
              cursor="pointer"
              borderRadius="16px"
              boxShadow="0 12px 24px 0 rgba(0,0,0,0.15)"
              objectFit="cover"
              width="100%"
              bgColor="border.100"
              height="100%"
            />
          )}
        </Box>
        <Flex
          height="60%"
          px="6"
          pt="4"
          pb="10"
          flexDirection="column"
          justifyContent={card.image ? 'space-between' : 'normal'}
          borderRadius="0 0 12px 12px"
          borderWidth={1}
          borderTopWidth={0}
          borderColor="border.300"
        >
          <Box minH={card.image ? '135px' : 'auto'} width="100%">
            <Heading
              key={`card-title-${language}`}
              noOfLines={2}
              color={mode('black', 'white')}
              as="h2"
              fontSize={{ base: 'lg', sm: '3xl' }}
              minHeight="48px"
            >
              {card.title}
            </Heading>
            <Text color="gray.700" fontSize="md" letterSpacing="0.05px" my={4}>
              {card.date}
            </Text>
            <Text
              key={`card-tagline-${language}`}
              noOfLines={3}
              mb="2"
              fontSize="md"
              color="rgba(26, 26, 26, 0.6)"
              minHeight="63px"
            >
              {card.description}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default BlogCard
