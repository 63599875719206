import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Link } from 'pages'
import { ReactComponent as Papik } from '../../assets/icons/papiktatik.svg'
import { ReactComponent as Reached } from 'assets/icons/reached.svg'
import TransactionBadge from 'components/TransactionBadge'
import AmountWithCurrency from 'components/AmountWithCurrency'

const Double = () => (
  <Flex ml="2" mr="2" alignItems="center">
    <Box mr="2" display="block" whiteSpace="nowrap" as="span">
      x 2
    </Box>
    <Papik height="30px" />
  </Flex>
)

const ListItem = ({
  data,
  dataLength,
  index,
  reachedText = 'Reached',
  goalText = 'Goal',
  variant,
  dateFromNow,
  Amount,
  donationPage,
  language,
  isMobile,
  isPeerToPeerFundraising,
  t,
}) => (
  <Flex
    justifyContent="space-between"
    borderBottomWidth={variant === 'donor' && index + 1 !== dataLength ? 1 : 0}
    borderStyle="solid"
    borderColor="border.100"
    py={variant === 'donor' ? '4' : variant === 'goal' ? '4' : '3'}
  >
    <Flex>
      {variant === 'goal' && (
        <Flex
          minW="24px"
          h="24px"
          bg="gray.300"
          borderRadius="100px"
          alignItems="center"
          justifyContent="center"
          mr={3}
        >
          <Text color="gray.800" fontSize="sm" fontWeight="semibold">
            {index + 1}
          </Text>
        </Flex>
      )}
      <Box>
        <Text fontSize="md" fontWeight="500" color="gray.800" mr={'2.5'}>
          {variant === 'goal' ? (
            <Flex>
              <Text as="span" mr="2">
                {goalText}:
              </Text>
              {Amount}{' '}
              {data.isReached && !isMobile && (
                <Text display="flex" as="span" color="gray.700" fontSize="md">
                  {' '}
                  <Reached style={{ margin: '0 4px' }} /> {reachedText}
                </Text>
              )}
            </Flex>
          ) : (
            <>
              {' '}
              {data.isAnonymous
                ? t('anonymous')
                : data.firstName + ' ' + data.lastName}
              {!isPeerToPeerFundraising && data?.peerProjectId ? (
                <TransactionBadge
                  title="Peer-to-peer"
                  target="_blank"
                  as={Link}
                  to={`/fundraisers/${data.peerProjectId}`}
                />
              ) : (
                !!data.subscriptionId &&
                data.subscriptionId !== 'NONE' &&
                !donationPage && <TransactionBadge title="monthly" />
              )}
            </>
          )}
        </Text>
        <Box display="block" fontSize="sm" as="span" color="gray.700">
          {variant === 'donor' ? (
            <>{!!dateFromNow && dateFromNow}</>
          ) : (
            data[`description_${language}`]
          )}
        </Box>
        {data.isReached && isMobile && (
          <Text display="flex" as="span" color="gray.700" fontSize="md" mt={3}>
            {' '}
            <Reached style={{ marginRight: '4px' }} /> {reachedText}
          </Text>
        )}
      </Box>
      {data.double && <Double />}
    </Flex>
    {variant === 'donor' && (
      <Box fontWeight="600" fontSize="md" as="span" color="gray.800">
        <AmountWithCurrency
          amount={data.donationToProject}
          currency={data.currency || undefined}
          currencyRate={data.currencyRate || undefined}
        />
      </Box>
    )}
  </Flex>
)

export default ListItem
