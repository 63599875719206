import { CATEGORY, GENERAL_USER_VISIBLE_STATUSES } from '_constants'
import { expertsSort, expertiseSort } from './sortingScripts'

export const applicationsListQuery = (
  {
    projectType = 'fundraisers',
    isRecurringChecked = false,
    category,
    status,
    search,
  },
  PAGE_SIZE = 16
) => {
  const must = () => {
    const mustArray = [
      {
        match:
          projectType === 'fundraisers' || projectType === 'peer-to-peer'
            ? {
                isProject: true,
              }
            : {
                isCollaboration: true,
              },
      },
    ]

    if (projectType === 'fundraisers' && !isRecurringChecked) {
      mustArray.push({
        match: {
          fundraisingType: 'oneTime',
        },
      })
    }

    if (projectType === 'peer-to-peer') {
      mustArray.push({
        match: {
          fundraisingType: 'peer-to-peer',
        },
      })
    }

    if (category) {
      mustArray.push({
        match: {
          category,
        },
      })
    }
    if (status) {
      mustArray.push({
        match: {
          status,
        },
      })
    }

    if (search) {
      mustArray.push({
        multi_match: {
          query: search,
          type: 'phrase_prefix',
          fields: ['applicationTitle', 'title_en', 'title_hy', 'title_ru'],
        },
      })
    }

    return mustArray
  }

  const mustNot = () => {
    const mustNot = [
      {
        terms: {
          status: ['live', 'ended', 'closed', 'terminated'],
        },
      },
    ]

    if (projectType === 'fundraisers' && isRecurringChecked) {
      mustNot.push({
        match: {
          fundraisingType: 'peer-to-peer',
        },
      })
    }

    return mustNot
  }

  return {
    size: PAGE_SIZE,
    from: 0,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `      
                if (doc['status.keyword'].value == 'submitted') {return  1}     
                else if (doc['status.keyword'].value == 'reviewed_by_ss') {return  2}     
                else if (doc['status.keyword'].value == 'reviewed_by_am') {return  3}       
                else if (doc['status.keyword'].value == 'reviewed_by_ab') {return  4} 
                else if (doc['status.keyword'].value == 'reviewed_by_bot') {return  5} 
                else if (doc['status.keyword'].value == 'project_page_development') {return  6} 
                else if (doc['status.keyword'].value == 'review_project_page') {return  7} 
                else if (doc['status.keyword'].value == 'published_by_owner') {return  8} 
                else if (doc['status.keyword'].value == 'launching_soon') {return  9} 
                else if (doc['status.keyword'].value == 'on_hold_by_am') {return  10} 
                else if (doc['status.keyword'].value == 'on_hold_by_tns') {return  11} 
                else if (doc['status.keyword'].value == 'rejected_by_ss') {return  12} 
                else if (doc['status.keyword'].value == 'rejected_by_am') {return  13} 
                else if (doc['status.keyword'].value == 'rejected_by_tns') {return  14} 
                else if (doc['status.keyword'].value == 'rejected_by_ab') {return  15} 
                else if (doc['status.keyword'].value == 'rejected_by_bot') {return  16} 
                return 20;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
          if (doc['status.keyword'].value == 'submitted') {return doc['createdAt'].value.getMillis()}     
           return doc['statusUpdatedAt'].value.getMillis();`,
          },
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: must(),
        must_not: mustNot(),
      },
    },
  }
}

export const adminProjectsQuery = (
  { type = 'fundraisers', category, status, search, projectType = 'oneTime' },
  PAGE_SIZE = 16
) => {
  const typeObject =
    type === 'fundraisers'
      ? {
          isProject: true,
        }
      : {
          isCollaboration: true,
        }
  const must = () => {
    const mustArray = [
      {
        match: typeObject,
      },
      {
        terms: {
          status: [
            'live',
            'ended',
            'implemented',
            'closed',
            'terminated',
            'waiting_for_transfer_confirmation',
          ],
        },
      },
    ]
    if (type === 'fundraisers') {
      mustArray.push({
        match: {
          fundraisingType: projectType,
        },
      })
    }
    if (category) {
      mustArray.push({
        match: {
          category,
        },
      })
    }
    if (status) {
      mustArray.push({
        match: {
          status,
        },
      })
    }

    if (search) {
      mustArray.push({
        multi_match: {
          query: search,
          type: 'phrase_prefix',
          fields: ['title_en', 'title_hy', 'title_ru', 'applicationTitle'],
        },
      })
    }
    return mustArray
  }
  return {
    from: 0,
    size: PAGE_SIZE,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `       
            if (doc['status.keyword'].value == 'live') {return  1}
            else if (doc['status.keyword'].value == 'ended') {return  2}
            else if (doc['status.keyword'].value == 'implemented') {return  3}
            else if (doc['status.keyword'].value == 'waiting_for_transfer_confirmation') {return  4}
            else if (doc['status.keyword'].value == 'closed') {return  5}
            else if (doc['status.keyword'].value == 'terminated') {return  6}
              return 6;`,
          },
          order: 'asc',
        },
      },
      { publishedAt: { order: 'desc' } },
    ],
    query: {
      bool: {
        must: must(),
      },
    },
  }
}

export const similarProjectsQuery = ({
  category,
  region,
  projectId,
  isCollaboration,
}) => ({
  size: 4,
  from: 0,
  sort: [
    {
      _script: {
        type: 'number',
        script: {
          lang: 'painless',
          source: `      
              if (doc['category.keyword'].value == '${category}') {return  1}
              else if (doc['region.keyword'].value == '${region}') {return  2}
              return doc['createdAt'].value.getMillis();`,
        },
        order: 'asc',
      },
    },
  ],
  query: {
    bool: {
      must: [
        {
          terms: {
            status: ['live'],
          },
        },
        {
          match: {
            isProject: !isCollaboration,
          },
        },
      ],
      must_not: [{ match: { id: projectId } }],
    },
  },
})

export const transactionsHistoryQuery = ({ from = 0, size = 10, email }) => {
  return {
    size,
    from,
    sort: [{ createdAt: { order: 'desc' } }],
    query: {
      bool: {
        must: [
          {
            match: {
              email,
            },
          },
          {
            exists: {
              field: 'projectId',
            },
          },
        ],
        must_not: [
          {
            match: {
              projectId: 'giftCard',
            },
          },
        ],
      },
    },
  }
}
export const expertsQuery = ({
  from = 0,
  size = 6,
  search,
  categories = [],
  directions = [],
  countryCode,
  languages,
  hrsPerWeek,
  isVolunteer,
  appliedFilters,
  isAdmin,
  status = 'approved',
}) => {
  const must = () => {
    const baseArr = [
      {
        match: {
          pk: 'expert',
        },
      },
    ]
    if (status !== 'all') {
      baseArr.push({
        match: {
          expert_status: status,
        },
      })
    }
    if (!isAdmin) {
      baseArr.push({
        match: {
          isPrivate: false,
        },
      })
    }
    if (isVolunteer) {
      baseArr.push({
        match: {
          isVolunteer: true,
        },
      })
    }
    if (categories?.length) {
      baseArr.push({
        terms: {
          'spheres.title_en': categories,
        },
      })
    }
    if (directions?.length) {
      baseArr.push({
        terms: {
          'interestedIn.interested_in': directions,
        },
      })
    }
    if (countryCode) {
      baseArr.push({
        match: {
          countryCode,
        },
      })
    }
    if (languages?.length) {
      baseArr.push({
        terms: {
          'selectedLanguage.language': languages.map((e) => e.toLowerCase()),
        },
      })
    }
    if (hrsPerWeek) {
      baseArr.push({
        match: {
          hrsPerWeek,
        },
      })
    }

    return baseArr
  }

  const sort = () => {
    if (search) {
      return [
        {
          _script: {
            type: 'number',
            script: {
              lang: 'painless',
              source: expertsSort({ search, directions, categories }),
            },
            order: 'desc',
          },
        },
      ]
    } else if (directions?.length || categories?.length) {
      return [
        {
          _script: {
            type: 'number',
            script: {
              lang: 'painless',
              source: expertiseSort({ directions, categories }),
            },
            order: 'desc',
          },
        },
      ]
    } else {
      return [
        { publishedAt: { order: 'desc' } },
      ]
    }
  }
  const query = () => {
    if (search) {
      const fieldsToMatch = [
        'firstName_en',
        'firstName_ru',
        'firstName_hy',
        'lastName_en',
        'lastName_hy',
        'lastName_ru',
        'spheres.title_ru',
        'spheres.title_en',
        'spheres.title_hy',
        'interestedIn.interested_in',
      ]
      const wildcardQueries = fieldsToMatch.map((field) => ({
        wildcard: {
          [field]: {
            value: `*${search}*`,
          },
        },
      }))

      const simple_query = {
        bool: {
          must: [
            {
              term: {
                pk: {
                  value: 'expert',
                },
              },
            },
            {
              bool: {
                should: [
                  ...fieldsToMatch.map((field) => ({
                    match: {
                      [field]: {
                        query: search,
                        fuzziness: 'AUTO',
                      },
                    },
                  })),
                  ...wildcardQueries,
                ],
                minimum_should_match: 1,
              },
            },
          ],
        },
      }
      if (!isAdmin) {
        simple_query.bool.must = [
          ...simple_query.bool.must,
          {
            match: {
              isPrivate: false,
            },
          },
        ]
      }
      if (appliedFilters) {
        return {
          bool: {
            must: [simple_query],
            filter: must(),
          },
        }
      }
      return simple_query
    }
    return {
      bool: {
        must: must(),
      },
    }
  }
  return {
    size,
    from,
    sort: sort(),
    query: query(),
  }
}

export const expertsDashboardQuery = ({
  from = 0,
  size = 6,
  isAdmin,
  status = 'approved',
}) => {
  const must = () => {
    const baseArr = [
      {
        match: {
          pk: 'expert',
        },
      },
    ]
    if (status !== 'all') {
      baseArr.push({
        match: {
          expert_status: status,
        },
      })
    }
    if (!isAdmin) {
      baseArr.push({
        match: {
          isPrivate: false,
        },
      })
    }
    return baseArr
  }
  const sort = () => {
    return [{ createdAt: { order: 'desc' } }]
  }
  const query = () => {
    return {
      bool: {
        must: must(),
      },
    }
  }
  return {
    size,
    from,
    sort: sort(),
    query: query(),
  }
}

export const projectsQuery = ({
  fundraisingType = 'oneTime',
  category,
  isAdmin,
  language = 'en',
}) => {
  const must_not_conditional = {}
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY[language],
    },
  }
  const fundOrCompany = () => {
    if (fundraisingType === 'foundations') {
      return [
        {
          match: {
            isCompany: true,
          },
        },
      ]
    }
    if (fundraisingType === 'individuals') {
      return [
        {
          match: {
            isIndividual: true,
          },
        },
      ]
    }
    return [
      {
        match: {
          fundraisingType: fundraisingType || 'oneTime',
        },
      },
      {
        match: {
          isCompany: false,
        },
      },
    ]
  }
  const size = 12
  const must = [
    {
      match: {
        isProject: true,
      },
    },
    {
      terms: {
        status: GENERAL_USER_VISIBLE_STATUSES,
      },
    },
    categoryTerms,
    ...fundOrCompany(),
  ]
  if (!isAdmin) {
    must.push({
      match: {
        isHidden: false,
      },
    })
  }

  if (fundraisingType !== 'individuals') {
    must_not_conditional.must_not = {
      term: {
        isIndividual: true,
      },
    }
  }

  return {
    size,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `int sortOrder = 0;       
          if (doc['status.keyword'].value == 'live') {sortOrder =  1}
          else if (doc['status.keyword'].value == 'launching_soon') {sortOrder =  2}
          else if (doc['status.keyword'].value == 'ended') {sortOrder =  3}
          else if (doc['status.keyword'].value == 'implemented') {sortOrder =  4}
          else if (doc['status.keyword'].value == 'closed') {sortOrder =  5}
          else if (doc['status.keyword'].value == 'terminated') {sortOrder =  6}
          sortOrder;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
          if (doc['status.keyword'].value == 'live') {return doc['publishedAt'].value.getMillis()}     
           return 0;`,
          },
          order: 'desc',
        },
      },
      { 'total.goalMetPercentage': { order: 'desc' } },
    ],
    query: {
      bool: {
        must: must,
        ...must_not_conditional,
      },
    },
  }
}

export const collaborationsQuery = (category, language) => {
  const categoryTerms = {
    terms: {
      category: category ? [category] : CATEGORY[language],
    },
  }
  return {
    size: 40,
    sort: [
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `int sortOrder = 0;       
            if (doc['status.keyword'].value == 'live') {sortOrder =  1}
            else if (doc['status.keyword'].value == 'launching_soon') {sortOrder =  2}
            else if (doc['status.keyword'].value == 'ended') {sortOrder =  3}
            else if (doc['status.keyword'].value == 'implemented') {sortOrder =  4}
            else if (doc['status.keyword'].value == 'closed') {sortOrder =  5}
            else if (doc['status.keyword'].value == 'terminated') {sortOrder =  6}
            sortOrder;`,
          },
          order: 'asc',
        },
      },
      {
        _script: {
          type: 'number',
          script: {
            lang: 'painless',
            source: `
            if (doc['status.keyword'].value == 'live') {
              if(doc['publishedAt'].size()==0) {return 10}
              return doc['publishedAt'].value.getMillis()}     
             return 0;`,
          },
          order: 'desc',
        },
      },
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              isCollaboration: true,
            },
          },
          {
            match: {
              isTestProject: false,
            },
          },
          {
            match: {
              isHidden: false,
            },
          },
          {
            terms: {
              status: GENERAL_USER_VISIBLE_STATUSES,
            },
          },
          categoryTerms,
        ],
      },
    },
  }
}

export const projectsByIds = (idList) => ({
  size: 24,
  query: {
    ids: {
      values: idList,
    },
  },
})
