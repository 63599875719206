import { Heading, Button, Flex } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link } from 'pages'

const DonationToRearmenia = () => {
  const { t } = useTranslation()
  return (
    <Flex py="5rem" direction="column" alignItems="center">
      <Heading mb="5" textAlign="center" as="h2" fontSize="3xl">
        {t('homepage@contributionHeading')}
      </Heading>
      <Link to="/fundraisers?type=oneTime">
        <Button mx="auto" variant="green">
          {t('homepage@contributionButtonText')}
        </Button>
      </Link>
    </Flex>
  )
}

export default DonationToRearmenia
