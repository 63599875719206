import { Button, Flex, Heading, Stack } from '@chakra-ui/react'
import { updatePassword } from 'aws-amplify/auth'
import { PasswordField } from 'components/PasswordField'
import { BlueSelect } from 'components/Select/BlueSelect'
import {
  SUPPORTED_CURRENCIES,
  useCurrencySettings,
} from 'contexts/CurrencySettingsContext'
import { LANGUAGES } from 'contexts/TranslationContext'
import { useCustomToast, useUpdateUser } from 'hooks'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export default function Account({ t, language, setLanguage, setValue, user }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const [loading, setLoading] = useState(false)
  const toast = useCustomToast()
  const { currency, changeDefaultCurrency } = useCurrencySettings()

  const submit = (data) => {
    setLoading(true)
    updatePassword({
      oldPassword: data.password,
      newPassword: data.newPassword,
    })
      .then(() => {
        toast({
          status: 'success',
          title: t('changePassword@success'),
          isClosable: true,
          duration: 5000,
        })
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (
          err.code === 'InvalidPasswordException' ||
          err.code === 'InvalidParameterException'
        ) {
          toast({
            status: 'error',
            title: t('changePassword@passwordRequirements'),
            isClosable: true,
            duration: 5000,
          })
        } else if (err.code === 'LimitExceededException') {
          toast({
            status: 'error',
            title: t('changePassword@limitExceeded'),
            isClosable: true,
            duration: 5000,
          })
        } else {
          toast({
            title: t('changePassword@incorrectPassword'),
            status: 'error',
            duration: 5000,
            position: 'top',
          })
        }
      })
  }

  const [selectedCurrency, setSelectedCurrency] = useState(currency.current)
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: language,
    title: t(`language@${language}Label`),
  })
  const { updateUserAsync, isLoading: isLoadingLangAndCurrencySave } =
    useUpdateUser()

  const handleLangAndCurrencySave = async () => {
    try {
      console.log('currency.current', currency.current)
      console.log('selectedCurrency', selectedCurrency)
      changeDefaultCurrency(selectedCurrency)
      if (selectedCurrency !== currency.current) {
        await updateUserAsync({
          id: user.id,
          currency: selectedCurrency,
        })
      }
      setLanguage(language, selectedLanguage.value)

      toast({
        status: 'success',
        title: t('profile@updatedSuccessfully'),
        isClosable: true,
        duration: 5000,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Heading
        lineHeight="base"
        fontSize="2xl"
        fontWeight="semibold"
        mb="22px"
        color="gray.800"
      >
        {t('changePassword@heading')}
      </Heading>

      <Stack spacing="6" maxW="331px">
        <PasswordField
          size="lg"
          bgColor="white"
          isInvalid={!!errors.password}
          errorMessage={t('changePassword@oldPasswordErrorMessage')}
          {...register('password', { required: true })}
          label={t('changePassword@oldPassword')}
          labelProps={{ background: 'transparent !important' }}
        />

        <PasswordField
          size="lg"
          bgColor="white"
          errorMessage={t('changePassword@newPasswordErrorMessage')}
          isInvalid={!!errors.newPassword}
          newPassword
          {...register('newPassword', {
            required: true,
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
            minLength: 8,
          })}
          label={t('changePassword@newPassword')}
          labelProps={{ background: 'transparent !important' }}
        />

        <Button
          variant="blue"
          size="lg"
          fontSize="md"
          isLoading={loading}
          onClick={handleSubmit(submit)}
        >
          {t('changePassword@submit')}
        </Button>
      </Stack>

      <Heading
        lineHeight="base"
        fontSize="2xl"
        fontWeight="semibold"
        mb="22px"
        mt={8}
        color="gray.800"
      >
        {t('profile@languageAndCurrency')}
      </Heading>

      <Stack spacing={{ base: 8, sm: '42px' }} maxW="331px">
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 4, sm: 5 }}
        >
          <BlueSelect
            variant="border"
            width="156px"
            display="flex"
            type="language"
            bgColor="white"
            rounded="xl"
            label={selectedLanguage.title}
            options={Object.entries(LANGUAGES).map(([key]) => ({
              title: t(`language@${key}Label`),
              value: key,
            }))}
            selectOption={(option) => {
              setSelectedLanguage({
                value: option,
                title: t(`language@${option}Label`),
              })
              setValue('language', option)
            }}
            currentSelectedOption={selectedLanguage.value}
            offset={[0, -6]}
            menuButtonProps={{ marginTop: 0, type: 'button' }}
          />

          <BlueSelect
            variant="border"
            width="156px"
            display="flex"
            type="currency"
            bgColor="white"
            rounded="xl"
            label={selectedCurrency}
            options={SUPPORTED_CURRENCIES.map((option) => ({
              title: t(`currency@${option}`),
              value: option,
            }))}
            selectOption={(option) => {
              setSelectedCurrency(option)
              setValue('currency', option)
            }}
            currentSelectedOption={selectedCurrency}
            offset={[0, -6]}
            menuButtonProps={{ marginTop: 0, type: 'button' }}
          />
        </Flex>

        <Button
          variant="blue"
          size="lg"
          fontSize="md"
          onClick={handleLangAndCurrencySave}
          isLoading={isLoadingLangAndCurrencySave}
        >
          {t('save')}
        </Button>
      </Stack>
    </>
  )
}
