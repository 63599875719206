import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
const w = window.innerWidth
const MainLayout = ({
  children,
  overflow = 'hidden',
  disableLinks = false,
  onClick,
  ...rest
}) => (
  <Box w="full" overflow={{ lg: overflow, base: 'hidden' }} {...rest}>
    {w > 481 && <Navbar disableLinks={disableLinks} onClick={onClick} />}
    {children}
  </Box>
)

export default MainLayout
