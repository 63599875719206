import React from 'react'
import {
  Button,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { Link } from 'pages'
import FilteredData from '../FilteredData'
import Space from '../Space'
import NoScrollbar from 'components/NoScrollbar'
import { useProjectsByUserId, useUser } from 'hooks'
import { useTranslation } from 'contexts/TranslationContext'
import {
  useCollaborationsDraftsByUserId,
  useDeleteDraft,
} from 'core/Draft/hooks'
import { useModal } from 'providers/ModalProvider'

const w = window.innerWidth

const TABS = [
  {
    id: 1,
    title: 'profile@all',
  },
  {
    id: 2,
    title: 'profile@active',
  },
  {
    id: 3,
    title: 'profile@completed',
  },
  {
    id: 4,
    title: 'profile@inKind@submitted',
  },
  {
    id: 5,
    title: 'profile@inKind@onHold',
  },
  {
    id: 6,
    title: 'profile@inKind@draft',
  },
]
const SingleTab = ({ title }) => (
  <NoScrollbar
    Component={Tab}
    id="a"
    minW="136px"
    py={2}
    mb={0}
    _active={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _focus={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _selected={{
      backgroundColor: 'white',
      borderRadius: 'lg',
    }}
    lineHeight="shorter"
    textAlign="center"
    fontSize="lg"
    fontWeight="semibold"
    color="gray.900"
    border="none"
    whiteSpace="nowrap"
  >
    {title}
  </NoScrollbar>
)

export default function InKind() {
  const { user } = useUser()
  const { t } = useTranslation()
  const { projects, isLoading } = useProjectsByUserId({
    owner: user.id,
    filter: {
      isInKind: { eq: true },
    },
  })
  const { data: draftData, refetch } = useCollaborationsDraftsByUserId(user.id)
  const { mutate: deleteDraft, isLoading: isDraftDeletionLoading } =
    useDeleteDraft()
  const { openModal, closeModal } = useModal()
  const onDraftDelete = (id) => {
    openModal('deleteDraft', null, false, () => {}, {
      onDelete: () => {
        deleteDraft(id, {
          onSuccess: () => {
            refetch()
          },
          onError: (err) => {
            console.log('error', err)
          },
          onSettled: () => {
            closeModal()
          },
        })
      },
      isDraftDeletionLoading,
    })
  }

  if (isLoading)
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        margin="auto"
        h={{ md: '100%', base: '300px' }}
      >
        <Spinner color="blue.400" />
      </Flex>
    )
  return (
    <>
      {w < 481 ? (
        <Flex h="100vh" flexDirection="column" justifyContent="center" px={5}>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty1')}
          </Text>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty2')}
          </Text>
        </Flex>
      ) : (
        <>
          {projects?.length > 0 && (
            <Link to="/pre-create-project">
              <Button
                display={{ base: 'inline-flex', md: 'none' }}
                color="blue.400"
                minH="50px"
                variant="transparent"
                textTransform="capitalize"
              >
                {t('profile@createProject')}
              </Button>
            </Link>
          )}
          <Space title={t('profile@inKindDonorSpace')}>
            <Tabs>
              <Flex justifyContent="space-between" alignItems="center">
                <TabList
                  sx={{
                    '&': {
                      msOverflowStyle: 'none',
                      scrollbarWidth: 'none',
                    },
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  borderRadius="lg"
                  border="none"
                  bg="#D9E0EA"
                  p={1.5}
                >
                  {TABS.map(({ id, title }) => (
                    <SingleTab key={id} title={t(title)} />
                  ))}
                </TabList>
                {projects?.length > 0 && (
                  <Link to="/pre-create-project">
                    <Button
                      display={{ base: 'none', md: 'inline-flex' }}
                      variant="border"
                      lineHeight="none"
                      textTransform="capitalize"
                      fontSize="sm"
                      fontWeight="medium"
                      color="#292929"
                    >
                      {t('createEditProject@create')}
                    </Button>
                  </Link>
                )}
              </Flex>

              <TabPanels>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={['all']}
                    isInKind={true}
                    data={projects.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    desktopMT="104px"
                  />
                </TabPanel>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={[
                      'live',
                      'launching_soon',
                      'waiting_for_transfer_confirmation',
                    ]}
                    isInKind={true}
                    data={projects.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    desktopMT="104px"
                  />
                </TabPanel>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={['ended', 'closed', 'terminated']}
                    isInKind={true}
                    data={projects.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    desktopMT="104px"
                  />
                </TabPanel>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={[
                      'submitted',
                      'reviewed_by_ss',
                      'reviewed_by_am',
                      'reviewed_by_tns',
                      'reviewed_by_bot',
                      'project_page_development',
                      'review_project_page',
                      'published_by_owner',
                    ]}
                    isInKind={true}
                    data={projects.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    desktopMT="104px"
                  />
                </TabPanel>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={[
                      'on_hold_by_am',
                      'on_hold_by_tns',
                      'rejected_by_ss',
                      'rejected_by_am',
                      'rejected_by_tns',
                      'rejected_by_bot',
                      'project_page_development',
                      'review_project_page',
                    ]}
                    isInKind={true}
                    data={projects.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    desktopMT="104px"
                  />
                </TabPanel>
                <TabPanel p="0" position="relative" pt="8">
                  <FilteredData
                    type="collaborations"
                    filterStatus={['all']}
                    isInKind={true}
                    data={draftData?.items?.filter(({ isInKind }) => isInKind)}
                    emptyButtonText={t('profile@noInitiative@isInKind@button')}
                    onDraftDelete={onDraftDelete}
                    desktopMT="104px"
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Space>
        </>
      )}
    </>
  )
}
