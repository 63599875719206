import React, { useRef, useEffect, useState } from 'react'
import {
  Container,
  Spinner,
  Text,
  Flex,
  Heading,
  Box,
  Checkbox,
} from '@chakra-ui/react'
import { MainLayout } from 'components/Layouts'
import ListView from 'components/ListView'
import {
  useUser,
  useProjects,
  useQueryParams,
  useCustomToast,
  useUpdateProject,
} from 'hooks'
import Header from '../ApplicationsDashboard/Header'
import { adminProjectsQuery } from 'elastic/queries'
import ImageWrapper from 'components/ImageWrapper'
import { useTranslation } from 'contexts/TranslationContext'
import StatusSelect from 'pages/ApplicationsDashboard/StatusSelect'
import moment from 'moment'
import { Link, useNavigation } from 'pages'
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg'
import Pagination from 'components/Pagination/Index'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import LineClamp from 'components/LineClamp'

const PAGE_SIZE = 16
const projectPageURL = (isProject, id, slug) =>
  `/${isProject ? 'fundraisers' : 'collaborations'}/${slug || id}`

const editProjectPageURL = (isProject, id) =>
  `/edit-${isProject ? 'fundraisers' : 'collaborations'}/${id}/content`

const TABS = ['oneTime', 'recurring']

const ProjectsDashboard = ({ type }) => {
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('page')) || 1
  )
  const { language } = useTranslation()
  const { user } = useUser()
  const { projects, isLoading, setFilters, filters } = useProjects(
    adminProjectsQuery({ type }),
    true,
    'fundraisers'
  )
  const toast = useCustomToast()
  const { navigationPush } = useNavigation()
  const [tabIndex, setTabIndex] = useState(0)
  const { updateProject, isLoading: isUpdateLoading } = useUpdateProject()
  const [localFilters, setLocalFilters] = useState({
    category: '',
    status: '',
    search: '',
    projectType: 'oneTime',
  })
  const searchRef = useRef(null)
  const onUpdate = (input) => {
    updateProject(input, {
      onSuccess: () => {
        toast({
          title: `Project updated successfully`,
          status: 'success',
          duration: 3000,
        })
      },
      onError: () => {
        toast({
          title: 'Project update failed',
          status: 'error',
          duration: 3000,
        })
      },
    })
  }
  useEffect(() => {
    setLocalFilters({ ...localFilters, projectType: TABS[tabIndex] })
  }, [tabIndex])

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!localFilters.search) {
      searchRef.current.value = ''
    }
    let updatingObject = {
      ...filters,
      ...adminProjectsQuery(localFilters),
      from: (currentPage - 1) * PAGE_SIZE,
    }
    if (!_.isEqual(filters, updatingObject)) {
      setCurrentPage(1)
    }
    setFilters(updatingObject)
  }, [localFilters])

  useEffect(() => {
    if (!user.isAdmin) {
      return
    }
    const basePath = `/${type}-dashboard`
    navigationPush(basePath + `?page=${currentPage}`)
    setFilters({ ...filters, from: (currentPage - 1) * PAGE_SIZE })
  }, [currentPage])
  if (!user.isAdmin) {
    return <Redirect to={`/`} />
  }
  return (
    <MainLayout>
      <Container w="full" maxW="90%" minH="calc(100vh - 96px)">
        <Header
          isLoading={isLoading}
          type={type}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabs={TABS}
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
          searchRef={searchRef}
        />

        {isLoading ? (
          <Flex minH="520px" w="full" align="center" justifyContent="center">
            <Spinner color="blue.300" />
          </Flex>
        ) : (
          <>
            {projects.total.value === 0 || !projects.items.length ? (
              <Flex alignItems="center" justifyContent="center" w="full">
                <Heading as="h2" fontSize="4xl" color="blue.300">
                  {t('noResults')}
                </Heading>
              </Flex>
            ) : (
              <>
                {/* eslint-disable */}
                <ListView
                  isLoading={isUpdateLoading}
                  head={[
                    '',
                    <Text textAlign="left">{t('dashboard@title')}</Text>,
                    t('dashboard@status'),
                    t('dashboard@lastUpdate'),
                    t('dashboard@liveDate'),
                    t('dashboard@featured'),
                    t('dashboard@popular'),
                    t('dashboard@contactName'),
                    t('dashboard@phoneNumber'),
                    '',
                  ]}
                  body={projects.items.map((project, index) => [
                    <Link
                      key={project.id}
                      to={projectPageURL(
                        project.isProject,
                        project.id,
                        project.slug
                      )}
                    >
                      {project.cover ? (
                        <ImageWrapper
                          src={`/thumbnail/small/${project.cover}`}
                          width="56px"
                          height="56px"
                          objectFit="cover"
                          borderRadius="8px"
                        />
                      ) : (
                        <Box
                          background="gray"
                          borderRadius="8px"
                          w="56px"
                          h="56px"
                        />
                      )}
                    </Link>,

                    <Link
                      to={projectPageURL(
                        project.isProject,
                        project.id,
                        project.slug
                      )}
                    >
                      <LineClamp
                        Component={Text}
                        textDecor="underline"
                        textAlign="left"
                      >
                        {project[`title_${language}`] ||
                          project.title_en ||
                          project.title_hy ||
                          project.title_ru ||
                          project.applicationTitle}
                      </LineClamp>
                    </Link>,
                    <StatusSelect
                      project={project}
                      defaultStatus={project.status}
                      onChange={onUpdate}
                    />,
                    moment(project.updatedAt).format('DD.MM.YYYY'),
                    moment(project.publishedAt).format('DD. MM.YYYY'),
                    <Checkbox
                      defaultChecked={project.featured === 'true'}
                      id={project.id}
                      type="featured"
                      colorScheme="orange"
                      isDisabled={project.status !== 'live'}
                      onChange={() => {
                        onUpdate({
                          id: project.id,
                          featured:
                            project.featured === 'true' ? 'false' : 'true',
                        })
                      }}
                    />,
                    <Checkbox
                      defaultChecked={project.popular === 'true'}
                      id={project.id}
                      type="popular"
                      isDisabled={project.status !== 'live'}
                      colorScheme="orange"
                      onChange={() => {
                        onUpdate({
                          id: project.id,
                          popular:
                            project.popular === 'true' ? 'false' : 'true',
                        })
                      }}
                    />,
                    project.ownerName,
                    project.phoneNumber,
                    <Link
                      to={editProjectPageURL(project.isProject, project.id)}
                    >
                      <PenIcon />
                    </Link>,
                  ])}
                />
                {/* eslint-disable */}
                <Pagination
                  total={projects.total.value}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  size={PAGE_SIZE}
                />
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  )
}

const WithType =
  (type) =>
  ({ props }) =>
    <ProjectsDashboard type={type} {...props} />

export default WithType
