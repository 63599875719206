import {
  useRadioGroup,
  Box,
  Flex,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import {
  useCurrencySettings,
  thousandSeparator,
} from 'contexts/CurrencySettingsContext'
import * as React from 'react'
import { RadioOption } from './RadioOption'

export const RadioGroup = ({
  name,
  options,
  onChange,
  defaultValue,
  price,
  other,
  setValue,
  donation,
  t,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  })
  const { currency } = useCurrencySettings()

  return (
    <Flex
      p={{ base: 2, sm: 4 }}
      gap={{ base: '8px', sm: '10px' }}
      flexDirection="column"
      maxW="full"
      borderRadius={8}
      overflow="hidden"
      bg="#EAECEF"
      {...getRootProps(rest)}
    >
      <Flex>
        {Object.entries(options).map(([key, value]) => (
          <RadioOption key={key} {...getRadioProps({ value: key })}>
            <Flex alignItems="center" direction="column" borderRadius="12px">
              <Box
                fontWeight="600"
                as="span"
                fontSize={{ base: 'md', sm: 'lg' }}
              >
                {key === 'other' ? value : `${value}%`}
              </Box>
              <Box as="span" fontSize="sm">
                {key !== 'other' &&
                  thousandSeparator((price * value) / 100, currency.current) +
                    ` ${currency.current}`}
              </Box>
            </Flex>
          </RadioOption>
        ))}
      </Flex>
      {other && (
        <FormControl>
          <InputGroup alignItems="center">
            <InputLeftElement
              top="unset"
              width="100px"
              pointerEvents="none"
              children={
                <Box as="span" fontSize="xl">
                  ({currency.current})
                </Box>
              }
            />
            <Input
              _focus={{
                boxShadow: 'none',
              }}
              maxLength="12"
              _hover={{
                borderColor: 'blue.300',
              }}
              bg="white"
              borderColor="border.100"
              value={thousandSeparator(donation.reArmenia)}
              pattern="[0-9,]*"
              onChange={(e) =>
                setValue(
                  'reArmenia',
                  e.target.value.replace(/[^0-9]|^0+(?!$)/g, '')
                )
              }
              pl="6.5rem"
              textAlign="right"
              fontSize="xl"
              height="60px"
            />
          </InputGroup>
        </FormControl>
      )}
    </Flex>
  )
}
