import { Flex, Button } from '@chakra-ui/react'
import { Pagination } from 'swiper'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'contexts/TranslationContext'
import { Link, useNavigation } from 'pages'
import BlogCard from '../BlogCard'
import ProjectCard from '../ProjectCard'
import 'swiper/css'
import 'swiper/css/pagination'
import ProjectSkeletonCard from 'components/ProjectSkeletonCard'
import {
  useCurrencySettings,
  CurrencyConverter,
  thousandSeparator,
  convert,
} from 'contexts/CurrencySettingsContext'
import { calculateProjectDurationLeft } from 'hooks'
import useGetNeeds from 'hooks/needs/useGetNeeds'
import { categoriesFilter } from 'hooks/needs/helpers'

const w = window.innerWidth

const filter = (swiper) =>
  swiper?.filter((project) => project.status === 'live')

const sort = (swiper) =>
  swiper?.sort((a, b) =>
    a.isProject
      ? b.total.goalMetPercentage - a.goalMetPercentage
      : b.collaboratorsTotal - a.collaboratorsTotal
  )
export default function Swiper({
  isSM,
  type,
  swiper,
  title,
  onBeforeInit,
  isLoading,
}) {
  const { t, language } = useTranslation()
  const { currency, settings, changeDefaultCurrency } = useCurrencySettings()
  const { navigationPush } = useNavigation()
  const { data: needCategories } = useGetNeeds()
  return (
    <ReactSwiper
      modules={[Pagination]}
      id={`swiper-${type}-${title}`}
      onBeforeInit={onBeforeInit}
      navigation
      centeredSlides={isSM}
      slidesPerView={
        w < 481 ? 1.2 : w < 768 ? 1.6 : w < 992 ? 2 : w < 1280 ? 2.5 : 3.4
      }
      spaceBetween={isSM ? 15 : 40}
      freeMode={true}
      className={'mySwiper'}
    >
      {isLoading
        ? [1, 2, 3, 4].map((item) => (
            <SwiperSlide key={item}>
              <ProjectSkeletonCard />
            </SwiperSlide>
          ))
        : sort(filter(swiper)).map((slide, index) => (
            <SwiperSlide key={`id-${slide.id}-${index}`}>
              {type === 'blog' ? (
                <BlogCard card={slide} />
              ) : (
                <ProjectCard
                  card={slide}
                  ml="5px"
                  goalPrice={slide.activeGoal?.amount || slide.goal}
                  raisedMoney={thousandSeparator(
                    convert({
                      amount: slide.amount,
                      currencyRate: settings?.currency[currency.current],
                    }),
                    currency.current
                  )}
                  thousandSeparatorText={thousandSeparator(
                    currency.current === 'AMD'
                      ? slide.amount + Number(slide.updateAmount)
                      : slide.amount /
                          Number(settings?.currency[currency.current]) +
                          Number(slide.updateAmount),
                    currency.current
                  )}
                  needCategories={needCategories?.filter(
                    categoriesFilter(slide)
                  )}
                  inNeedOfText={t('inNeedOf')}
                  type={type}
                  language={language}
                  calculatedProjectDurationLeft={calculateProjectDurationLeft({
                    t,
                    campaignImplementorPeriod_en:
                      slide.campaignImplementorPeriod_en,
                    fundraisingType: slide.fundraisingType,
                  })}
                  currency={currency}
                  settings={settings}
                  changeDefaultCurrency={changeDefaultCurrency}
                  navigationPush={navigationPush}
                  cardStatusText={t(`status@${slide.status}`)}
                  cardCategoryText={t(`category@${slide.category}`)}
                  editText={t('edit')}
                  unsubscribeText={t('unsubscribe')}
                  monthlyGoalText={t('monthlyGoal')}
                  collaborationProposalsText={t(
                    slide?.collaboratorsTotal === 1
                      ? 'collaboration proposal'
                      : 'collaboration proposals'
                  )}
                  collaborationProposalsTextRuEdit={t('collaboration proposals')
                    .split('...')[1]
                    .replace('запросов', 'запроса')}
                />
              )}
            </SwiperSlide>
          ))}
      {swiper.filter((item) => item.status === 'ended').length > 0 &&
        sort(swiper?.filter((item) => item.status === 'ended')).map(
          (ended, idx) => (
            <SwiperSlide key={`id-${ended.id}-${idx}`}>
              <ProjectCard
                card={ended}
                goalPrice={CurrencyConverter({
                  amount: ended.activeGoal?.amount || ended.goal,
                  currency: currency.current,
                })}
                raisedMoney={thousandSeparator(
                  convert({
                    amount: ended.amount,
                    currencyRate: settings?.currency[currency.current],
                  }),
                  currency.current
                )}
                ml="5px"
                needCategories={needCategories?.filter(categoriesFilter(ended))}
                inNeedOfText={t('inNeedOf')}
                thousandSeparatorText={thousandSeparator(
                  currency.current === 'AMD'
                    ? ended.amount + Number(ended.updateAmount)
                    : ended.amount /
                        Number(settings?.currency[currency.current]) +
                        Number(ended.updateAmount),
                  currency.current
                )}
                type={type}
                monthlyGoalText={t('monthlyGoal')}
                language={language}
                calculatedProjectDurationLeft={calculateProjectDurationLeft({
                  t,
                  campaignImplementorPeriod_en:
                    ended.campaignImplementorPeriod_en,
                  fundraisingType: ended.fundraisingType,
                })}
                currency={currency}
                settings={settings}
                changeDefaultCurrency={changeDefaultCurrency}
                navigationPush={navigationPush}
                cardStatusText={t(`status@${ended.status}`)}
                cardCategoryText={t(`category@${ended.category}`)}
                editText={t('edit')}
                unsubscribeText={t('unsubscribe')}
                collaborationProposalsText={t(
                  ended?.collaboratorsTotal === 1
                    ? 'collaboration proposal'
                    : 'collaboration proposals'
                )}
                collaborationProposalsTextRuEdit={t('collaboration proposals')
                  .split('...')[1]
                  .replace('запросов', 'запроса')}
              />
            </SwiperSlide>
          )
        )}
      <Flex
        w="full"
        justifyContent="center"
        display={{ base: 'flex', sm: 'none' }}
        alignItems="center"
      >
        <Button mt="8" fontSize="md">
          <Link to={type === 'project' ? '/fundraisers' : '/collaborations'}>
            {t('seeAll')}
          </Link>
        </Button>
      </Flex>
    </ReactSwiper>
  )
}
