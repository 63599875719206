import { useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import Hammer from 'react-hammerjs'
import Remove from './Remove'

const w = window.innerWidth

export default function HammerWrapper({
  children,
  creditCard,
  removable = true,
}) {
  const pan = useRef({ right: false, left: false })
  const [isPanned, togglePanned] = useState(false)

  const onPan = (e) => {
    if (e.additionalEvent === 'panleft') {
      if (pan.current.left) return
      pan.current.left = true
      togglePanned(true)
      setTimeout(() => {
        pan.current.left = false
      }, 100)
    }
    if (e.additionalEvent === 'panright') {
      if (pan.current.right) return
      pan.current.right = true
      togglePanned(false)
      setTimeout(() => {
        pan.current.right = false
      }, 100)
    }
  }

  if (w > 481 || !removable) {
    return children
  }

  return (
    <Hammer onPan={onPan}>
      <Box position="relative">
        <Box
          transform={`translateX(${isPanned ? '-80px' : '0px'})`}
          transition="transform 300ms"
          position="relative"
          zIndex={1}
        >
          {children}
        </Box>

        {creditCard && (
          <Remove
            onCancel={() => togglePanned(false)}
            creditCard={creditCard}
          />
        )}
      </Box>
    </Hammer>
  )
}
