import React from 'react'
import { Heading, Box, HStack, Button } from '@chakra-ui/react'
import { useTranslation } from 'contexts/TranslationContext'
import UserCard from './UserCard'
import { Link } from 'pages'
import NoScrollbar from 'components/NoScrollbar'

const DATA = [
  {
    name: 'Bill',
    lastName: 'Gates',
    position: 'Co-Founder, Microsoft',
    member: 'chairman',
    photo: 'BillGates.jpg',
  },
  {
    name: 'Felix',
    lastName: 'Chambers',
    position: 'Founding chairperson, Professor',
    photo: 'Felix Chambers-min.jpg',
  },
  {
    name: 'Mateo',
    lastName: 'Navarro',
    position: 'CEO, BPO',
    member: 'board',
    photo: 'Mateo Navarro-min.jpg',
  },
]

const BoardOfTrusters = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Heading px="5" as="h2" mb="6" fontSize="1.625rem">
        {t('about@boardTrust')}
      </Heading>
      <NoScrollbar
        Component={HStack}
        alignItems="baseline"
        overflowX="scroll"
        flexWrap="nowrap"
        // ml="5"
        px="5"
        position="relative"
        spacing="5"
      >
        {DATA.map((item) => (
          <Box minW="140px" key={item.name}>
            <UserCard
              photo={item.photo}
              name={item.name}
              position={item.position}
              lastName={item.lastName}
              member={item.member}
            />
          </Box>
        ))}
      </NoScrollbar>
      <Link to="/about-us">
        <Button ml="5" color="blue.300" variant="transparent">
          {t('see everyone')}
        </Button>
      </Link>
    </Box>
  )
}

export default BoardOfTrusters
