import React from 'react'
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { Link } from 'pages'
import FilteredData from '../FilteredData'
import NoScrollbar from 'components/NoScrollbar'

const w = window.innerWidth

const TABS = [
  {
    id: 1,
    title: 'profile@all',
  },
  {
    id: 2,
    title: 'profile@active',
  },
  {
    id: 3,
    title: 'profile@completed',
  },
  {
    id: 4,
    title: 'profile@submitted',
  },
  {
    id: 5,
    title: 'profile@onHold',
  },
  {
    id: 6,
    title: 'status@draft',
  },
]
const SingleTab = ({ title }) => (
  <NoScrollbar
    Component={Tab}
    id="a"
    minW="136px"
    py={2}
    mb={0}
    _active={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _focus={{ backgroundColor: 'white', borderRadius: 'lg' }}
    _selected={{
      backgroundColor: 'white',
      borderRadius: 'lg',
    }}
    textTransform="capitalize"
    lineHeight="shorter"
    textAlign="center"
    fontSize="lg"
    fontWeight="semibold"
    color="gray.900"
    border="none"
    whiteSpace="nowrap"
  >
    {title}
  </NoScrollbar>
)

export default function Collaborations({
  projects,
  onDraftDelete,
  draftData,
  t,
}) {
  return (
    <>
      {w < 481 ? (
        <Flex h="100vh" flexDirection="column" justifyContent="center" px={5}>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty1')}
          </Text>
          <Text
            textAlign="center"
            lineHeight="tall"
            fontSize="lg"
            color="gray.700"
          >
            {t('profile@mobileSpace@empty2')}
          </Text>
        </Flex>
      ) : (
        <>
          {projects?.length > 0 && (
            <Link to="/pre-create-project">
              <Button
                display={{ base: 'inline-flex', md: 'none' }}
                color="blue.400"
                minH="50px"
                variant="transparent"
                textTransform="capitalize"
              >
                {t('profile@createProject')}
              </Button>
            </Link>
          )}

          <Tabs>
            <Flex justifyContent="space-between" alignItems="center">
              <TabList
                sx={{
                  '&': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                  },
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
                borderRadius="lg"
                border="none"
                bg="#D9E0EA"
                p={1.5}
              >
                {TABS.map(({ id, title }) => (
                  <SingleTab key={id} title={t(title)} />
                ))}
              </TabList>
              {projects?.length > 0 && (
                <Link to="/pre-create-project">
                  <Button
                    display={{ base: 'none', md: 'inline-flex' }}
                    variant="border"
                    lineHeight="none"
                    textTransform="capitalize"
                    fontSize="sm"
                    fontWeight="medium"
                    color="#292929"
                  >
                    {t('createEditProject@create')}
                  </Button>
                </Link>
              )}
            </Flex>

            <TabPanels>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={['all']}
                  data={projects.filter(
                    ({ isCollaboration }) => isCollaboration
                  )}
                  emptyButtonText={t('profile@createInitiative')}
                  desktopMT="104px"
                />
              </TabPanel>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={[
                    'live',
                    'launching_soon',
                    'waiting_for_transfer_confirmation',
                  ]}
                  data={projects.filter(
                    ({ isCollaboration }) => isCollaboration
                  )}
                  emptyButtonText={t('profile@createInitiative')}
                  desktopMT="104px"
                />
              </TabPanel>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={['ended', 'closed', 'terminated']}
                  data={projects.filter(
                    ({ isCollaboration }) => isCollaboration
                  )}
                  emptyButtonText={t('profile@createInitiative')}
                  desktopMT="104px"
                />
              </TabPanel>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={[
                    'submitted',
                    'reviewed_by_ss',
                    'reviewed_by_am',
                    'reviewed_by_tns',
                    'reviewed_by_bot',
                    'project_page_development',
                    'review_project_page',
                    'published_by_owner',
                  ]}
                  data={projects.filter(
                    ({ isCollaboration }) => isCollaboration
                  )}
                  emptyButtonText={t('profile@createInitiative')}
                  desktopMT="104px"
                />
              </TabPanel>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={[
                    'on_hold_by_am',
                    'on_hold_by_tns',
                    'rejected_by_ss',
                    'rejected_by_am',
                    'rejected_by_tns',
                    'rejected_by_bot',
                    'project_page_development',
                    'review_project_page',
                  ]}
                  data={projects.filter(
                    ({ isCollaboration }) => isCollaboration
                  )}
                  emptyButtonText={t('profile@createInitiative')}
                  desktopMT="104px"
                />
              </TabPanel>
              <TabPanel p="0" position="relative" pt="8">
                <FilteredData
                  type="collaborations"
                  filterStatus={['all']}
                  data={draftData?.items}
                  emptyButtonText={t('profile@createInitiative')}
                  onDraftDelete={onDraftDelete}
                  desktopMT="104px"
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </>
  )
}
