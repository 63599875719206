import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import ReactGA from 'react-ga4'
import { useQueryParams } from 'hooks'
import {
  SignUpModal,
  SignInModal,
  VerifySignUpModal,
  VerifyResetPasswordModal,
  ResetPasswordModal,
  SubscriptionModal,
  DraftModal,
  DeleteDraftModal,
  RedeemGiftCardModal,
  ExpertsConnectModal,
  ListSubscriptions,
  ApplyExpertModal,
  ExpertNotificationModal,
  ExpertCardModal,
  ChoosePeerToPeerFundraiser,
} from 'modals'
import {
  SIGN_IN_MODAL,
  SIGN_UP_MODAL,
  VERIFY_MODAL,
  FORGOT_PASSWORD_MODAL,
  RESET_VERIFY_MODAL,
  SUBSCRIBE_MODAL,
  DRAFT_MODAL,
  DELETE_DRAFT_MODAL,
  REDEEM_GIFT_CARD_MODAL,
  CONNECT_MODAL,
  LIST_SUBSCRIPTIONS_MODAL,
  APPLY_EXPERT_MODAL,
  EXPERT_NOTIFICATION_MODAL,
  EXPERT_CARD_MODAL,
  CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL,
} from 'constants/modals'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/TranslationContext'

const MODALS = {
  [SIGN_IN_MODAL]: SignInModal,
  [SIGN_UP_MODAL]: SignUpModal,
  [VERIFY_MODAL]: VerifySignUpModal,
  [FORGOT_PASSWORD_MODAL]: ResetPasswordModal,
  [RESET_VERIFY_MODAL]: VerifyResetPasswordModal,
  [SUBSCRIBE_MODAL]: SubscriptionModal,
  [DRAFT_MODAL]: DraftModal,
  [DELETE_DRAFT_MODAL]: DeleteDraftModal,
  [REDEEM_GIFT_CARD_MODAL]: RedeemGiftCardModal,
  [CONNECT_MODAL]: ExpertsConnectModal,
  [LIST_SUBSCRIPTIONS_MODAL]: ListSubscriptions,
  [APPLY_EXPERT_MODAL]: ApplyExpertModal,
  [EXPERT_NOTIFICATION_MODAL]: ExpertNotificationModal,
  [EXPERT_CARD_MODAL]: ExpertCardModal,
  [CHOOSE_PEER_TO_PEER_FUNDRAISER_MODAL]: ChoosePeerToPeerFundraiser,
}
const DEFAULT_MODAL = 'signIn'

export const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
})
export const useModal = () => useContext(ModalContext)

const getPath = (pathname, search, action, isOpen, params) => {
  let result =
    pathname.slice(3, pathname.length) === '/'
      ? ''
      : pathname.slice(3, pathname.length)
  const query =
    typeof search === 'string'
      ? search
          .replaceAll(`?action=${params.get('action')}&`, '?')
          .replaceAll(`?action=${params.get('action')}`, '')
          .replaceAll(`&action=${params.get('action')}`, '')
      : search

  if (query) {
    result += query
  }

  if (isOpen) {
    result += query ? '&' : '?'
    result += `action=${action}`
  }

  return result
}
const useNavigation = () => {
  const history = useHistory()
  const { language } = useTranslation()

  const navigationPush = (to = '') => history.push(`/${language}${to}`)
  const navigationReplace = (to = '') => history.replace(`/${language}${to}`)

  return {
    navigationPush,
    navigationReplace,
  }
}
const extractActionQuery = (url) => {
  const regex = /\?(?:\w+=\w+&)*action=(\w+)/
  const match = url.match(regex)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

const ModalProvider = ({ children }) => {
  const { language } = useTranslation()
  const [modal, setModal] = useState({
    name: DEFAULT_MODAL,
    modalProps: {},
    modalWrapperProps: {},
    isOpen: false,
  })
  const [email, setEmail] = useState()
  const [firstName, setFirstName] = useState()
  const { pathname, search } = useLocation()
  const { navigationReplace, navigationPush } = useNavigation()
  const params = useQueryParams()
  const redirectTo = useRef()
  const callback = useRef(() => {})
  const closeModal = (
    redirectParams = {},
    callbackOptions,
    redirectPath,
    replaceUrl = true,
    needRedirect = true
  ) => {
    if (callback.current) {
      callback.current(callbackOptions)
    }
    setModal({
      name: DEFAULT_MODAL,
      modalProps: {},
      modalWrapperProps: {},
      isOpen: false,
    })
    if (needRedirect) {
      const redirect = redirectTo.current || redirectPath
      if (redirect) {
        const nextModal = extractActionQuery(redirect)
        let url = redirect
        if (Object(redirectParams).keys?.length) {
          for (const [key, value] of Object.entries(redirectParams)) {
            url += `&${key}=${value}`
          }
        }
        if (nextModal) {
          url = url.replace(/(\?|&)action=[^&]*/g, '')
        }
        if (replaceUrl) {
          return navigationReplace(url)
        }

        return navigationPush(url)
      }
      navigationReplace(getPath(pathname, search, null, false, params))
    }
  }

  const openModal = useCallback(
    (
      modalType = DEFAULT_MODAL,
      nextRedirect,
      isJoin = false,
      closeCallback,
      modalProps = {},
      modalWrapperProps = {},
      storeActionInUrl = true
    ) => {
      if (isJoin) {
        ReactGA.event({
          category: 'Join community',
          action: 'button click',
          label: 'homepage click',
          value: 1,
          nonInteraction: true,
          transport: 'xhr',
        })
      }

      redirectTo.current = nextRedirect
      if (closeCallback) {
        callback.current = closeCallback
      }
      const currentModalType =
        typeof modalType === 'string' ? modalType : DEFAULT_MODAL
      if (storeActionInUrl) {
        const newUrl = `${window.location.origin}/${language}${getPath(
          pathname,
          search,
          currentModalType,
          true,
          params
        )}`
        window.history.pushState({}, '', newUrl)
      }
      setModal({
        name: currentModalType,
        isOpen: true,
        modalProps,
        modalWrapperProps,
        storeActionInUrl,
      })
    },
    []
  )

  const onModalChange = (Content, authEmail, authFirstName) => {
    setEmail(authEmail)
    setFirstName(authFirstName)
    setModal({ ...Content, isOpen: true })
  }

  const memorizedChildren = useMemo(() => children, [])

  useEffect(() => {
    if (modal.storeActionInUrl) {
      let replace = getPath(pathname, search, modal.name, modal.isOpen, params)
      if (email) {
        replace += `&email=${email}`
      }
      if (firstName) {
        replace += `&firstName=${firstName}`
      }
      if (replace) {
        const newUrl = `${window.location.origin}/${language}${replace}`
        window.history.replaceState({}, '', newUrl)
      }
    }
  }, [modal])

  useEffect(() => {
    const action = params.get('action')
    if (action) {
      openModal(action)
    }
  }, [])

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <ModalComponent
        redirectTo={redirectTo}
        closeModal={closeModal}
        setModal={onModalChange}
        modal={modal}
      />
      {memorizedChildren}
    </ModalContext.Provider>
  )
}

const ModalComponent = ({ modal, closeModal, redirectTo, setModal }) => {
  const ContentComponent = MODALS?.[modal?.name]

  if (!ContentComponent) {
    console.log('>>> invalid modal', MODALS?.[modal?.name])
    return null
  }

  return (
    <Modal
      isOpen={modal?.isOpen}
      onClose={closeModal}
      isCentered={false}
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        overflow="hidden"
        {...(modal?.modalWrapperProps || null)}
      >
        <ContentComponent
          closeModal={closeModal}
          redirectTo={redirectTo.current}
          setModal={setModal}
          maxHeight="540px"
          {...(modal?.modalProps || null)}
        />
      </ModalContent>
    </Modal>
  )
}

export default ModalProvider
