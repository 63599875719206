import { Box } from '@chakra-ui/react'
import { Footer } from 'components/Footer'
import { MainLayout } from 'components/Layouts'
import BackButton from 'components/BackButton'
import Header from './Header'

const w = window.innerWidth
export default function Layout({ children }) {
  return (
    <MainLayout>
      {w <= 475 && <Header />}
      <Box
        minH="620px"
        w={{ base: 'full', md: '1140px' }}
        marginX="auto"
        mt={12}
        mb={{ base: 0, sm: '240px' }}
        ml={{ base: 6, xl: 'auto' }}
      >
        {w > 475 && <BackButton mb="0" to="/" />}
        {children}
      </Box>
      {w > 475 && <Footer />}
    </MainLayout>
  )
}
